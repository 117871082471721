import React, {useState} from "react";
import './BulkActionModal.css';
import {toastError, toastSuccess} from "../../../../../shared/ToastMsg/ToastMsgs";
import {approveRegularization} from "./BulkActionModal-service";
import {Box, Modal, TextField} from "@mui/material";

const BulkActionModal = ({ isShowBulk, setShowBulk, checkedItems, getPendingUserList, getPendingList, setCheckedItems }) => {
    const [reason, setReason] = useState("");

    const bulkApproveHandler = () => {
        const data = {
            type: isShowBulk.type,
            regularizationIds: checkedItems,
            reason: reason
        };
        approveRegularization(data)
            .then(response => {
                toastSuccess(response?.data?.message);
                setCheckedItems([]);
                getPendingList();
                getPendingUserList(isShowBulk.type);
                if(response){
                    window?.location?.reload()
                }
            })
            .catch(err => toastError(err.message));

        setShowBulk({ bool: false });
    };

    const handleReasonChange = (e) => {
        setReason(e.target.value);
    };
  return (
      <Modal
          open={isShowBulk.bool}
          onClose={() => setShowBulk({ bool: false })}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
      >
          <Box sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: 350,
              bgcolor: 'background.paper',
              boxShadow: 24,
              borderRadius: '14px',
              p: 3,
          }}>
              <div className='d-flex flex-column' style={{ rowGap: "30px" }}>
                  <div>
                      <p className='m-0 reason-heading'>Reason</p>
                      <div>
                          <TextField
                              placeholder="Enter here"
                              multiline
                              rows={6}
                              variant="outlined"
                              fullWidth
                              onChange={handleReasonChange}
                              sx={{ mb: 2, borderRadius: "11px" }}
                          />
                      </div>
                  </div>
                  <div className='d-flex justify-content-end'>
                      <button className='btn-cancel' onClick={() => setShowBulk({ bool: false })}>Cancel</button>
                      <button className='btn-approve' disabled={reason.length === 0} onClick={bulkApproveHandler}>Submit</button>
                  </div>
              </div>
          </Box>
      </Modal>
  );
}

export default React.memo(BulkActionModal);
