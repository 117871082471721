// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.BulkAction {
  /* Add your styles here */
}
.action-btn-wrap {
    background-color: #e7e7e7;
    padding: 0px 38px;
    border-radius: 30px ;
    height: 84%;
}

.mt-15 {
    margin-top: 15px;
}
`, "",{"version":3,"sources":["webpack://./src/v1/components/pages/Reportee/Web/ReporteeRegularization/BulkAction/BulkAction.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;AAC3B;AACA;IACI,yBAAyB;IACzB,iBAAiB;IACjB,oBAAoB;IACpB,WAAW;AACf;;AAEA;IACI,gBAAgB;AACpB","sourcesContent":[".BulkAction {\n  /* Add your styles here */\n}\n.action-btn-wrap {\n    background-color: #e7e7e7;\n    padding: 0px 38px;\n    border-radius: 30px ;\n    height: 84%;\n}\n\n.mt-15 {\n    margin-top: 15px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
