import React, { useState, useEffect, useContext } from "react";
import Drawer from "@mui/material/Drawer";
import {
  getDepartments,
  getManagers,
  postManagers,
} from "./ChangeManager-services";
import { AppContext } from "../../../../../AppContext";
import "./ChangeManagerDrawer.css";

function ChangeManagerDrawer({
  handleCloseDrawer,
  drawerOpen,
  LeftArrow,
  Warn,
  recentManagerId,
  managerId,
  department,
}) {
  const { setOpenModal, setSuccessText } = useContext(AppContext);
  const [formValues, setFormValues] = useState({
    department: department,
    managerName: managerId,
    reason: "",
  });
  const [departments, setDepartments] = useState([]);
  const [managers, setManagers] = useState([]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues((prev) =>({
      ...prev,
      [name]: value,
     }));
  };

  const handleClear = () => {
    setFormValues({
      department: "",
      managerName: "",
      reason: "",
    });
  };

  const handleSubmit = (e) => {
    const data = {
      managerId: parseInt(formValues?.managerName),
      reason: formValues?.reason,
      oldManagerId: recentManagerId,
    };
    e.preventDefault();
    postManagers(data)
      .then((res) => {
        setSuccessText(res?.data?.message);
        setOpenModal(true);
        setTimeout(() => {
          setOpenModal(false);
          handleCloseDrawer();
        }, 2000);
        updateManagerLocally(res.data.data);

        handleClear();
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  function updateManagerLocally(managerData) {
    const localUserData = JSON.parse(localStorage.getItem("user_data"));
    localUserData.managers = [managerData];
    localStorage.setItem("user_data", JSON.stringify(localUserData));
  }

  const backClicked = () => {
    handleClear();
    handleCloseDrawer();
  }

  useEffect(() => {
    if (drawerOpen) {
      getDepartments()
        .then((res) => {
          setDepartments(res?.data?.data);
          handleChange({target: {name: 'department', value: department}});
        })
        .catch((err) => {
          console.log("err msg is ,", err);
        });
    }
  }, [drawerOpen]);

  useEffect(() => {
    const params = formValues?.department
      ? {
          departmentId: formValues?.department,
        }
      : {};
    // if (formValues?.department !== "") {
    getManagers(params)
      .then((res) => {
        setManagers(res?.data?.data);
        handleChange({target: {name: 'managerName', value: managerId}});
      })
      .catch((err) => {
        console.log("err is", err);
      });
    // }
  }, [formValues?.department]);

  return (
    <div>
      <Drawer
        anchor="bottom"
        open={drawerOpen}
        onClose={handleCloseDrawer}
        PaperProps={{
          style: {
            borderTopLeftRadius: 30,
            borderTopRightRadius: 30,
            padding: "16px",
            height: "520px",
          },
        }}
      >
        <div>
          <div className="change-manager-containerr">
            <div type="button" onClick={backClicked}>
              <img src={LeftArrow} alt="Left Arrow" />
            </div>
            <span className="Assign-new-manager-heading">
              Assign New Manager
            </span>
          </div>

          <form onSubmit={handleSubmit} className="cm-form-container">
            <div className="cmf-contt">
              <div>
                <label htmlFor="department" className="profile-label">
                  Department*
                </label>
                <select
                  id="department"
                  name="department"
                  className="profile-input open-input"
                  style={{ padding: "5px" }}
                  value={formValues.department}
                  onChange={handleChange}
                >
                  <option value="">Select</option>
                  {departments?.map((data) => (
                    <option value={data?.id}>{data?.name}</option>
                  ))}
                </select>
              </div>
              <div>
                <label htmlFor="managerName" className="profile-label">
                  Manager Name*
                </label>
                <select
                  id="managerName"
                  name="managerName"
                  className="profile-input open-input"
                  style={{ padding: "5px" }}
                  value={formValues.managerName}
                  onChange={handleChange}
                  disabled={!managers || managers.length == 0}
                >
                  <option value="">Select</option>
                  {managers?.map((data) => (
                    <option value={data?.id}>{data?.name}</option>
                  ))}
                </select>
              </div>
              <div>
                <label htmlFor="reason" className="profile-label">
                  Reason
                </label>
                <textarea
                  id="reason"
                  name="reason"
                  className="custom-textareaa"
                  value={formValues.reason}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="cm-btn-container">
              <button
                type="button"
                onClick={handleClear}
                className="cm-clear-btn"
              >
                Clear
              </button>
              <button
                type="submit"
                disabled={formValues.managerName === ""}
                className="cm-submit-btn"
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      </Drawer>
    </div>
  );
}

export default ChangeManagerDrawer;
