import api from "../../../services/api";

export const getAssignedManager = () => {
  return api.get(`/user/user_manager_list`);
};

export const convertManagerDataToUserData = (managerData) => {
  return {
    user: {
      name: managerData?.profile?.name,
      contact: managerData?.contact?.phoneNumber,
    },
    empCode: managerData?.profile?.empCode,
    department: {
      name: managerData?.contact?.departmentName,
    },
    designation: {
      name: managerData?.profile?.designation,
      grade: managerData?.profile?.grade,
    },
    contact: managerData?.contact,
    user_details: {
      contact: managerData?.contact,
    },
    managers: [
      {
        manager: {
          name: managerData?.profile?.manager_id,
        },
      },
    ],
  };
};
