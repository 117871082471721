import React, { useContext, useEffect, useState } from "react";
import "./ApplyRegularizationDrawer.css";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import ArrowLeft from "../../../../../../assets/img/si_Arrow_left.svg";
import LoginIcon from "../../../../../../assets/img/login_in.svg";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { renderTimeViewClock } from "@mui/x-date-pickers/timeViewRenderers";
import LogoutIcon from "../../../../../../assets/img/login_out.svg";
import Warning from "../../../../../../assets/img/warning.svg";
import Drawer from "@mui/material/Drawer";
import { toast } from "react-toastify";
import { submitRegularization } from "./ApplyRegularizationDrawer-service";
import { AppContext } from "../../../../../../AppContext";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import { formatDate, formatTime } from "../../../../../services/common-service";

const ApplyRegularizationDrawer = ({
  attendanceList,
  currentAttendance,
  setCurrentAttendance,
  reasons,
  getData,
}) => {
  const [selectedReason, setSelectedReason] = useState(null);
  const [updatedStartTime, setUpdatedStartTime] = useState(
    dayjs("2024-07-25T09:00")
  );
  const [updatedEndTime, setUpdatedEndTime] = useState(null);
  const [remark, setRemark] = useState(null);
  const { setOpenModal, setSuccessText } = useContext(AppContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (currentAttendance?.shift?.start_time) {
      const shiftStartTime = new Date(currentAttendance?.shift?.start_time);
      setUpdatedStartTime(dayjs(currentAttendance?.shift?.start_time));
    }
    if (currentAttendance?.shift?.end_time) {
      const shiftEndTime = new Date(currentAttendance?.shift?.end_time);
      setUpdatedEndTime(dayjs(shiftEndTime));
    } else {
      setUpdatedEndTime(null);
    }
  }, [currentAttendance?.shift]);

  const clearData = () => {
    setRemark("");
    setSelectedReason("");
    setUpdatedStartTime(null);
    setUpdatedEndTime(null);
    setCurrentAttendance(null);
  };

  const handleSubmit = () => {
    if (!selectedReason) {
      return toast.warn("Please select Reason.");
    }
    if (
      !(
        currentAttendance?.is_late_check_in == false &&
        currentAttendance?.is_early_check_out == false &&
        currentAttendance?.has_minimum_hours == true
      ) &&
      (!updatedStartTime || !updatedEndTime)
    ) {
      return toast.warn("Please select Updated Time");
    }

    // if (
    //   updatedStartTime &&
    //   updatedEndTime &&
    //   updatedStartTime.isAfter(updatedEndTime)
    // ) {
    //   return toast.warn("Start time should be less than end time.");
    // }

    const applyingDate = new Date(currentAttendance.check_in_time)
      .toUTCString()
      .split(" ");
    const startTime = new Date(updatedStartTime?.$d)
      .toUTCString()
      .split(" ")[4];
    const endTime = new Date(updatedEndTime?.$d).toUTCString().split(" ")[4];
    const finalStartTime = [...applyingDate];
    finalStartTime[4] = startTime;
    const finalEndTime = [...applyingDate];
    finalEndTime[4] = endTime;
    const applyData = {
      reasonId: selectedReason,
      remarks: remark,
      applyStartTime: finalStartTime.join(" "),
      applyEndTime: finalEndTime.join(" "),
      attendanceId: currentAttendance.id,
      managerId: currentAttendance?.manager?.id,
    };
    submitRegularization(applyData)
      .then((res) => {
        setSuccessText(res?.data?.message);
        setOpenModal(true);
        setTimeout(() => {
          setOpenModal(false);
          if (attendanceList?.length == 1) {
            navigate("/home");
          } else {
            setCurrentAttendance(false);
            setRemark("");
            setSelectedReason("");
            getData();
          }
        }, 2000);
      })
      .catch((err) => {
        console.log("error in applying regularization", err);
      });
  };

  return (
    <>
      <Drawer
        anchor="bottom"
        open={currentAttendance}
        onClose={clearData}
        PaperProps={{
          sx: {
            borderTopLeftRadius: 30,
            borderTopRightRadius: 30,
            padding: 2,
            height: "570px",
          },
        }}
      >
        <Box sx={{ width: "100%", height: "100%" }}>
          <Typography variant="h6" component="div" className="box-typo">
            <div className="apply-regularization">
              <div className="heading-wrap">
                <div className="heading-sub-wrap">
                  <div typeof="button" onClick={clearData}>
                    <img src={ArrowLeft} />
                  </div>
                  <span className="heading-span">Regularization</span>
                </div>
                <div className="shift-text">
                  Regular shift {currentAttendance?.shift_start_time} to{" "}
                  {currentAttendance?.shift_end_time}
                </div>
              </div>
              <div className="h-40">
                <div className="applying-to-wrap">Applying To</div>
                <div className="manager-text">
                  {currentAttendance?.manager?.name} (
                  {currentAttendance?.manager?.empCode})
                </div>
              </div>
              <div>
                <label htmlFor="department" className="profile-label">
                  Reason*
                </label>
                <select
                  id="department"
                  name="department"
                  className="reason-inputt reason-select"
                  value={selectedReason}
                  onChange={(e) => setSelectedReason(e?.target?.value)}
                >
                  <option value="">Select</option>
                  {reasons?.map((data) => (
                    <option key={data?.id} value={data?.id}>
                      {data?.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="dashed-divider"></div>
              <div className="info-wrap">
                <div>
                  <div>
                    <div className="date-label">Date</div>
                    <div className="check-in-time">
                      {formatDate(currentAttendance?.created_at)}
                    </div>
                  </div>
                  {(currentAttendance?.is_late_check_in === true ||
                    currentAttendance?.is_early_check_out === true ||
                    currentAttendance?.has_minimum_hours === false) && (
                    <div>
                      <div className="d-f">
                        <div className="newTimeSelection">
                          <img src={LoginIcon} />
                        </div>
                        <div>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DemoContainer components={["TimePicker"]}>
                              <TimePicker
                                label={!updatedStartTime && "__:__"}
                                viewRenderers={{
                                  hours: renderTimeViewClock,
                                  minutes: renderTimeViewClock,
                                }}
                                value={updatedStartTime}
                                onChange={(newValue) =>
                                  setUpdatedStartTime(newValue)
                                }
                              />
                            </DemoContainer>
                          </LocalizationProvider>
                        </div>
                      </div>
                      <div className="remark-labell">Req Sign-in Time</div>
                    </div>
                  )}
                </div>
                <div>
                  <div>
                    <div className="timing-label">Check-in/check-out time</div>
                    <div className="timing-value">
                      {formatTime(currentAttendance?.check_in_time)} -{" "}
                      {formatTime(currentAttendance?.check_out_time)}
                    </div>
                  </div>

                  {(currentAttendance?.is_late_check_in == true ||
                    currentAttendance?.is_early_check_out == true ||
                    currentAttendance?.has_minimum_hours == false) && (
                    <div>
                      <div className="d-f">
                        <div className="newTimeSelection">
                          <img src={LogoutIcon} />
                        </div>
                        <div>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DemoContainer components={["TimePicker"]}>
                              <TimePicker
                                label={!updatedEndTime && "__:__"}
                                viewRenderers={{
                                  hours: renderTimeViewClock,
                                  minutes: renderTimeViewClock,
                                  seconds: renderTimeViewClock,
                                }}
                                value={updatedEndTime}
                                onChange={(newValue) =>
                                  setUpdatedEndTime(newValue)
                                }
                              />
                            </DemoContainer>
                          </LocalizationProvider>
                        </div>
                      </div>
                      <div className="remark-labell">Req Sign-out Time</div>
                    </div>
                  )}
                </div>
              </div>

              {(currentAttendance?.is_oor_check_in == true ||
                currentAttendance?.is_oor_check_out == true) && (
                <div className="oor-wrap">
                  <div className="warning-img-wrap">
                    {" "}
                    <img src={Warning} />
                  </div>
                  <div>
                    <div>You were out of radius.</div>
                    <div>
                      Latitude & Longitude :{" "}
                      {currentAttendance?.check_in_location}
                    </div>
                  </div>
                </div>
              )}
              <div>
                <label className="remark-label">Remark</label>
                <textarea
                  placeholder="Write here"
                  onChange={(e) => setRemark(e.target.value)}
                  value={remark}
                  className="reason-input"
                />
              </div>
            </div>
            <div className="close-btn-wrap">
              <button onClick={clearData} className="action-btn close-btn">
                Close
              </button>
              <button onClick={handleSubmit} className="action-btn submit-btn">
                Submit
              </button>
            </div>
          </Typography>
        </Box>
      </Drawer>
    </>
  );
};

export default ApplyRegularizationDrawer;
