// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Attendancerecordcalender {
  /* Add your styles here */
}



@media (max-width: 786px) {
  .ant-calendar-range {
    width: 420px !important;
  }

  :where(.css-dev-only-do-not-override-m4timi).ant-picker-dropdown .ant-picker-panel-container .ant-picker-panels {
    display: inline-flex !important;
    flex-wrap: wrap !important;
    direction: ltr !important;
    width: 300px !important;
  }

  :where(.css-dev-only-do-not-override-m4timi).ant-picker-dropdown .ant-picker-range-wrapper {
    display: flex !important;
    position: relative !important;
  }

  .ant-calendar-range-part {
    width: 100% !important;
  }
}

:where(.css-m4timi).ant-picker-dropdown .ant-picker-panel-container .ant-picker-panels {
  display: inline-flex !important;
  flex-wrap: wrap !important;
  direction: ltr !important;
  width: 300px !important;
}

:where(.css-dev-only-do-not-override-d2lrxs).ant-picker-dropdown .ant-picker-panel-container .ant-picker-panels {
  display: inline-flex !important;
  flex-wrap: wrap !important;
  direction: ltr !important;
  width: 300px !important;
}

:where(.css-dev-only-do-not-override-1c0na6j).ant-picker-dropdown .ant-picker-panel-container .ant-picker-panels {
  display: inline-flex;
  flex-wrap: wrap !important;
  direction: ltr !important;
  width: 300px !important;
}`, "",{"version":3,"sources":["webpack://./src/v1/components/pages/Attendance/Web/Attendancerecordcalender/Attendancerecordcalender.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;AAC3B;;;;AAIA;EACE;IACE,uBAAuB;EACzB;;EAEA;IACE,+BAA+B;IAC/B,0BAA0B;IAC1B,yBAAyB;IACzB,uBAAuB;EACzB;;EAEA;IACE,wBAAwB;IACxB,6BAA6B;EAC/B;;EAEA;IACE,sBAAsB;EACxB;AACF;;AAEA;EACE,+BAA+B;EAC/B,0BAA0B;EAC1B,yBAAyB;EACzB,uBAAuB;AACzB;;AAEA;EACE,+BAA+B;EAC/B,0BAA0B;EAC1B,yBAAyB;EACzB,uBAAuB;AACzB;;AAEA;EACE,oBAAoB;EACpB,0BAA0B;EAC1B,yBAAyB;EACzB,uBAAuB;AACzB","sourcesContent":[".Attendancerecordcalender {\n  /* Add your styles here */\n}\n\n\n\n@media (max-width: 786px) {\n  .ant-calendar-range {\n    width: 420px !important;\n  }\n\n  :where(.css-dev-only-do-not-override-m4timi).ant-picker-dropdown .ant-picker-panel-container .ant-picker-panels {\n    display: inline-flex !important;\n    flex-wrap: wrap !important;\n    direction: ltr !important;\n    width: 300px !important;\n  }\n\n  :where(.css-dev-only-do-not-override-m4timi).ant-picker-dropdown .ant-picker-range-wrapper {\n    display: flex !important;\n    position: relative !important;\n  }\n\n  .ant-calendar-range-part {\n    width: 100% !important;\n  }\n}\n\n:where(.css-m4timi).ant-picker-dropdown .ant-picker-panel-container .ant-picker-panels {\n  display: inline-flex !important;\n  flex-wrap: wrap !important;\n  direction: ltr !important;\n  width: 300px !important;\n}\n\n:where(.css-dev-only-do-not-override-d2lrxs).ant-picker-dropdown .ant-picker-panel-container .ant-picker-panels {\n  display: inline-flex !important;\n  flex-wrap: wrap !important;\n  direction: ltr !important;\n  width: 300px !important;\n}\n\n:where(.css-dev-only-do-not-override-1c0na6j).ant-picker-dropdown .ant-picker-panel-container .ant-picker-panels {\n  display: inline-flex;\n  flex-wrap: wrap !important;\n  direction: ltr !important;\n  width: 300px !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
