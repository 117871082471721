import React from "react";
import './SigninSignoutInfoWeb.css';

const SigninSignoutInfoWeb = ({attendance}) => {
  return (
    <>
        <div className="d-flex signIn-container">
            <div>
                <p className="signIn">Sign In</p>
                <p className="signIn-time">{attendance?.check_in_time}</p>
            </div>
            <div className="vr-line"></div>
            <div>
                <div>
                    <p className="signIn">Sign Out</p>
                    <p className="signIn-time">{attendance?.check_out_time}</p>
                </div>
            </div>
        </div>
    </>
  );
}

export default SigninSignoutInfoWeb;
