import React, { useContext } from "react";
import PageComponentHeader from "./PageComponentHeader/PageComponentHeader";
import "./RightDrawerComponent.css";
import { Button } from "react-bootstrap";
import {formatDate, formatTime, getAvatarInitials} from "../../../../v1/services/common-service"
import {ReactComponent as ClockImg} from "../../../../assets/img/CardImg/Alarm.svg"
import {ReactComponent as CrossImg} from "../../../../assets/img/CardImg/cross.svg"
import { Avatar } from "@mui/material";
import FormComponent from "../Formcomponent/Formcomponent"
import { AppContext } from "../../../../AppContext";


const RightDrawerComponent = React.memo(({
  headerTitle,
  buttonText,
  tableComponent: TableComponent,
  tableProps,
  formProps,
  selectedData,
  officeFormData,
  setOfficeFormData,
  setIsOfficeData
}) => {
  const { isDrawerOpen, setIsDrawerOpen } = useContext(AppContext);
  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };
  return (
    <div className={`component-container ${isDrawerOpen ? "shifted" : ""}`}>
      {isDrawerOpen && <div className="backdrop" onClick={() => setIsDrawerOpen(false)}></div>}
      <div className="w-100">
        <PageComponentHeader
          headerTitle={headerTitle}
          buttonText={buttonText}
          isOpen={isDrawerOpen}
          setIsOpen={setIsDrawerOpen}
          toggleDrawer={toggleDrawer}
        />
        {TableComponent && <TableComponent  {...tableProps} />}
      </div>
      <div></div>
      <div className={`drawer ${isDrawerOpen ? "open" : ""}`}>
        <div></div>
        <div className="drawer-footer">
          <div className="drawer-footer-container ">
            <button
              onClick={() => {
                setIsDrawerOpen(false);
              }}
              style={{ background: "transparent", color: "#7647EB" }}
              >
              <CrossImg />
            </button>
              {officeFormData && <FormComponent setIsOfficeData={setIsOfficeData} setOfficeFormData={setOfficeFormData} officeFormData={officeFormData} setIsDrawerOpen={setIsDrawerOpen} />}
            {selectedData && <div className="d-flex justify-content-center flex-column align-items-center ">
              <Avatar className="avtar-img">
                {getAvatarInitials(selectedData?.user?.name || 'U')}
              </Avatar>
              <div className="text-center">
                <p className="applied-by-date-drawer">Applied by</p>
                <p className="applied-by-name-drawer">{selectedData?.user?.name}</p>
                <p className="applied-by-date-drawer">On Date : {formatDate(selectedData?.user?.created_at)}</p>
              </div>
              <div className="d-flex justify-content-center column-gap-drawer width-62">
                <ClockImg className="mt-2" />
                <div>
                  <p className="m-0 light-shift">Shift</p>
                  <p className="shift-text-drawer m-0">  <span>
                    {formatTime(selectedData?.user?.office?.shifts?.[0]?.shift?.start_time)}
                  </span>
                    -
                    <span>
                      {formatTime(selectedData?.user?.office?.shifts?.[0]?.shift?.end_time)}
                    </span></p>
                </div>
              </div>
              <div className="d-flex justify-content-center column-gap-drawer width-62 bg-white mt-4">
                <ClockImg className="mt-2" />
                <div >
                  <p className="m-0 light-shift">Actual & Updated Time</p>
                  <p className="shift-text-drawer m-0">{formatTime(selectedData?.attendance?.check_in_time)} - {formatTime(selectedData?.attendance?.check_out_time)}</p>
                  <p className="shift-text-drawer m-0"><span>
                    {formatTime(selectedData?.start_date)}
                  </span>
                    -
                    <span>
                      {formatTime(selectedData?.end_date)}
                    </span></p>
                </div>
              </div>
              <div className=" width-62">
                <p className="light-shift text-left mr-4 mt-3">Remark by <span className="name-text-600">{selectedData?.user?.name}</span></p>
                <div className="d-flex justify-content-center column-gap-drawer reason-drawer ">
                  <p className="shift-text-reason">{selectedData?.remarks|| "--"}</p>
                </div>
              </div>
              <div className="  width-62 mt-3">
                <span className={`rejected-apporve border-drawer-rejected text-left ${selectedData?.status === "Approved" ? "approved-color" : "rejected-color"}`}
                >

                  {selectedData?.status} </span>
                <div className="d-flex justify-content-center column-gap-drawer reason-drawer  rejected-bg mt-2">
                  <p className="shift-text-reason text-left w-100">{selectedData?.approve_reason || selectedData?.reject_reason || "--"}</p>
                </div>
              </div>
            </div>}
          </div>
        </div>
      </div>
    </div>
  );
});

export default RightDrawerComponent;





