// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.link-wrap {
  width: 51%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.page-heading {
  font-size: 18px;
  font-weight: 600;
  color: black;
}

.regularization-btn {
  display: flex;
  justify-content: space-between;
  padding: 15px;
  height: 52px;
  background-color: white;
  border-radius: 12px;
  margin: 14px;
}

.btn-text {
  font-weight: 500;
  font-size: 16px;
  color: #212121;
}

.mr-0 {
  margin-right: 0;
}

.no-data-found-contt {
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: black;
}

.regularization-count {
  border: solid 1px #7647eb;
  display: inline;
  font-size: 10px;
  padding: 0.15rem 0.3rem;
  border-radius: 100%;
  margin-left: 5px;
}

.attendance-tab .css-1aquho2-MuiTabs-indicator {
  background-color: #7647eb !important;
}

.AttActionBtn {
  background-color: white;
  color: black;
  padding-left: 0px !important;
  border: none;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin-bottom: 10px;
  cursor: pointer;
  border-radius: 4px;
}
`, "",{"version":3,"sources":["webpack://./src/v1/components/pages/Attendance/Mobile/Mobile.css"],"names":[],"mappings":"AAAA;EACE,UAAU;EACV,aAAa;EACb,6BAA6B;EAC7B,mBAAmB;AACrB;;AAEA;EACE,eAAe;EACf,gBAAgB;EAChB,YAAY;AACd;;AAEA;EACE,aAAa;EACb,8BAA8B;EAC9B,aAAa;EACb,YAAY;EACZ,uBAAuB;EACvB,mBAAmB;EACnB,YAAY;AACd;;AAEA;EACE,gBAAgB;EAChB,eAAe;EACf,cAAc;AAChB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,aAAa;EACb,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,YAAY;AACd;;AAEA;EACE,yBAAyB;EACzB,eAAe;EACf,eAAe;EACf,uBAAuB;EACvB,mBAAmB;EACnB,gBAAgB;AAClB;;AAEA;EACE,oCAAoC;AACtC;;AAEA;EACE,uBAAuB;EACvB,YAAY;EACZ,4BAA4B;EAC5B,YAAY;EACZ,kBAAkB;EAClB,kBAAkB;EAClB,qBAAqB;EACrB,qBAAqB;EACrB,eAAe;EACf,mBAAmB;EACnB,eAAe;EACf,kBAAkB;AACpB","sourcesContent":[".link-wrap {\n  width: 51%;\n  display: flex;\n  justify-content: space-evenly;\n  align-items: center;\n}\n\n.page-heading {\n  font-size: 18px;\n  font-weight: 600;\n  color: black;\n}\n\n.regularization-btn {\n  display: flex;\n  justify-content: space-between;\n  padding: 15px;\n  height: 52px;\n  background-color: white;\n  border-radius: 12px;\n  margin: 14px;\n}\n\n.btn-text {\n  font-weight: 500;\n  font-size: 16px;\n  color: #212121;\n}\n\n.mr-0 {\n  margin-right: 0;\n}\n\n.no-data-found-contt {\n  height: 100px;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  color: black;\n}\n\n.regularization-count {\n  border: solid 1px #7647eb;\n  display: inline;\n  font-size: 10px;\n  padding: 0.15rem 0.3rem;\n  border-radius: 100%;\n  margin-left: 5px;\n}\n\n.attendance-tab .css-1aquho2-MuiTabs-indicator {\n  background-color: #7647eb !important;\n}\n\n.AttActionBtn {\n  background-color: white;\n  color: black;\n  padding-left: 0px !important;\n  border: none;\n  padding: 10px 20px;\n  text-align: center;\n  text-decoration: none;\n  display: inline-block;\n  font-size: 16px;\n  margin-bottom: 10px;\n  cursor: pointer;\n  border-radius: 4px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
