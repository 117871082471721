import React, { useContext, useEffect, useState } from "react";
import "./Office.css";
import TableComp from "../../../pages/Dashboard/shared/Table/Table";
import BreadCrumbs from "../shared/BreadCrumbs/BreadCrumbs";
import SharedInput from "../shared/Shared/Shared-input";
import { getOfficeDetails } from "./Office-services";
import { listItemTextClasses } from "@mui/material";
import RightDrawerComponent from "../../../shared/RightDrawerComponent/RightDrawerComponent";
import { AppContext } from "../../../../../AppContext";

function Office() {
  const { searchValue, setSearchValue, isDrawerOpen,
    setIsDrawerOpen, } = useContext(AppContext);
    const [officeFormData ,setOfficeFormData]=useState([])
  const [officeData, setOfficeData] = useState([]);
  const [isOfficeData, setIsOfficeData]=useState(false)
  // useEffect(() => {
  //   const data = {
  //     offset: 0,
  //     limit: 10,
  //   };
  //   getOfficeData(data);
  // }, [isOfficeData]);

  const getOfficeData = (data) => {
    getOfficeDetails(data)
      .then((res) => {
        setOfficeData(res?.data?.data);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };
  
  const officeHandler=(item)=>{
    setOfficeFormData(item)
    setIsDrawerOpen(true)
  }

  return (
    <div className="office-container">
      <RightDrawerComponent officeFormData={officeFormData}  setOfficeFormData={setOfficeFormData} setIsOfficeData={setIsOfficeData} />
      <div>
        <BreadCrumbs pathText="Office" />
      </div>
      <div>
        <SharedInput callFunction={getOfficeData} />
      </div>
      <div>
        <TableComp
        isOfficeData={isOfficeData}
          headersData={officeData?.headers}
          tableData={officeData?.list}
          callFunction={getOfficeData}
          count={officeData?.total}
          office={true}
          officeHandler={officeHandler}
        />
      </div>
    </div>
  );
}

export default Office;