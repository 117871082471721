// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.UserDetailCardRow {
  /* Add your styles here */
}
.p-18 {
    padding: 18px;
}

.cg-4 {
    column-gap: 4px !important;
}

.fs-0-8 {
    font-size: 0.8rem !important;
}
`, "",{"version":3,"sources":["webpack://./src/v1/components/shared/UserDetailCardRow/UserDetailCardRow.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;AAC3B;AACA;IACI,aAAa;AACjB;;AAEA;IACI,0BAA0B;AAC9B;;AAEA;IACI,4BAA4B;AAChC","sourcesContent":[".UserDetailCardRow {\n  /* Add your styles here */\n}\n.p-18 {\n    padding: 18px;\n}\n\n.cg-4 {\n    column-gap: 4px !important;\n}\n\n.fs-0-8 {\n    font-size: 0.8rem !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
