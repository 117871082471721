import api from "../../../services/api";

export const getHomeData = () => {
  return api.get(`/home/data`);
};

export const logOut = (data) => {
  const userData = JSON.parse(localStorage.getItem("user_data"));
  const token = userData.activeToken;
  return api.post(`/logOut`, data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};
