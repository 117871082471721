import React, { useEffect, useState } from "react";
import "./Pending.css";
import { ReactComponent as Review } from "../../../../../../../assets/img/CardImg/Review.svg";
import LoginIcon from "../../../../../../../assets/img/CardImg/pendingImgin.svg";
import LogoutIcon from "../../../../../../../assets/img/login_out.svg";
import {ReactComponent as BuildingImg} from "../../../../../../../assets/img/CardImg/apartment.svg";
import {ReactComponent as LoginGreen} from "../../../../../../../assets/img/CardImg/loginGreen.svg";
import {ReactComponent as LoginRed} from "../../../../../../../assets/img/CardImg/loginRed.svg";
import {ReactComponent as Road} from "../../../../../../../assets/img/CardImg/Rectangle 34624484.svg";
import {ReactComponent as Line} from "../../../../../../../assets/img/CardImg/Line 1107.svg";
import {ReactComponent as Man} from "../../../../../../../assets/img/CardImg/Union.svg";
import {
  getPendingRegularizationList,
  getPendingUserData,
} from "./Pending-service";
import UserInfoRow2 from "../../../../../shared/UserInfoRow/UserInfoRow2";
import {formatDate, formatTime} from "../../../../../../services/common-service";
import { useParams } from "react-router-dom";

const PendingReporteeRegularization = ({ setPendingRegularizationsParent, pendingReportees,checkedItems, setPendingReportees ,checkActions }) => {
  const [pendingRegularizations, setPendingRegularizations] = useState([]);
  const [activeRegularization, setActiveRegularization] = useState(null);
  

  useEffect(() => {
    getPendingList();
  }, []);

  function getPendingList() {
    getPendingRegularizationList()
      .then((response) => setPendingReportees(response?.data?.data))
      .catch((err) => console.log(err, "err"));
  }

  function getPendingUserList(userId) {
    setActiveRegularization(userId);
    getPendingUserData(userId)
      .then((reply) => {
        setPendingRegularizations(reply?.data?.data);
        setPendingRegularizationsParent(reply?.data?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  return (
    <>
      <div className="row p-4 ">
        <div className="col-md-4">
          {Array.isArray(pendingReportees) &&
            pendingReportees.length > 0 &&
            pendingReportees.map((item, index) => {
              return (
                <div
                  className={ 
                    "bg-white p-4 d-flex flex-column reg-container-radius mb-3 cursor-pointer" +
                    (item.id === activeRegularization
                      ? " active-regularisation"
                      : "")
                  }
                  style={{ rowGap: "28px" }}
                  onClick={() => getPendingUserList(item.id)}
                >
                  <div
                    style={{
                      borderBottom: "1px solid rgba(172, 175, 181, 0.1)",
                    }}
                  >
                    
                    <UserInfoRow2 userData={item} />
                  </div>
                  <div className="d-flex justify-content-between">
                    <p className="reg-applied m-0">Applied</p>
                    <p className="reg-day m-0">
                      {item?.pendingRegularizationsCount} Day
                    </p>
                  </div>
                </div>
              );
            })}
        </div>

        <div className="col-md-8 bg-white reg-container-list">
          {pendingRegularizations.map((pendingItem, index) => {
            return (
              <div
                className="container-border-reg mb-2"
                key={`pending-item-${index}`}
              >
                <div className="container-pending-div border-radius-none-reg d-flex  border-bottom-reg">
                  <div
                    className="col-md-2 border-right-reg"
                    style={{ padding: "10px 0px" }}
                  >
                    <p className="applied-by m-0">Applied Date(s)</p>
                    <p className="applied-by-name">
                      {formatDate(pendingItem?.created_at)}
                    </p>
                  </div>
                  <div className="col-md-5 border-right-reg">
                    <label
                      htmlFor="department"
                      className="profile-label"
                      style={{ marginTop: "5px" }}
                    >
                      Captured
                    </label>
                    <div className="d-flex justify-content-center col-gap-pending" >
                      <div
                        className="d-flex align-items-start"
                        style={{ columnGap: "12px" }}
                      >
                        <img src={LoginIcon} alt="" />
                        <div>
                          <p
                            className="applied-by-name m-0"
                            style={{
                              color: pendingItem?.attendance?.is_late_check_in
                                ? "red"
                                : "",
                            }}
                          >
                            {formatTime(pendingItem?.attendance?.check_in_time)}
                          </p>
                          <p className="profile-label ">Sign In</p>
                        </div>
                      </div>
                      <div
                        className="d-flex align-items-start"
                        style={{ columnGap: "12px" }}
                      >
                        <img src={LogoutIcon} alt="" />
                        <div>
                          <p
                            className="applied-by-name m-0"
                            style={{
                              color: pendingItem?.attendance?.is_early_check_out
                                ? "red"
                                : "",
                            }}
                          >
                            {formatTime(
                              pendingItem?.attendance?.check_out_time,
                            )}
                          </p>
                          <p className="applied-by ">Sign Out</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-5 ">
                    <label
                      htmlFor="department"
                      className="profile-label"
                      style={{ marginTop: "5px" }}
                    >
                      Requested
                    </label>
                    <div>
                      <div className="d-flex justify-content-center col-gap-pending" >
                        <div
                          className="d-flex align-items-start"
                          style={{ columnGap: "12px" }}
                        >
                          <img src={LoginIcon} alt="" />
                          <div>
                            <p className="applied-by-name m-0">
                              {formatTime(pendingItem?.start_date)}
                            </p>
                            <p className="applied-by ">Sign In</p>
                          </div>
                        </div>
                        <div
                          className="d-flex align-items-start"
                          style={{ columnGap: "12px" }}
                        >
                          <img src={LogoutIcon} alt="" />
                          <div>
                            <p className="applied-by-name m-0">
                              {formatTime(pendingItem?.end_date)}
                            </p>
                            <p className="applied-by ">Sign Out</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="d-flex justify-content-between align-items-center" style={{columnGap:"10px", padding:"10px 10px"}}>
                  <div
                    className="col-md-4 reason-container"
                    key={`pending-item-${index}`}
                    style={{ padding: "10px 0px" }}
                  >
                   <div className="d-flex col-gap-reason">
                   <p className=" reason-heading">Reason: </p>
                    <p className=" reason-container-sub">
                       {pendingItem?.leaveReason?.name}
                    </p>
                   </div>
                   <div className="d-flex col-gap-reason">
                   <p className=" reason-heading">Remarks: </p>
                    <p className=" reason-container-sub remark-border m-0 ">
                    {pendingItem?.remarks || "--"}
                    </p>
                   </div>
                  </div>
                  
                  <div className=" d-flex col-gap">
                <div className="d-flex ">
                <div className="buildingImg-style">
                  <BuildingImg/>
                  </div>
                  <div className="location-container" >
                    <LoginGreen/>
                   <div className="position-relative">
                    <Road/>
                    <div  className="position-abs">
                      <Line/>
                    </div>
                   </div>
                    <Man/>
                  </div>
                </div>
                <div className="d-flex flex-column address justify-content-center">
         
                <a
  className="address"
  href={`https://www.google.com/maps/search/?api=1&query=${pendingItem?.attendance?.check_in_location}`}
  target="_blank"
  rel="noopener noreferrer"
>
  {pendingItem?.attendance?.check_in_address || "NA"}

</a>
<p className="m-0 distance-text ">{Math.ceil(pendingItem?.attendance?.check_in_distance)}  m</p>

</div>   
                  </div>


                  <div className=" d-flex col-gap">
                <div className="d-flex ">
                <div className="buildingImg-style">
                  <BuildingImg/>
                  </div>
                  <div className="location-container" >
                    <LoginRed/>
                   <div className="position-relative">
                    <Road/>
                    <div  className="position-abs">
                      <Line/>
                    </div>
                   </div>
                   <div className="rotate-man">
                    <Man/>
                  </div>
                  </div>
                </div>
                <div className="d-flex flex-column address justify-content-center">
                <a
  className="address"
  href={`https://www.google.com/maps/search/?api=1&query=${pendingItem?.attendance?.check_out_location}`}
  target="_blank"
  rel="noopener noreferrer"
>
  {pendingItem?.attendance?.check_out_address || "NA"}
</  a>
<p className="m-0 distance-text">{Math.ceil(pendingItem?.attendance?.check_out_distance)} m</p>
</div>

                  </div>
                  <input
                    type="checkbox"
                    checked={checkedItems.includes(pendingItem?.id)}
                    id="reg-checkbox"
                    onChange={() => checkActions(pendingItem?.id)}
                  />
                </div>
              </div>
            );
          })}
          {(!pendingRegularizations || pendingRegularizations.length <= 0) && (
            <div className="empty-regularisation">
              <div className="center-item">
                <Review width="220" height="220" />
                <span>Select a reportee to approve/reject regularisation</span>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default PendingReporteeRegularization;
