// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
@media (min-width: 1550px) {
.signIn-container{
  padding: 11px 13px !important;
}
}
@media (min-width: 1750px) {
.signIn-container{
 padding: 7px 9px !important;
}
}

.padding-card-home{padding:  44px 35px ;


}
@media (max-width: 1750px) {
  .padding-card-home{padding:  44px 19px }
 
}`, "",{"version":3,"sources":["webpack://./src/v1/components/shared/SigninSignoutInfoWeb/SigninSignoutInfoWeb.css"],"names":[],"mappings":";AACA;AACA;EACE,6BAA6B;AAC/B;AACA;AACA;AACA;CACC,2BAA2B;AAC5B;AACA;;AAEA,mBAAmB,oBAAoB;;;AAGvC;AACA;EACE,mBAAmB,oBAAoB;;AAEzC","sourcesContent":["\n@media (min-width: 1550px) {\n.signIn-container{\n  padding: 11px 13px !important;\n}\n}\n@media (min-width: 1750px) {\n.signIn-container{\n padding: 7px 9px !important;\n}\n}\n\n.padding-card-home{padding:  44px 35px ;\n\n\n}\n@media (max-width: 1750px) {\n  .padding-card-home{padding:  44px 19px }\n \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
