import React from "react";
import "./BreadCrumbs.css";
import { useNavigate, useParams } from "react-router-dom";

function BreadCrumbs({ pathText ,navigation }) {
  
  const navigate = useNavigate();
   
  return (
    <div className="bread-crumbs-container">
      <span onClick={() => navigate("/dashboard")} className="link-dashboard">
        Dashboard
      </span>
      {"  "}/{" "}
      <span className="link-dashboardd cursor-pointer" onClick={navigation ?()=>navigate(`/${navigation}`) : undefined}>
        {pathText.split("/")[0].split("-")[0].charAt(0).toUpperCase() +
          pathText.split("/")[0].split("-")[0].slice(1)}
      </span>
      <span className="link-dashboardd cursor-pointer">
      {
          pathText.split("/")[1] && (" / " + pathText.split("/")[1])
          }
      </span>
    </div>
  );
}

export default BreadCrumbs;
