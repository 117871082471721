import React from "react";
import './UserAttendanceListRow.css';
import UserInfoRow from "../../../../shared/UserInfoRow/UserInfoRow";
import {useNavigate} from "react-router-dom";
import {formatTime} from "../../../../../services/common-service";
import {checkInStatus, checkOutStatus} from "../../../../../services/util-services";

const UserAttendanceListRow = ({attendeeData}) => {

    const navigate = useNavigate();

  return (
    <>
        <div
            className="row reporte-card-container align-items-center"
            onClick={() => {
                navigate("/reportees/" + attendeeData.id);
            }}
        >
            {attendeeData?.attendances?.map((item, index) => {
                return (
                    <div className="col-md-4">
                        <div
                            className={
                                "row " +
                                (index === 1
                                    ? "justify-content-around border-card-reportee"
                                    : index === 2
                                        ? "justify-content-around"
                                        : "")
                            }
                        >
                            {index === 0 && <UserInfoRow userData={attendeeData} />}
                            {index > 0 && (
                                <p className="m-0 reporte-card-text">
                                    {index === 1 ? "Yesterday" : "Day Before"}
                                </p>
                            )}
                            <div
                                className={
                                    "d-flex justify-content-center w-50 " +
                                    (index === 0
                                        ? "align-items-center border-card-reportee"
                                        : "")
                                }
                                style={{ columnGap: "40px" }}
                            >
                                <div className="">
                                    <p className="reporte-container-time m-0">
                                        {attendeeData?.attendances[index]?.check_in_time
                                            ? formatTime(
                                                attendeeData?.attendances[index]?.check_in_time,
                                            )
                                            : "-"}
                                    </p>
                                    <p className="m-0 reporte-container-ontime">
                                        {checkInStatus(attendeeData?.attendances[index])}
                                    </p>
                                </div>
                                <div className="">
                                    <p className="text-center m-0 reporte-container-time">
                                        {attendeeData?.attendances[index]?.check_out_time
                                            ? formatTime(
                                                attendeeData?.attendances[index]?.check_out_time,
                                            )
                                            : "-"}
                                    </p>
                                    <p className="m-0 reporte-container-ontime">
                                        {checkOutStatus(attendeeData?.attendances[index])}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            })}
        </div>
    </>
  );
}

export default UserAttendanceListRow;
