// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ShiftChange {
  /* Add your styles here */
}
.my-chip {
  border: solid 1px #7647eb;
  padding: 4px 8px;
  border-radius: 20px;
  font-size: 11px;
  background-color: #efe9ff;
  color: #7647eb;
  font-weight: 500;
}

.my-chip.disabled {
  background-color: lightgrey;
  opacity: 0.4;
}

.my-chip-container {
  display: flex;
  column-gap: 5px;
  flex: auto;
  flex-wrap: wrap;
  row-gap: 5px;
}

.assigned {
  color: #7647eb;
  font-weight: 600;
}
.shift-cursor-default{
  cursor: default !important;
}

.pr-48 {
  padding-right: 48px;
}

.create-shift-btn {
  background: #7647eb;
}

.create-shift-btn:hover {
  background: #7647eb;
}
`, "",{"version":3,"sources":["webpack://./src/v1/components/pages/Reportee/Web/ShiftChange/ShiftChange.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;AAC3B;AACA;EACE,yBAAyB;EACzB,gBAAgB;EAChB,mBAAmB;EACnB,eAAe;EACf,yBAAyB;EACzB,cAAc;EACd,gBAAgB;AAClB;;AAEA;EACE,2BAA2B;EAC3B,YAAY;AACd;;AAEA;EACE,aAAa;EACb,eAAe;EACf,UAAU;EACV,eAAe;EACf,YAAY;AACd;;AAEA;EACE,cAAc;EACd,gBAAgB;AAClB;AACA;EACE,0BAA0B;AAC5B;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,mBAAmB;AACrB","sourcesContent":[".ShiftChange {\n  /* Add your styles here */\n}\n.my-chip {\n  border: solid 1px #7647eb;\n  padding: 4px 8px;\n  border-radius: 20px;\n  font-size: 11px;\n  background-color: #efe9ff;\n  color: #7647eb;\n  font-weight: 500;\n}\n\n.my-chip.disabled {\n  background-color: lightgrey;\n  opacity: 0.4;\n}\n\n.my-chip-container {\n  display: flex;\n  column-gap: 5px;\n  flex: auto;\n  flex-wrap: wrap;\n  row-gap: 5px;\n}\n\n.assigned {\n  color: #7647eb;\n  font-weight: 600;\n}\n.shift-cursor-default{\n  cursor: default !important;\n}\n\n.pr-48 {\n  padding-right: 48px;\n}\n\n.create-shift-btn {\n  background: #7647eb;\n}\n\n.create-shift-btn:hover {\n  background: #7647eb;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
