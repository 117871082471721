// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.{
    z-index: 1500 !important; /* Adjust z-index as needed */
  }
  
  .change-shift-text{
    font-family: "Quicksand";
font-size: 24px;
font-weight: 600;
line-height: 36px;
text-align: left;
color: #000000;

}
.shift-text-container{
  border-bottom: 1px solid  rgba(0, 0, 0, 0.1);
}
.change-shiftTime-text{
  font-family: "Kumbh Sans";
  font-size: 20px;
  font-weight: 500;
  line-height: 24.8px;
  text-align: left;
  color: #000000;

  }
  .timepicker-border{
    font-family: "Kumbh Sans";
    border: 1px solid #D9D9D9;
    padding: 2px 9px;
    border-radius: 10px;
  }
  .relaxation-border{
    border: 1px solid #D9D9D9;
    padding: 2px 7px;
    border-radius: 10px;
  }

  .ant-picker-suffix{
    background-color: #7647EB;
    opacity: 0.6;
    padding: 4px 4px;
    color: black !important;
    fill: black !important;
    border-radius: 50px;
  }
  .ant-picker-outlined:focus-within {
    border: none !important;
    border-color: white !important;
    box-shadow: none;
  }
  .color_black{
    color: black !important;
  }`, "",{"version":3,"sources":["webpack://./src/v1/components/pages/Reportee/Web/ShiftChange/ShiftChangeModal/ShiftChangeModal.css"],"names":[],"mappings":"AAAA;IACI,wBAAwB,EAAE,6BAA6B;EACzD;;EAEA;IACE,wBAAwB;AAC5B,eAAe;AACf,gBAAgB;AAChB,iBAAiB;AACjB,gBAAgB;AAChB,cAAc;;AAEd;AACA;EACE,4CAA4C;AAC9C;AACA;EACE,yBAAyB;EACzB,eAAe;EACf,gBAAgB;EAChB,mBAAmB;EACnB,gBAAgB;EAChB,cAAc;;EAEd;EACA;IACE,yBAAyB;IACzB,yBAAyB;IACzB,gBAAgB;IAChB,mBAAmB;EACrB;EACA;IACE,yBAAyB;IACzB,gBAAgB;IAChB,mBAAmB;EACrB;;EAEA;IACE,yBAAyB;IACzB,YAAY;IACZ,gBAAgB;IAChB,uBAAuB;IACvB,sBAAsB;IACtB,mBAAmB;EACrB;EACA;IACE,uBAAuB;IACvB,8BAA8B;IAC9B,gBAAgB;EAClB;EACA;IACE,uBAAuB;EACzB","sourcesContent":[".{\n    z-index: 1500 !important; /* Adjust z-index as needed */\n  }\n  \n  .change-shift-text{\n    font-family: \"Quicksand\";\nfont-size: 24px;\nfont-weight: 600;\nline-height: 36px;\ntext-align: left;\ncolor: #000000;\n\n}\n.shift-text-container{\n  border-bottom: 1px solid  rgba(0, 0, 0, 0.1);\n}\n.change-shiftTime-text{\n  font-family: \"Kumbh Sans\";\n  font-size: 20px;\n  font-weight: 500;\n  line-height: 24.8px;\n  text-align: left;\n  color: #000000;\n\n  }\n  .timepicker-border{\n    font-family: \"Kumbh Sans\";\n    border: 1px solid #D9D9D9;\n    padding: 2px 9px;\n    border-radius: 10px;\n  }\n  .relaxation-border{\n    border: 1px solid #D9D9D9;\n    padding: 2px 7px;\n    border-radius: 10px;\n  }\n\n  .ant-picker-suffix{\n    background-color: #7647EB;\n    opacity: 0.6;\n    padding: 4px 4px;\n    color: black !important;\n    fill: black !important;\n    border-radius: 50px;\n  }\n  .ant-picker-outlined:focus-within {\n    border: none !important;\n    border-color: white !important;\n    box-shadow: none;\n  }\n  .color_black{\n    color: black !important;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
