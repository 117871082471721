// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.reportees-tab {
    border-bottom: none !important;
    justify-content: start !important;
    /* margin-top: -3.3rem !important; */
    background: transparent !important;

    .nav-link {
        color: black;
        margin-right: 0px !important;
    }

    .active {
        background: transparent !important;
        color: black !important;
        border: none !important;
        border-bottom: solid 4px #7647eb !important;
    }

    .nav-link:hover,
    .nav-link:focus {
        border: none !important;
    }
}
`, "",{"version":3,"sources":["webpack://./src/v1/components/pages/Reportee/Web/Web.css"],"names":[],"mappings":"AAAA;IACI,8BAA8B;IAC9B,iCAAiC;IACjC,oCAAoC;IACpC,kCAAkC;;IAElC;QACI,YAAY;QACZ,4BAA4B;IAChC;;IAEA;QACI,kCAAkC;QAClC,uBAAuB;QACvB,uBAAuB;QACvB,2CAA2C;IAC/C;;IAEA;;QAEI,uBAAuB;IAC3B;AACJ","sourcesContent":[".reportees-tab {\n    border-bottom: none !important;\n    justify-content: start !important;\n    /* margin-top: -3.3rem !important; */\n    background: transparent !important;\n\n    .nav-link {\n        color: black;\n        margin-right: 0px !important;\n    }\n\n    .active {\n        background: transparent !important;\n        color: black !important;\n        border: none !important;\n        border-bottom: solid 4px #7647eb !important;\n    }\n\n    .nav-link:hover,\n    .nav-link:focus {\n        border: none !important;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
