import React from "react";
import './Reportee.css';
import ReporteeWeb from "./Web/Web";

const Reportee = () => {
  return (
    <><ReporteeWeb /></>
  );
}

export default Reportee;
