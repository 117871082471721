import React, { useState } from "react";
import { Modal, Box } from "@mui/material";
import "./ShiftChangeModal.css";
import { TimePicker } from "antd";
import { DownOutlined } from "@ant-design/icons";
import { Dropdown, Space } from "antd";
import { Chip, Stack } from "@mui/material";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import Minimumdropdown from "../Minimumdropdown/Minimumdropdown";

const items = [
  { label: <a>5 Minutes</a>, key: "5" },
  { label: <a>10 Minutes</a>, key: "10" },
  { label: <a>15 Minutes</a>, key: "15" },
  { label: <a>20 Minutes</a>, key: "20" },
  { label: <a>25 Minutes</a>, key: "25" },
  { label: <a>30 Minutes</a>, key: "30" },
];

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "none",
  borderRadius: "12px",
  boxShadow: 24,
  p: 4,
  display: "flex",
  flexDirection: "column",
  rowGap: "18px",
};

function ShiftChangeModal({ isShiftModal, setShiftModal, assignShiftToUser }) {
  const [selectedChip, setSelectedChip] = useState([]);
  const [shiftName, setShiftName] = useState("");
  const [relaxationTime, setRelaxtionTime] = useState("");
  const [minimunHours, setMinimumHours] = useState('');
  const [maxShift, setMaxShift] = useState(24);
  const [shiftTime, setShiftTime] = useState({
    start_time: null,
    end_time: null,
  });

  const weekdays = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  const onChange = (time, type) => {
    setShiftTime((prevState) => {
      const updatedState = { ...prevState, [type]: time };
      if (
        type === "start_time" &&
        time &&
        updatedState.end_time &&
        time.isAfter(updatedState.end_time)
      ) {
        updatedState.end_time = null;
      }
      return updatedState;
    });
  };

  const onMinimumHoursChange = (e) => {
    const hours = e?.$H; 
    const minutes = e?.$m; 
    const Time = `${hours}.${minutes}`
    setMinimumHours(Time)
  };

  const handleChange = (day) => {
    setSelectedChip((prev) =>
      prev.includes(day) ? prev.filter((d) => d !== day) : [day, ...prev]
    );
  };

  const disabledEndTime = () => {
    if (!shiftTime.start_time) return {};

    const start = dayjs(shiftTime.start_time);
    const minHour = start.hour();
    const minMinute = start.minute();

    return {
      disabledHours: () => Array.from({ length: minHour }, (_, i) => i),
      disabledMinutes: (selectedHour) =>
        selectedHour === minHour
          ? Array.from({ length: minMinute }, (_, i) => i)
          : [],
    };
  };

  dayjs.extend(utc);

  const formatDateTime = (date) =>
    dayjs(date).utc().format("YYYY-MM-DD HH:mm:ss");

  const assignShiftHandler = () => {
    const start_time = formatDateTime(shiftTime.start_time);
    const end_time = formatDateTime(shiftTime.end_time);
    const shiftData = {
      shift_name: shiftName,
      start_time,
      end_time,
      minimum_hours: parseFloat(minimunHours),
      relaxation_time: relaxationTime,
      working_days: selectedChip,
    };
    assignShiftToUser(shiftData);
    setShiftModal(false);
    setMinimumHours("");
    setRelaxtionTime("");
    setSelectedChip([]);
    setShiftTime({ start_time: null, end_time: null });
    setShiftName("");
  };

  const handleClear = () => {
    setShiftTime({ ...shiftTime, start_time: null });
  };

  const isFormValid = () =>
    shiftName &&
    shiftTime.start_time &&
    shiftTime.end_time &&
    minimunHours &&
    relaxationTime &&
    selectedChip.length > 0;

  const handleMenuClick = (info) => setRelaxtionTime(info.key);

  return (
    <div>
      <Modal
        open={isShiftModal}
        onClose={() => setShiftModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="shift-text-container">
            <p className="change-shift-text">Change Shift Hours</p>
          </div>
          <div>
            <p className="change-shiftTime-text">Shift Name</p>
            <input
              type="text"
              onChange={(e) => setShiftName(e.target.value)}
              className="form-control timepicker-border"
              value={shiftName}
            />
          </div>
          <div>
            <p className="change-shiftTime-text">Shift Time</p>
            <div className="d-flex justify-content-around">
              <div className="timepicker-border">
                <TimePicker
                  value={shiftTime.start_time}
                  allowClear
                  onClear={handleClear}
                  format="HH:mm"
                  onChange={(time) => onChange(time, "start_time")}
                  dropdownClassName="time-picker-dropdown"
                  inputReadOnly
                  placeholder="Start time"
                />
              </div>
              <div className="timepicker-border">
                <TimePicker
                  value={shiftTime.end_time}
                  disabledTime={disabledEndTime}
                  onChange={(time) => onChange(time, "end_time")}
                  inputReadOnly
                  placeholder="End time"
                  allowClear
                  dropdownClassName="time-picker-dropdown"
                  format="HH:mm"
                />
              </div>
            </div>
          </div>
          <div>
            <p className="change-shiftTime-text">Minimum Hours</p>
            <div>
              <Minimumdropdown minimunHours={minimunHours} onMinimumHoursChange={onMinimumHoursChange}/>
            </div>
            {/* <div>
              <input
                type="text"
                onChange={onMinimumHoursChange}
                className="form-control timepicker-border"
                value={minimunHours}
                max={maxShift}
              />
            </div> */}
          </div>
          <div>
            <p className="change-shiftTime-text">Relaxation time</p>
            <Dropdown
              overlayStyle={{ zIndex: 1500 }}
              menu={{ items, onClick: handleMenuClick }}
              trigger={["click"]}
            >
              <a onClick={(e) => e.preventDefault()}>
                <Space className="relaxation-border d-flex justify-content-between align-items-end">
                  <p className="m-0 color_black">
                    {relaxationTime || 0} Minutes
                  </p>
                  <DownOutlined style={{ color: "black" }} />
                </Space>
              </a>
            </Dropdown>
          </div>
          <div>
            <p className="change-shiftTime-text">Working Days</p>
            <Stack
              direction="row"
              style={{ flexWrap: "wrap", rowGap: "10px" }}
              spacing={1}
            >
              {weekdays.map((day, index) => (
                <Chip
                  key={index}
                  color={selectedChip.includes(day) ? "primary" : "default"}
                  onClick={() => handleChange(day)}
                  label={day}
                />
              ))}
            </Stack>
          </div>
          <div className="d-flex justify-content-end">
            <button className="btn-cancel" onClick={() => setShiftModal(false)}>
              Cancel
            </button>
            <button
              className="btn-approve"
              disabled={!isFormValid()}
              onClick={assignShiftHandler}
            >
              Submit
            </button>
          </div>
        </Box>
      </Modal>
    </div>
  );
}

export default ShiftChangeModal;
