import React, { useEffect, useState } from "react";
import "./PageInfoHeader.css";
import { useLocation, useNavigate } from "react-router-dom";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import { formatString, getGreeting } from "../../../services/common-service";
import { cleanDigitSectionValue } from "@mui/x-date-pickers/internals/hooks/useField/useField.utils";

const PageInfoHeader = () => {
  const [breadCrumbs, setBreadCrumbs] = useState([]);
  const [currentPageName, setCurrentPageName] = useState(null);
  const { pathname } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    setBreadCrumbs(
      ["home"].concat(pathname?.split("/").filter((item, index) => index > 0))
    );
    setCurrentPageName(pathname.split("/").reverse()[0]);
  }, [pathname]);

  const handlePath = (item, i) => {
    if (pathname.split("/")[2] === "shift" && i == 2) {
      // console.log("shift");
      navigate(`/reportees/${pathname.split("/")[3]}`);
    } else {
      navigate(item);
    }
  };

  return (
    <div
      className={
        currentPageName === "home" ? "  page-header-container " : " ml-3"
      }
    >
      <div className="d-flex justify-content-between text-black">
        <div>
          {currentPageName !== "home" &&
            breadCrumbs &&
            breadCrumbs?.length > 2 && (
              <Breadcrumb>
                {breadCrumbs?.map((item, index) => {
                  return (
                    <Breadcrumb.Item
                      active={currentPageName === item}
                      className="pages-breadcrumb text-black "
                      onClick={() => handlePath(item, index)}
                      key={index}
                    >
                      {formatString(item)}
                    </Breadcrumb.Item>
                  );
                })}
              </Breadcrumb>
            )}
          <div className={currentPageName === "home " ? "mt-2 " : "mt-3"}>
            {/* <h1 className="page-header-title">Good Morning,</h1> */}
            <h1 className="page-header-title text-left font-style ">
              {currentPageName === "home"
                ? `Good ${getGreeting()}, ${
                    JSON.parse(
                      localStorage.getItem("user_data") || "{}"
                    )?.name?.split(" ")?.[0]
                  }`
                : ""}
            </h1>
          </div>
        </div>
        {currentPageName === "home" && (
          <div>
            <img
              src={require("../../../../assets/img/header-img.png")}
              alt="headerImage"
              style={{ width: "210px" }}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default PageInfoHeader;
