import React, { useEffect, useState } from "react";
import './ShiftChange.css';
import { useParams } from "react-router-dom";
import { toastError, toastSuccess } from "../../../../shared/ToastMsg/ToastMsgs";
import ShiftChangeModal from "../ShiftChange/ShiftChangeModal/ShiftChangeModal";
import { createUpdateUserShift, getShiftList, getWeekDays } from "./ShiftChange-service";
import { formatTime } from "../../../../../services/common-service";

const ShiftChange = () => {
  const [shifts, setShifts] = useState([]);
  const params = useParams();
  const [refresh, setRefresh] = useState(false)
  const [isShiftModal, setShiftModal] = useState(false);


  useEffect(() => {
    if (params?.id) {
      getShiftList(params?.id)
        .then((reply) => {
          setShifts(reply?.data || []);
          setRefresh(false);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [params?.id, refresh]);


  const assignShiftToUser = (shift) => {
    const shiftObj = {
      'id': shift?.id,
      'shift_name': shift?.shift_name,
      'start_time': new Date(shift?.start_time),
      'end_time': new Date(shift?.end_time),
      'minimum_hours': shift?.minimum_hours,
      'relaxation_time': shift?.relaxation_time,
      'working_days': typeof (shift?.working_days) === 'string' ? shift?.working_days.split(",") : shift?.working_days,
      'user_id': params?.id
    }
    createUpdateUserShift(shiftObj).then(reply => {
      setRefresh(true);
      toastSuccess(reply?.data?.message || "shift has been changed");
    }).catch(err => {
      console.log(err?.reply?.response?.data?.message)
      toastError(err?.reply?.response?.data?.message)
    });
  }

  return (
    <>
      <h4 className="d-flex justify-content-end pt-3 pr-48"><button className="btn btn-primary create-shift-btn" onClick={() => setShiftModal(true)}>Create & Assign</button> </h4>    {shifts?.map(shift => {
        return <div className="row reporte-card-container align-items-center shift-cursor-default">
          <div className="col-md-4">
            <div className="row justify-content-around">
              <p className="m-0 reporte-card-text">{shift?.name}</p>
              <div className="d-flex justify-content-center w-50 align-items-center border-card-reportee"
                style={{ columnGap: "40px" }}>
                <div className="">
                  <p className="reporte-container-time m-0">{formatTime(shift?.start_time)}</p>
                  <p className="m-0 reporte-container-ontime">Start Time</p>
                </div>
                <div className="">
                  <p className="text-center m-0 reporte-container-time">{formatTime(shift?.end_time)}</p>
                  <p className="m-0 reporte-container-ontime">End Time</p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="row">
              <p className="m-0 reporte-card-text">Working Days</p>
              <div className="my-chip-container justify-content-center w-50 align-items-center border-card-reportee">
                {getWeekDays().map(item => {
                  return <div className={"my-chip " + (!shift?.working_days?.includes(item) ? "disabled" : "")}>{item}</div>;
                })}
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="row justify-content-around">
              <p className="m-0 reporte-card-text">Timing Rules</p>
              <div className="d-flex justify-content-center w-50 align-items-center"
                style={{ columnGap: "40px" }}>
                <div className="">
                  <p className="reporte-container-time m-0">{shift?.minimum_hours} Hours</p>
                  <p className="m-0 reporte-container-ontime">Minimum Hours</p>
                </div>
                <div className="">
                  <p className="text-center m-0 reporte-container-time">{shift?.relaxation_time} Minutes</p>
                  <p className="m-0 reporte-container-ontime">Relaxation Time</p>
                </div>
              </div>
              {shift?.currentShift ? <p className="assigned" >Assigned</p> :
                <button className="btn btn-outline-primary" onClick={() => assignShiftToUser(shift)}>Assign</button>}
            </div>
          </div>
        </div>
      })}
      <ShiftChangeModal isShiftModal={isShiftModal} setShiftModal={setShiftModal} assignShiftToUser={assignShiftToUser} />

    </>
  );
}

export default ShiftChange;

