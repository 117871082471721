// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Minimumdropdown {
  /* Add your styles here */
}

.time-picker-dropdown{
  z-index: 10000;
}
.width-full .ant-picker-outlined{
  width: 100% !important;
  border-color: #d9d9d9 !important;
  border:1px solid #D9D9D9 !important;
}
.width-full{
  /* border:1px solid #D9D9D9; */
  border-radius:10px ;
}`, "",{"version":3,"sources":["webpack://./src/v1/components/pages/Reportee/Web/ShiftChange/Minimumdropdown/Minimumdropdown.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;AAC3B;;AAEA;EACE,cAAc;AAChB;AACA;EACE,sBAAsB;EACtB,gCAAgC;EAChC,mCAAmC;AACrC;AACA;EACE,8BAA8B;EAC9B,mBAAmB;AACrB","sourcesContent":[".Minimumdropdown {\n  /* Add your styles here */\n}\n\n.time-picker-dropdown{\n  z-index: 10000;\n}\n.width-full .ant-picker-outlined{\n  width: 100% !important;\n  border-color: #d9d9d9 !important;\n  border:1px solid #D9D9D9 !important;\n}\n.width-full{\n  /* border:1px solid #D9D9D9; */\n  border-radius:10px ;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
