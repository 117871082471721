// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Calendertogglebutton {
  /* Add your styles here */
}
.tabs-container {
  display: flex;
  justify-content: space-around;
  background-color: white;
  padding: 10px;
  width: 100%;
  border-radius: 5px;
}

.tab {
  padding: 4px 20px;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s ease;
  width: 100%;
}

.tab.active {
  background-color: #7647eb;
  color: white;
}

.tab:not(.active):hover {
  background-color: #f0f0f0;
}
`, "",{"version":3,"sources":["webpack://./src/v1/components/shared/Calendertogglebutton/Calendertogglebutton.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;AAC3B;AACA;EACE,aAAa;EACb,6BAA6B;EAC7B,uBAAuB;EACvB,aAAa;EACb,WAAW;EACX,kBAAkB;AACpB;;AAEA;EACE,iBAAiB;EACjB,eAAe;EACf,kBAAkB;EAClB,sCAAsC;EACtC,WAAW;AACb;;AAEA;EACE,yBAAyB;EACzB,YAAY;AACd;;AAEA;EACE,yBAAyB;AAC3B","sourcesContent":[".Calendertogglebutton {\n  /* Add your styles here */\n}\n.tabs-container {\n  display: flex;\n  justify-content: space-around;\n  background-color: white;\n  padding: 10px;\n  width: 100%;\n  border-radius: 5px;\n}\n\n.tab {\n  padding: 4px 20px;\n  cursor: pointer;\n  border-radius: 5px;\n  transition: background-color 0.3s ease;\n  width: 100%;\n}\n\n.tab.active {\n  background-color: #7647eb;\n  color: white;\n}\n\n.tab:not(.active):hover {\n  background-color: #f0f0f0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
