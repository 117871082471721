import React, { useEffect, useState } from "react";
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import './Attendancecalender.css';
import moment from "moment";

const Attendancecalender = ({ calenderList }) => {

  const [value, setValue] = useState(new Date());
  useEffect(() => {
    if (calenderList?.month) {
      const [year, month, day] = calenderList.month.split('/');
      const formattedDate = new Date(year, month - 1, day); 
      setValue(formattedDate);
    }
  }, [calenderList])
  
  const formatDate = (date) => {
    const [day, month, year] = date.split('/');
    const parsedDate = new Date(`${year}-${month}-${day}T00:00:00`);
    return isNaN(parsedDate) ? null : parsedDate.toISOString().split('T')[0];
  };

  // Construct the attendanceData object from calenderList
  const attendanceData = calenderList?.data?.reduce((acc, item) => {
  
    const date = formatDate(item.date);
    if (!date) return acc;  
    if (item.data.absent) acc[date] = "A";
    if (item.data.present) acc[date] = "P";
    if (item.data.off_day) acc[date] = "O";
    if (item.data.regularized) acc[date] = "R";
    if (item.data.leave) acc[date] = "L";
    if (item.data.on_duty) acc[date] = "OD";
    if (item.data.holiday) acc[date] = "H";
    if (item.data.wfh) acc[date] = "WFH";
    if( item.data.present && item.data.wfh ) acc[date]= `WFH P`
    if( item.data.present && item.data.on_duty ) acc[date]= `OD P`
    return acc;
  }, {});

  const isToday = (date) => {
    const today = new Date();
    return date.getDate() === today.getDate() &&
           date.getMonth() === today.getMonth() &&
           date.getFullYear() === today.getFullYear();
  };

  // Function to get attendance status for a specific date
  const getAttendanceStatus = (date) => {
    const dateString = date.toISOString().split('T')[0]; // Format date to 'YYYY-MM-DD'
    return attendanceData?.[dateString] || '';
  };

  // Mapping status code to corresponding CSS class
  const statusToClassMap = {
    'p': 'present-mark', // 'P' will use the 'Present' CSS class 
  };

  // Function to render tile content
  const tileContent = ({ date, view }) => {
    if (view === 'month') {
      const status = getAttendanceStatus(date)?.split(' ')?.map((item) => item?.toLowerCase()) ?? [];
return <>
      {status?.map((item, index) => {
        const className = statusToClassMap[item] || item; // Use the mapped class if available, else fallback to the status itself
        return <div key={index} className={`attendance-mark  ${className}`}>{item.toUpperCase()}</div>})}
      </>
    }
    return null;
  };

  return (
    <>
      <Calendar
        value={value}
        maxDetail="month" 
        selectRange={false} 
        tileContent={tileContent}
        className="unclickable-calendar"
        
      />
      <div>
        <p className="legend-heading p-2">Legends</p>
        <div className="d-flex flex-wrap justify-content-between legend-container">
          <p className="legend-name mb-2"> <span className="legend-border color-1">P</span> Present </p>
          <p className="legend-name mb-2"> <span className="legend-border color-2">A</span> Absent </p>
          <p className="legend-name mb-2"> <span className="legend-border color-3">O</span> Off Day </p>
          <p className="legend-name mb-2"> <span className="legend-border color-4">R</span> Regularize</p>
          <p className="legend-name mb-2"> <span className="legend-border color-5">L</span> Leave </p>
          <p className="legend-name mb-2"> <span className="legend-border color-6">OD</span> On Duty </p>
          <p className="legend-name mb-2"> <span className="legend-border color-7">H</span> Holiday </p>
          <p className="legend-name mb-2"> <span className="legend-border color-7">WFH</span> Work From Home </p>
        </div>
      </div>
    </>
  );
}

export default Attendancecalender;
