import React from "react";
import "./WebDashboardCard.css";
import { useNavigate } from "react-router-dom";

const WebDashboardCard = ({ item, adminData }) => {
  const navigatePath = () => {
    switch (item?.desc_key) {
      case "officeCount":
        return "office";
      case "departmentCount":
        return "department-count";
      case "shiftCount":
        return "shift-count";
      case "userCount":
        return "user-count";
      default:
        return "";
    }
  };
  const navigate = useNavigate();
  return (
    <>
      <div
        className="bg-white h-100 admin-dash-card-padding card-radius"
        onClick={() => navigate(`/dashboard/${navigatePath()}`)}
      >
        <div className="dashboard-icon-container">{item?.img}</div>

        <p className="review">{item?.heading || "Title"}</p>
        <p className="admin-count m-0">
          {adminData?.[item?.desc_key]?.toLocaleString("en-IN") || 0}
        </p>
        <div className="d-flex justify-content-end cursor-pointer">
          <div className="navigate-btn-dashboard">{item?.navigateImg}</div>
        </div>
      </div>
    </>
  );
};

export default WebDashboardCard;
