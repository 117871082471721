import React, {useEffect, useState} from "react";
import './Sidebar.css';
import {Container, Nav, Navbar, NavItem, NavLink} from "reactstrap";
import {NavLink as NavLinkRRD, useLocation} from "react-router-dom";

const Sidebar = () => {
    const localData = JSON.parse(localStorage.getItem("user_data"));
    const roleName=localData?.role?.name
    const [ currentPathName, setCurrentPathName ] = useState("home");
    const { pathname } = useLocation();

    useEffect(() => {
        setCurrentPathName(pathname?.split('/')?.[2])
    }, [pathname]);
  return (
    <>

        <Navbar className="navbar-vertical fixed-left navbar-light bg-white" expand="md" id="sidenav-main">
            <Container fluid className="mt-5">
                <div className="sidebar-items-container">
                    <Nav navbar className="nav-item">
                        <NavItem className="sidebar-btn">
                            <NavLink to="/home" tag={NavLinkRRD} active={currentPathName === 'home'}> Home </NavLink>
                           {roleName==="admin" && <NavLink to="/dashboard" tag={NavLinkRRD} active={currentPathName === 'dashboard'}> Dashboard </NavLink>}
                            <NavLink to={"/attendance"} tag={NavLinkRRD} active={currentPathName === 'attendance'}> Attendance </NavLink>
                            <NavLink to={"/leave"} tag={NavLinkRRD} active={currentPathName === 'leave'}> Leave </NavLink>
                            {roleName.toLowerCase()==="manager" &&<NavLink to="/reportees?tab=current" tag={NavLinkRRD}
                                      active={['reportees', 'reportee'].includes(currentPathName)}> Reportees </NavLink>}
                            <NavLink to="/manager" tag={NavLinkRRD} active={currentPathName === 'manager'}> Manager </NavLink>
                            {roleName==="admin" && <NavLink to="/report" tag={NavLinkRRD} active={currentPathName === 'report'}> Report </NavLink>}
                        </NavItem>
                    </Nav>
                </div>
            </Container>
        </Navbar>
    </>
  );
}

export default Sidebar;
