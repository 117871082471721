import React from 'react'
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 450,
  bgcolor: 'background.paper',
  borderRadius:"12px",
  p: 2,
  
};

function HeadAssignModal(isAssign , setAssign) {
    // const handleOpen = () => setOpen(true);
    // const handleClose = () => setOpen(false);
  
    return (
      <div>
        <Modal
          open={isAssign}
        //   onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
           <div className=' d-flex flex-column' style={{rowGap:"40px"}} >
           <p>Do you want to Assign new Department Head ?</p>
            <div className='d-flex justify-content-end' style={{columnGap:"10px"}}>
                <button type="button" className="btn btn-danger">Cancel </button>
                <button type="button" className=" btn-primary">Assign Head</button>
            </div>
           </div>
          </Box>
        </Modal>
      </div>
  )
}

export default HeadAssignModal