import React from "react";
import './Calendertogglebutton.css';

const Calendertogglebutton = ({ isChecked, checkedHandler }) => {
  return (
    <div className="tabs-container">
      <div className={`tab ${!isChecked ? 'active' : ''}`} onClick={() => checkedHandler({ target: { checked: false } })}>
        Calendar View
      </div>
      <div className={`tab ${isChecked ? 'active' : ''}`} onClick={() => checkedHandler({ target: { checked: true } })}>
        Day by view
      </div>
    </div>
  );
}

export default React.memo(Calendertogglebutton);
