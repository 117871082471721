import Leave from "../../../../../assets/img/CardImg/Leave.webp";
import RightArrow from "../../../../../assets/img/CardImg/ArrowRight.svg";
import Employee from "../../../../../assets/img/CardImg/employee.svg";
import AttnedanceImg from "../../../../../assets/img/CardImg/attendance.webp";

import React from "react";

export const SMALL_CARD_DATA = [
    {
        heading: "Leave",
        desc: "Simplify the leave approval process and gain instant visibility into leave balances and employee absence patterns.",
        img: <img src={Leave} style={{width: "45%"}} />,
        navigateImg: <img src={RightArrow} />,
        navigate:"/leave"
    },
    {
        heading: "Employees",
        desc: "Easily manage and update employee records with real-time data, ensuring accurate and up-to-date information at your fingertips.",
        img: <img src={Employee} style={{width: "45%"}} />,
        navigateImg: <img src={RightArrow} />,
        navigate:""
    },
    {
        heading: "Attendance",
        desc: "View your daily sign-ins, sign-outs, and total hours worked to stay on top of your attendance.",
        img: <img src={AttnedanceImg} style={{width: "32%"}} />,
        navigateImg: <img src={RightArrow} />,
        navigate:"/attendance"
    }
];
