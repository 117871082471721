import React, { useEffect, useState } from "react";
import "./Dashboard.css";
import UserProfileCard from "../../shared/UserProfileCard/UserProfileCard";
import { Container } from "react-bootstrap";
import HomeWebSmallCard from "../Home/Web/HomeWebSmallCard/HomeWebSmallCard";
import { ADMIN_CARDS } from "./WebDashboardCard/WebDashboardCard-service";
import WebDashboardCard from "./WebDashboardCard/WebDashboardCard";
import { getadminData } from "./Dashboard-service";
import { toastError } from "../../shared/ToastMsg/ToastMsgs";

const Dashboard = ({ homeData }) => {
  const [dastboardData,setDashboardData]=useState(null)

  const dashboardCount = () => {
    getadminData().then((res) => {
      setDashboardData(res?.data?.data)
    }).catch(err=>toastError(err?.res?.data?.message))
  }
  useEffect(() => {
    dashboardCount()
  }, [])

  return (
    <>
      <Container fluid className="home-container">
        <div className="d-flex flex-column rg-40">
          <div className="row row-card-gap mb-2r">
            {ADMIN_CARDS.map((item, index) => {
              return (
                <div className="col-md-3 card-radius p-0-12">
                  <WebDashboardCard
                    item={item}
                    adminData={dastboardData}
                  />
                </div>
              );
            })}
          </div>
        </div>
      </Container>
    </>
  );
};

export default Dashboard;
