import api from "../../../../services/api";

export const getDepartments = () => {
    return api.get(`/department`);
};

export const getManagers = (params) => {
    return api.get(`/user/eligible_managers`, { params });
};

export const postManagers = (data) => {
    return api.post(`/user/change_manager`, data);
};
