import React, { useState } from "react";
import DateRangePicker from "../../shared/DateRangePicker/DateRangePicker";
import { generateReport } from "./Report-service";
import { toastError, toastSuccess } from "../../shared/ToastMsg/ToastMsgs";
import "./Report.css";

const Report = () => {
  const [active, setActive] = useState("attendance");
  const [selectedDates, setSelectedDates] = useState([null, null]);
  const [email, setEmail] = useState("");
  const [error, setError] = useState('');

  const handleChange = (e) => {
    const value = e.target.value;
    setEmail(value);

    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (value === '') {
      setError('Email is required');
    } else if (!emailPattern.test(value)) {
      setError('Invalid email format');
    } else {
      setError('');
    }
  };

  const getReport = () => {
    const params = {
      reportType: active,
      startDate: selectedDates?.[0],
      endDate: selectedDates?.[1],
      email: email,
    };

    generateReport(params)
      .then((res) => {
        setSelectedDates([null, null]); // Clear selected dates
        setEmail(""); // Clear email field
        toastSuccess(res?.data?.message || "Attendance report generated and sent successfully");
      })
      .catch((err) => {
        setSelectedDates([null, null]); // Clear selected dates even on error
        setEmail(""); // Clear email field
        toastError(err?.response?.data?.message || "Request failed");
        console.error("err", err);
      });
  };

  const isDatesEmpty = selectedDates?.every(date => date === null);

  return (
    <div className="col-md-3 mt-3 ml-2 report-container">
      <div className="bg-white p-4 d-flex flex-column border-radius-report report-row-gap">
        <p className="type-of-text">Type of Report</p>
        <div className="d-flex align-items-center cg-15">
          <p
            className={active === "attendance" ? "attendance-report selected-report" : "attendance-report non-selected-report"}
            onClick={() => setActive("attendance")}
          >
            Attendance Report
          </p>
          <p
            className={active === "regularize" ? "attendance-report selected-report" : "attendance-report non-selected-report"}
            onClick={() => setActive("regularize")}
          >
            Regularize Report
          </p>
        </div>
        <div>
          <p className="date-range">Date Range</p>
          <div className="date-range-border">
            <DateRangePicker
              selectedDates={selectedDates}
              setSelectedDates={setSelectedDates}
            />
          </div>
        </div>
        <div>
          <p className="date-range">Email</p>
          <input
            className={`form-control email-input ${error ? 'is-invalid' : ''}`}
            value={email}
            onChange={handleChange}
            placeholder="Enter your Email"
            type="email"
          />
          {error && <div className="invalid-feedback">{error}</div>}
        </div>
        <div className="mt-3">
          <button
            className="generate-btn"
            disabled={isDatesEmpty }
            onClick={getReport}
          >
            Generate Report
          </button>
        </div>
      </div>
    </div>
  );
};

export default Report;
