import React from "react";
import "./HomeWebSmallCard.css";
import {useNavigate} from "react-router-dom";

const HomeWebSmallCard = ({item, userData ,admin}) => {

    const navigate = useNavigate();

  return (
    <>
        <div className='bg-white h-100 card-padding card-radius cursor-pointer' onClick={!admin ? () => navigate(item?.navigate) : undefined}>
            {item?.img}
            <p className="review">{item?.heading || 'Title'}</p>
            <p className={admin?"admin-count m-0":"leave-text"}>
                {item?.desc}
            </p>
            <div className="d-flex justify-content-end cursor-pointer" onClick={!admin ? () => navigate(item?.navigate) : undefined}>
                {item?.navigateImg}
            </div>

        </div>
    </>
  );
};

export default HomeWebSmallCard;
