import React, { useContext, useEffect, useState } from "react";
import "./Login.css";
import Container from "react-bootstrap/Container";
import star from "../../../../assets/img/brand/star.svg";
import LoginSvg from "../../../../assets/img/CardImg/LoginPageLogo.svg";
import { AppContext } from "../../../../AppContext";
import LoginForm from "./LoginForm/LoginForm";
import {
  toastError,
  toastSuccess,
} from "../../shared/ToastMsg/ToastMsgs";
import { useNavigate } from "react-router-dom";
import { sendOTP, verifyOTP } from "./Login-service";

const Login = () => {
  const [isOtpValue, setOtpValue] = React.useState("");
  const [isOtp, setOtp] = useState(false);
  const [isVerifiedOtp, setVerifiedOtp] = useState(false);
  const [isBtn, setBtn] = useState(true);
  const [isDisable, setDisable] = useState(false);
  const [changeNumber, setChangeNumber] = useState(false);
  const [formData, setFormData] = useState({
    phoneNumber: "",
  });
  const [validationErrors, setValidationErrors] = useState({
    phoneNumber: "",
  });
  const { setValue, isMobView, deviceId } = useContext(AppContext);
  const navigate = useNavigate();

  const handleChange = (newValue) => {
    setOtpValue(newValue);
  };
  const handleKeyPress = (e) => {
    const allowedKeys = [
      "0",
      "1",
      "2",
      "3",
      "4",
      "5",
      "6",
      "7",
      "8",
      "9",
      "Backspace",
      "Delete",
      "ArrowLeft",
      "ArrowRight",
      "Tab",
      "Enter",
    ];
    // Allow copy, paste, cut, select all, and other common shortcuts
    if (e.ctrlKey || e.metaKey) {
      switch (e.key) {
        case "a": // Select All
        case "c": // Copy
        case "v": // Paste
        case "x": // Cut
        case "z": // Undo
        case "y": // Redo
          return;
      }
    }
    if (!allowedKeys.includes(e.key)) {
      e.preventDefault();
    }
  };
  useEffect(() => {
    if (isOtpValue.length === 4) {
      const data = {
        phoneNumber: formData.phoneNumber,
        otp: isOtpValue,
        deviceId: deviceId,
        lat: 27.987654,
        long: 77.0987654,
      };
      verifyOTP(data)
        .then((response) => {
          setBtn(true);
          setValue(response?.data?.data);
          localStorage.setItem(
            "user_data",
            JSON.stringify(response?.data?.data)
          );
          toastSuccess(response?.data?.message);
          navigate("/home");
        })
        .catch((err) => {
          toastError(err?.response?.data?.message);
        });
    }
  }, [isOtpValue]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });

    if (name === "phoneNumber") {
      const phoneRegex = /^[0-9]{10}$/;
      const isValidPhoneNumber = phoneRegex.test(value);
      setValidationErrors((prevErrors) => ({
        ...prevErrors,
        phoneNumber: isValidPhoneNumber ? "" : "Invalid phone number format",
      }));
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const { phoneNumber } = formData;

    const data = {
      phoneNumber,
    };
    const numberRegex = /^[0-9]{10}$/;
    const errors = {
      phoneNumber: "",
    };
    if (!numberRegex.test(phoneNumber)) {
      errors.phoneNumber = "Number format is invalid.";
    }

    if (errors.phoneNumber) {
      setValidationErrors(errors);
    } else {
      setValidationErrors({});
      sendOTP(data)
        .then((response) => {
          toastSuccess(response?.data?.message);
          setOtp(true);
          setDisable(true);
          setBtn(false);
          setVerifiedOtp(true);
          setChangeNumber(true);
        })
        .catch((error) => {
          toastError(error?.response?.data?.message);
        });
    }
  };
  return (
    <>
      <div>
        {!isMobView && (
          <div className="bg-white h-100">
            <div className="d-flex ">
              <Container className="w-50">
                <div className="login-container ">
                  <LoginForm
                    handleSubmit={(e) => handleSubmit(e)}
                    handleInputChange={handleInputChange}
                    formData={formData}
                    handleChange={handleChange}
                    isOtpValue={isOtpValue}
                    isVerifiedOtp={isVerifiedOtp}
                    isBtn={isBtn}
                    validationErrors={validationErrors}
                    isOtp={isOtp}
                    star={star}
                    disable={isDisable}
                    setDisable={setDisable}
                    setOtp={setOtp}
                    setVerifiedOtp={setVerifiedOtp}
                    setBtn={setBtn}
                    changeNumber={changeNumber}
                    handleKeyPress={handleKeyPress}
                    
                  />
                  {/* <div className="divider">
                    <span>Or</span>
                  </div>
                  <div>
                    <p
                      className="reg-org"
                      onClick={() => navigate("register-organisation")}
                    >
                      Register New Organisation
                    </p>
                  </div> */}
                </div>
              </Container>
              <div className="w-50" style={{ paddingLeft: "9%" }}>
                <img
                  src={LoginSvg}
                  alt=""
                  style={{ width: "100%"}}
                />
              </div>
            </div>
          </div>
        )}
        {isMobView && (
          <div className="LoginFormContainerMobile">
            <LoginForm
              star={star}
              validationErrors={validationErrors}
              setValidationErrors={setValidationErrors}
              handleSubmit={handleSubmit}
              handleInputChange={handleInputChange}
              formData={formData}
              handleChange={handleChange}
              isOtpValue={isOtpValue}
              isVerifiedOtp={isVerifiedOtp}
              isBtn={isBtn}
              isOtp={isOtp}
              disable={isDisable}
              setDisable={setDisable}
              setOtp={setOtp}
              setVerifiedOtp={setVerifiedOtp}
              setBtn={setBtn}
              changeNumber={changeNumber}
              handleKeyPress={handleKeyPress}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default Login;
