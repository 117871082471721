import React, { useState, useEffect } from "react";
import "./Mobile.css";
import { Link, useNavigate } from "react-router-dom";
import ArrowLeft from "../../../../../assets/img/si_Arrow_left.svg";
import ArrowIcon from "../../../../../assets/img/arrow_right.svg";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import PropTypes from "prop-types";
import AttendanceListingItemCard from "../ListingItemCard/ListingItemCard";
import AttendanceDonoughtChart from "../DonoughtChart/DonoughtChart";
import AttendanceProgressChart from "../ProgressChart/ProgressChart";
import PageHeaderMobile from "../../../shared/PageHeaderMobile/PageHeaderMobile";
import { getRequiredRegularization } from "./Mobile-service";
import Calendertogglebutton from "../../../shared/Calendertogglebutton/Calendertogglebutton";
import Attendancecalender from "../../../shared/Attendancecalender/Attendancecalender";
import Attendancerecordcalender from "../Web/Attendancerecordcalender/Attendancerecordcalender";

const AttendanceMobile = ({ data, attendanceDate, setAttendanceDate }) => {
  const { attendanceSummary, attendanceList, attendancePerformance, checkedHandler, isChecked, setChecked, calenderList } = data;
  const [tabValue, setTabValue] = useState(0);
  const [requiredRegularizationCount, setRequiredRegularizationCount] =
    useState(0);
  const navigate = useNavigate();
  const { managers } = JSON.parse(localStorage.getItem("user_data") || "{}");

  useEffect(() => {
    getRequiredRegularization()
      .then((reply) =>
        setRequiredRegularizationCount(reply?.data?.data?.required_count || 0)
      )
      .catch((err) => console.log(err));
  }, []);

  const CustomTabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
      </div>
    );
  };

  CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleNavigate = () => {
    if (managers.length === 0) {
      navigate("/profile-detail");
    } else {
      navigate("/regularization");
    }
  };

  return (
    <>
      <div style={{ backgroundColor: "#f6f8fa" }}>
        <PageHeaderMobile title="Attendance Logs" />
        {tabValue === 0 && (
          <div
            className="regularization-btn"
            type="button"
            onClick={handleNavigate}
          >
            <span className="btn-text">
              Apply Regularization
              <div className="regularization-count">
                {requiredRegularizationCount}
              </div>
            </span>
            <div className="mr-0">
              {""}
              <img src={ArrowIcon} />
            </div>
          </div>
        )}
        <div className="attendance-tab">
          <Box sx={{ width: "100%" }}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                value={tabValue}
                onChange={handleChange}
                aria-label="basic tabs example"
              >
                <Tab label="Daily Records" {...a11yProps(0)} />
                <Tab label="Report" {...a11yProps(1)} />
              </Tabs>
            </Box>

            <CustomTabPanel value={tabValue} index={0}>
              <div className="d-flex w-100 bg-white m-0">
                <Calendertogglebutton isChecked={isChecked} checkedHandler={checkedHandler} />
              </div>

              <div className="d-flex justify-content-center pt-1 bg-white">
                {<Attendancerecordcalender attendanceDate={attendanceDate} setAttendanceDate={setAttendanceDate} />}
              </div>

              {isChecked ? (
               (
                  attendanceList?.user?.attendance?.map((attendanceData) => (
                    <AttendanceListingItemCard
                      key={attendanceData.id} 
                      attendanceData={attendanceData}
                    />
                  ))
                )
              ) : (
                  <div>
                      <Attendancecalender attendanceData={attendanceList} calenderList={calenderList} />
                  </div>
              )}

            </CustomTabPanel>
            <CustomTabPanel value={tabValue} index={1}>
              <AttendanceDonoughtChart attendanceSummary={attendanceSummary} />
              <AttendanceProgressChart
                attendancePerformance={attendancePerformance}
              />
            </CustomTabPanel>
          </Box>
        </div>
      </div>
    </>
  );
};

export default AttendanceMobile;
