import ArrowRight from "../../../../../assets/img/arrow_right.svg";
import AttPerson from "../../../../../assets/img/att_person.webp";
import LeavePerson from "../../../../../assets/img/leave_person.webp";
import React from "react";

export const MOBILE_HOME_DATA = [
    {
        navigateTo: "attendance",
        attText: " Attendance Logs",
        atttextSub: "All time Attendence log with predictive analytics",
        icon: ArrowRight,
        iconPerson: AttPerson
    },
    {
        navigateTo: "leave",
        attText: "Leaves",
        atttextSub: "Seamlessly integrate the leave & update attendance",
        icon: ArrowRight,
        iconPerson: LeavePerson
    }
];

export const renderGridItemView = (data) => {
    const {icon, statusText, textPart, colorName, status} = data;
    return <div className="overview_card">
        <div className="OverViewCardOne">
            <img src={icon} height="20px" />
            <span className="OverViewCardOneText">{statusText}</span>
        </div>
        <div>
            <div className="AttendanceCheckInOutCont">{textPart}</div>
            <div className={`AttendanceStatus${colorName}`}>{status}</div>
        </div>
    </div>
}
