import React, { useContext, useEffect, useState } from "react";
import "./Mobile.css";
import HomeWebHolidayCard from "../HomeHolidayCard/HomeHolidayCard";
import { MOBILE_HOME_DATA, renderGridItemView } from "./Mobile-service";
import HomeMobileRowCard from "./HomeMobileRowCard/HomeMobileRowCard";
import LoginIcon from "../../../../../assets/img/login_in.svg";
import LogoutIcon from "../../../../../assets/img/login_out.svg";
import Averagecon from "../../../../../assets/img/average.svg";
import CalendarIcon from "../../../../../assets/img/calendar.svg";
import Avatar from "@mui/material/Avatar";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import {
  getAvatarInitials,
  getGreeting,
} from "../../../../services/common-service";
import HomeFooter from "./HomeFooter/HomeFooter";
import { AppContext } from "../../../../../AppContext";

const HomeMobile = ({
  homeData,
  logoutHandler,
  selectedTab,
  setSelectedTab,
}) => {
  const [userLocation, setUserLocation] = useState(null);
  const navigate = useNavigate();
  const { setIsPunch } = useContext(AppContext);

  // useEffect(() => {
  //   console.log("selected tab" , selectedTab);
  //   if (selectedTab === "home") {
  //     navigate("/home");
  //   } else {
  //     navigate("/profile");
  //   }
  // }, [selectedTab]);

  useEffect(() => {
    setIsPunch(homeData?.attendance?.check_in_time === "Yet to sign in");
  }, [homeData]);

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setUserLocation({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          });
        },
        (error) => {
          console.error("Error fetching user's location:", error);
        }
      );
    } else {
      console.error("Geolocation is not supported by this browser.");
    }
  }, []);

  function doPunchAction() {
    if (
      homeData?.attendance?.check_in_time != "Yet to sign in" &&
      homeData?.attendance?.check_out_time != "Yet to sign out"
    ) {
      return toast.warning(
        "Your attendacce has been already capture for today."
      );
    }
    navigate("/attendance-action");
  }

  const handleAvtarClick = () => {
    setSelectedTab("profile");
    navigate("/profile");
  };

  return (
    <div style={{ backgroundColor: "#F6F8FA" }}>
      <div className="mob-signin-div">
        <div className="MobSignInContainerOne">
          <div>
            <span
              className={
                homeData?.user?.name?.split(" ")[0].length > 6
                  ? "MobHeadingTextNew"
                  : "MobHeadingText"
              }
            >
              {getGreeting()}, {homeData?.user?.name?.split(" ")[0]}
            </span>
            <div className="MobHeadingTextTwo">Let's be productive today!</div>
          </div>
          <Avatar className="AvtarImg" onClick={handleAvtarClick}>
            {getAvatarInitials(homeData?.user?.name)}
          </Avatar>
        </div>
        <div className="wave">
          <button
            className={
              homeData?.attendance?.check_in_time === "Yet to sign in"
                ? "sign-in-btn"
                : homeData?.attendance?.check_out_time === "Yet to sign out"
                ? "sign-out-btn"
                : "sign-in-btn"
            }
            onClick={() => doPunchAction()}
          ></button>
        </div>
      </div>
      <div className="TodaysOverViewText">Today's Overview</div>
      <div className="oc_container">
        <div className="overview_card_container_one">
          {renderGridItemView({
            icon: LoginIcon,
            statusText: "Sign In",
            textPart: homeData?.attendance?.check_in_time,
            status:
              homeData?.attendance?.check_in_time !== "Yet to sign in"
                ? homeData?.attendanceRecordsBool?.[0]?.is_late_check_in
                  ? "Arrived late"
                  : "On Time"
                : "",
            colorName: homeData?.attendanceRecordsBool?.[0]?.is_late_check_in
              ? "red"
              : "",
          })}
          {renderGridItemView({
            icon: LogoutIcon,
            statusText: "Sign Out",
            textPart: homeData?.attendance?.check_out_time,
            status:
              homeData?.attendance?.check_out_time !== "Yet to sign out"
                ? homeData?.attendanceRecordsBool?.[0]?.is_early_check_out
                  ? "Left Early"
                  : "On Time"
                : "",
            colorName: homeData?.attendanceRecordsBool?.[0]?.is_early_check_out
              ? "red"
              : "",
          })}
        </div>
        <div className="overview_card_container_two mb-1r">
          {renderGridItemView({
            icon: Averagecon,
            statusText: "Total Hrs",
            textPart: homeData?.allAttendance?.total_time,
            status: "This Month",
          })}
          {renderGridItemView({
            icon: CalendarIcon,
            statusText: "Total Days",
            textPart: homeData?.allAttendance?.total_days,
            status: "Working Days",
          })}
        </div>
      </div>
      <div className="att_cont_div">
        {MOBILE_HOME_DATA.map((item) => {
          return <HomeMobileRowCard data={item} />;
        })}
      </div>
      <HomeWebHolidayCard holidays={homeData?.holidays} />
      <div className="width-div"></div>
    </div>
  );
};

export default HomeMobile;
