// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../../assets/img/signin_bg.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `profil_bg_div {
  height: 220px;
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
}

.profile_header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.proframe {
  display: flex;
  justify-content: space-between;
  height: 58px;
  background-color: white;
  margin: 15px;
  align-items: center;
  padding: 10px;
  border-radius: 50px;
}

.proframe-new {
  height: 45px;
  background-color: white;
  margin: 15px;
  border-radius: 12px;
}

.proframe-open {
  height: 90px;
  background-color: white;
  margin: 15px;
  border-radius: 12px;
}

.proframe_sub {
  width: 45%;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.btn-div-logout {
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}
`, "",{"version":3,"sources":["webpack://./src/v1/components/pages/profile/Profile.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,yDAA6D;AAC/D;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,mBAAmB;EACnB,YAAY;AACd;;AAEA;EACE,aAAa;EACb,8BAA8B;EAC9B,YAAY;EACZ,uBAAuB;EACvB,YAAY;EACZ,mBAAmB;EACnB,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,YAAY;EACZ,uBAAuB;EACvB,YAAY;EACZ,mBAAmB;AACrB;;AAEA;EACE,YAAY;EACZ,uBAAuB;EACvB,YAAY;EACZ,mBAAmB;AACrB;;AAEA;EACE,UAAU;EACV,aAAa;EACb,6BAA6B;EAC7B,mBAAmB;AACrB;;AAEA;EACE,WAAW;EACX,aAAa;EACb,qBAAqB;EACrB,uBAAuB;AACzB","sourcesContent":["profil_bg_div {\n  height: 220px;\n  background-image: url(\"../../../../assets/img/signin_bg.svg\");\n}\n\n.profile_header {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  height: 100%;\n}\n\n.proframe {\n  display: flex;\n  justify-content: space-between;\n  height: 58px;\n  background-color: white;\n  margin: 15px;\n  align-items: center;\n  padding: 10px;\n  border-radius: 50px;\n}\n\n.proframe-new {\n  height: 45px;\n  background-color: white;\n  margin: 15px;\n  border-radius: 12px;\n}\n\n.proframe-open {\n  height: 90px;\n  background-color: white;\n  margin: 15px;\n  border-radius: 12px;\n}\n\n.proframe_sub {\n  width: 45%;\n  display: flex;\n  justify-content: space-around;\n  align-items: center;\n}\n\n.btn-div-logout {\n  width: 100%;\n  display: flex;\n  align-items: flex-end;\n  justify-content: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
