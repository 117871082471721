import { v4 as uuidv4 } from "uuid";

export const getGreeting = () => {
  const currentHour = new Date().getHours();
  if (currentHour < 12) {
    return "Morning";
  } else if (currentHour < 16) {
    return "Afternoon";
  } else {
    return "Evening";
  }
};

export const formatString = (string) => {
  if (!string) return;
  const firstChar = string.charAt(0).toUpperCase();
  const restChar = string.slice(1);
  return `${firstChar}${restChar}`;
};

export const getAvatarInitials = (name) => {
  const nameArr = name?.split(" ").map((item) => {
    if (item.charAt(0) !== ".") {
      return item.charAt(0);
    } else {
      return '';
    }
  });
  if (!nameArr || nameArr.length === 0) {
    return null;
  }
  let finalStr = nameArr[0];
  if (nameArr.length > 0) {
    return finalStr += nameArr[nameArr.length-1];
  }
  return finalStr;
};

export const formatTime = (dateString) => {
  if (!dateString) {
    return '--:--';
  }
  const date = new Date(dateString);
  const options = {
    hour: "numeric",
    minute: "numeric",
    hour12: true,
    timeZone: "Asia/Kolkata",
  };
  return date.toLocaleTimeString("en-US", options);
};

export const formatDate = (dateString) => {
  if (!dateString) {
    return;
  }
  const date = new Date(dateString.toString());
  const options = {
    timeZone: "Asia/Kolkata",
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  };

  const formatter = new Intl.DateTimeFormat("en-GB", options);
  const parts = formatter.formatToParts(date);

  const day = parts.find((part) => part.type === "day").value;
  const month = parts.find((part) => part.type === "month").value;
  const year = parts.find((part) => part.type === "year").value;

  return `${day}/${month}/${year}`;
};

export const getDeviceId = () => {
  let deviceId = localStorage.getItem("device_id");
  if (!deviceId) {
    deviceId = uuidv4();
    localStorage.setItem("device_id", deviceId);
  }
  return deviceId;
};
