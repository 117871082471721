import React, { useEffect, useState } from "react";
import './Formcomponent.css';
import  BuildingImg from "../../../../assets/img/CardImg/building.svg" 
import  LocationImg from "../../../../assets/img/CardImg/Location.svg" 
import  LatitudeImg from "../../../../assets/img/CardImg/solar_map-line-duotone (1).svg" 
import  PincodeImg from "../../../../assets/img/CardImg/pincode.svg" 
import  RadiusImg from "../../../../assets/img/CardImg/radiusImg.svg" 
import InputWithIcon from "../InputWithIcon/InputWithIcon";
import { updateOffice } from "./Formcomponent-service";
import { toastError, toastSuccess } from "../ToastMsg/ToastMsgs";

const Formcomponent = ({officeFormData,setIsDrawerOpen,setOfficeFormData,setIsOfficeData}) => {
  const [formData, setFormData] = useState({
    name: '',
    fullAddress: '',
    latitude: '',
    longitude: '',
    pincode: '',
    radius: '',
  });
  
  useEffect(() => {
    setFormData({
      name: officeFormData?.name || '',
      fullAddress: officeFormData?.full_address || '',
      latitude: officeFormData?.lat || '',
      longitude: officeFormData?.long || '',
      pincode: officeFormData?.pincode || '',
      radius: officeFormData?.oor_radius || '',
    });
  }, [officeFormData]);


  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };


  const handleSubmit = (e) => {
    e.preventDefault();
    const data = {
      params :{
        officeId:officeFormData?.id
      },
      body:{
        name:formData?.name,
        pincode:formData?.pincode,
        address:formData?.fullAddress,
        lat:parseFloat(formData?.latitude),
        long:parseFloat(formData?.longitude),
        oorRadius:parseInt(formData?.radius)
      }
    }
    updateOffice(data).then((res)=>{
      toastSuccess(res?.data?.message)
      setIsDrawerOpen(false)
      setOfficeFormData([])
      setIsOfficeData(true)
    }).catch(err=>toastError(err?.res?.data?.message || "failed to update office data"))

  };
  const handleCancel=(e)=>{
    e.preventDefault();
    setIsDrawerOpen(false)
    setOfficeFormData([])
  }

  return (
    <>
    <p className="edit-office">
      Edit Office
    </p>
    <form className="location-form bg-white border-style-container " >
      <div className="location-form-group ">
        <InputWithIcon
        icon={BuildingImg}
        label="Name"
        type="text"
        name="name"
        value={formData.name}
        onChange={handleChange}
        className="location-form-input border-none"
        required
        />
      </div>

      <div className="location-form-group">
        <InputWithIcon
        icon={LocationImg}
        label="Full Address:"
        type="text"
          name="fullAddress"
          value={formData.fullAddress}
          onChange={handleChange}
          className="location-form-input border-none"
          required
        />
      </div>

      <div className="location-form-group">
        <InputWithIcon
                icon={LatitudeImg}
                label="Latitude or Longitude"
                type="number"
                name="latitude"
                value={formData?.latitude}
                onChange={handleChange}
                className="location-form-input border-none"
                step="any"
                required
                />
      </div>

      <div className="location-form-group">
        <InputWithIcon value={formData?.longitude} 
  icon={LatitudeImg}
  className="location-form-input border-none"
  name="longitude" type="number"
   placeholder="longitude" 
   onChange={handleChange}
   />
      </div>

      <div className="location-form-group">
        <InputWithIcon
  icon={PincodeImg}
  label="Pincode:"
  type="text"
  name="pincode"
  value={formData.pincode}
  onChange={handleChange}
  className="location-form-input border-none"
  required
  />
      </div>

      <div className="location-form-group">
        <InputWithIcon
  icon={RadiusImg}
  label="Radius:"
  type="number"
  name="radius"
          value={formData.radius}
          onChange={handleChange}
          className="location-form-input border-none"
          required
          />
      </div>

    <div className="d-flex justify-content-center col-gap-btn">
    <button onClick={handleSubmit} className="btn-padding-radius update-btn" >
        Update
      </button>
    <button onClick={handleCancel} className="btn-padding-radius cancel-btn">
        Cancel
      </button>
    </div>

    </form>
          </>
  );
}




export default React.memo(Formcomponent);