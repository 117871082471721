import React, { useContext, useEffect, useState } from "react";
import { Map, Marker } from "pigeon-maps";
import "./AttendanceAction.css";
import { useNavigate } from "react-router-dom";
import BackArrow from "../../../../assets/img/back_arrow.svg";
import Cached from "../../../../assets/img/cached.svg";
import LocationIcon from "../../../../assets/img/location_icon.svg";
import { postAttendance, putAttendance } from "./AttendanceAction-services";
import { AppContext } from "../../../../AppContext";
import { toast } from "react-toastify";
import { formatDate, formatTime } from "../../../services/common-service";
import { setDefaults, fromLatLng } from "react-geocode";
import { calculateDistance } from "./AttendanceAction-services";

function AttendanceAction() {
  const { isPunch, openModal, setOpenModal, setSuccessText, deviceId, value } =
    useContext(AppContext);
  const { office } = JSON.parse(localStorage.getItem("user_data"));
  const [location, setLocation] = useState({ lat: 0, lng: 0 });
  const [loaded, setLoaded] = useState(false);
  const [locationType, setLocationType] = useState(null);
  const [distance, setDistance] = useState(null);
  const [isAnimating, setIsAnimating] = useState(false);
  const [currentLocation, setCurrentLocation] = useState("");
  const navigate = useNavigate();


  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setLocation({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          });
          setLoaded(true);
        },
        (error) => {
          if (error.code === error.PERMISSION_DENIED) {
            console.error("User denied the request for Geolocation.");
            navigate("/home");
          } else if (error.code === error.POSITION_UNAVAILABLE) {
            console.error("Location information is unavailable.");
          } else if (error.code === error.TIMEOUT) {
            console.error("The request to get user location timed out.");
          } else {
            console.error("An unknown error occurred.");
          }
        }
      );
    } else {
      console.error("Geolocation is not supported by this browser.");
    }
  }, []);

  useEffect(() => {
    setDefaults({
      key: "AIzaSyBYAAkJ_L_JnJXeD2m8gaM-wulIq2iEAkk",
      language: "en",
      region: "es",
    });

    fromLatLng(location?.lat, location?.lng)
      .then(({ results }) => {
        const { lat, lng } = results[0].geometry.location;
        setCurrentLocation(results[1]?.formatted_address);
      })
      .catch(console.error);

    setDistance(
      calculateDistance(location?.lat, location?.lng, office?.lat, office?.long)
    );
  }, [location]);

  const handleButtonClick = () => {
    setIsAnimating(true);
    calculateDistance(location?.lat, location?.lng, office?.lat, office?.long);
    setTimeout(() => setIsAnimating(false), 500);
  };

  const handleAttChange = (e) => {
    let { value } = e.target;
    if (typeof value === "string") value = parseInt(value);
    setLocationType(value);
  };

  const actionTrigger = (punchIn = true) => {
    const data = {
      attendanceTypeId: locationType,
      latitude: location?.lat,
      longitude: location?.lng,
      checkInDistance: distance,
      deviceId: deviceId,
      address: currentLocation,
    };
    if (punchIn) {
      postAttendance(data)
        .then((res) => successAction(res))
        .catch((err) =>
          toast.error(err?.response?.data?.message || "Error while punch in")
        );
    } else {
      putAttendance(data)
        .then((res) => successAction(res))
        .catch((err) =>
          toast.error(err?.response?.data?.message || "Error while punch out")
        );
    }
  };

  const successAction = (res) => {
    setSuccessText(res?.data?.message);
    setOpenModal(true);
    setTimeout(() => {
      setOpenModal(false);
      navigate("/home");
    }, 2000);
  };
  const outOfRadius = distance >= value?.office?.oor_radius;

  if (!loaded) {
    return <div>Loading...</div>;
  }

  return (
    <div className="punch_container">
      <Map defaultCenter={[location.lat, location.lng]} zoom={16}>
        <Marker color="Red" width={50} anchor={[location.lat, location.lng]} />
      </Map>
      <div className="btn_container">
        <button onClick={() => navigate("/home")} className="back_btn">
          <img src={BackArrow} />
        </button>
        <button
          className="get_help_btn"
          onClick={() =>
            (window.location = "mailto:neeraj.kumar@jarvis.consulting")
          }
        >
          Get Help
        </button>
      </div>
      <div className="punch_sub_container">
        <div
          className={`${outOfRadius ? "red_color" : "green_color"} psc_one `}
        >
          <div
            type="button"
            className={isAnimating ? "rotate-animation" : ""}
            onClick={handleButtonClick}
          >
            <img src={Cached} />
          </div>
          <span className={`ApTextOne `}>
            Current Location Accuracy: {Math.floor(distance)}m
          </span>
        </div>
        <div className="ApMarginContt">
          <div className="OfficeAddressContt">{office?.full_address}</div>
          <span className="address-contt">
            This is the allotted office Address for you.
          </span>
        </div>
        <div className="ApDashedDiv"></div>
        <div>
          <div className="location-contt">
            <img src={LocationIcon} />
            <div className="current-location-contt">{currentLocation}</div>
          </div>
          <span className="current-lcn-contt">
            This is your current Location
          </span>
        </div>
        <div className="ApDashedDiv"></div>
        <div className="ApDateTimeContt">
          <div>
            <div className="ApDateTime">Date</div>
            <div className="OfficeAddressContt">{formatDate(new Date())}</div>
          </div>
          <div>
            <div className="ApDateTime">Time</div>
            <div className="OfficeAddressContt">{formatTime(new Date())}</div>
          </div>
        </div>
        <div className="ApDashedDiv"></div>
        <div className="dropdown-container">
          <label>Sign In Location </label>
          <select
            id="location-select"
            className="custom-select"
            onChange={handleAttChange}
          >
            <option value="" selected>
              Select
            </option>
            <option value={1}>Office</option>
            <option value={2}>Work From Home</option>
            <option value={3}>On Duty</option>
          </select>
        </div>
        <div className="sign_in_btn_div">
          <button
            className={outOfRadius ? "sign_in_btn-red" : "sign_in_btn"}
            onClick={() => (isPunch ? actionTrigger() : actionTrigger(false))}
            disabled={locationType === null}
          >
            {isPunch ? "Sign in" : "Sign out"}
          </button>
        </div>
      </div>
    </div>
  );
}

export default AttendanceAction;
