import React from "react";
import "./PendingRegularizationRowItem.css";
import LoginIcon from "../../../../../../assets/img/login_in.svg";
import LogoutIcon from "../../../../../../assets/img/login_out.svg";
import { formatDate, formatTime } from "../../../../../services/common-service";

const PendingRegularizationRowItem = ({ regularData }) => {
  return (
    <>
      <div className="container-pending-div">
        <div className="modal-web-border" style={{ padding: "10px 0px" }}>
          <p className="applied-by m-0">Applied To</p>
          <p className="applied-by-name">
            {regularData?.applyTo?.name} ({regularData?.applyTo?.empCode})
          </p>
        </div>
        <div className="modal-web-border" style={{ padding: "10px 0px" }}>
          <p className="applied-by m-0">Applied Date(s)</p>
          <p className="applied-by-name">
            {formatDate(regularData?.created_at)}
          </p>
        </div>
        <div className="regular-shift-text">
          Regular shift{" "}
          {`${formatTime(
            regularData?.user?.office?.shifts?.[0]?.shift?.start_time
          )} ${formatTime(
            regularData?.user?.office?.shifts?.[0]?.shift?.end_time
          )}`}
        </div>
        <label
          htmlFor="department"
          className="profile-label"
          style={{ marginTop: "5px" }}
        >
          Captured Time
        </label>
        <div className="d-flex justify-content-between modal-web-border">
          <div
            className="d-flex align-items-start"
            style={{ columnGap: "12px" }}
          >
            <img src={LoginIcon} alt="" />
            <div>
              <p className="applied-by-name m-0">
                {formatTime(regularData?.user?.attendances?.[0]?.check_in_time)}
              </p>
              <p className="applied-by ">Sign In</p>
            </div>
          </div>
          <div
            className="d-flex align-items-start"
            style={{ columnGap: "12px" }}
          >
            <img src={LogoutIcon} alt="" />
            <div>
              <p className="applied-by-name m-0">
                {formatTime(
                  regularData?.user?.attendances?.[0]?.check_out_time
                )}
              </p>
              <p className="applied-by ">Sign Out</p>
            </div>
          </div>
          <div></div>
        </div>
        <label
          htmlFor="department"
          className="profile-label"
          style={{ marginTop: "5px" }}
        >
          Requested Time
        </label>
        <div className="d-flex justify-content-between modal-web-border">
          <div
            className="d-flex align-items-start"
            style={{ columnGap: "12px" }}
          >
            <img src={LoginIcon} alt="" />
            <div>
              <p className="applied-by-name m-0">
                {formatTime(regularData?.start_date)}
              </p>
              <p className="applied-by ">Sign In</p>
            </div>
          </div>
          <div
            className="d-flex align-items-start"
            style={{ columnGap: "12px" }}
          >
            <img src={LogoutIcon} alt="" />
            <div>
              <p className="applied-by-name m-0">
                {formatTime(regularData?.end_date)}
              </p>
              <p className="applied-by ">Sign Out</p>
            </div>
          </div>
          <div></div>
        </div>
        <div style={{ padding: "10px 0px" }}>
          <p className="m-0 reason-heading">Reason</p>
          <p className="reason-border-style">
            {regularData?.leaveReason?.name}
          </p>
        </div>
        {regularData?.remarks && (
          <div>
            <p className="m-0 reason-heading">Remarks</p>
            <p className="reason-border-style">{regularData?.remarks}</p>
          </div>
        )}
      </div>
    </>
  );
};

export default PendingRegularizationRowItem;
