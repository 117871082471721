// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.HomeWebSmallCard {
  /* Add your styles here */
}

.admin-count{
  font-family: 'Kumbh Sans';
font-size: 53px;
font-weight: 600;
text-align: left;
color: #000000;

}`, "",{"version":3,"sources":["webpack://./src/v1/components/pages/Home/Web/HomeWebSmallCard/HomeWebSmallCard.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;AAC3B;;AAEA;EACE,yBAAyB;AAC3B,eAAe;AACf,gBAAgB;AAChB,gBAAgB;AAChB,cAAc;;AAEd","sourcesContent":[".HomeWebSmallCard {\n  /* Add your styles here */\n}\n\n.admin-count{\n  font-family: 'Kumbh Sans';\nfont-size: 53px;\nfont-weight: 600;\ntext-align: left;\ncolor: #000000;\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
