// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Tab {
  /* Add your styles here */
}
.active_regularisation-tab-btn,
.regularization-tab:focus {
  /* Styles for the active or focused tab buttons */
  background-color: #7647eb;
  color: white;
  
}
.active_regularisation-tab-btn,
.regularization-tab:hover{
  background-color: #7647eb;
  
}
.regularization-tab:focus {
  background-color: #7647eb;
  outline: none;
}`, "",{"version":3,"sources":["webpack://./src/v1/components/pages/Reportee/Web/ReporteeRegularization/Tab/Tab.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;AAC3B;AACA;;EAEE,iDAAiD;EACjD,yBAAyB;EACzB,YAAY;;AAEd;AACA;;EAEE,yBAAyB;;AAE3B;AACA;EACE,yBAAyB;EACzB,aAAa;AACf","sourcesContent":[".Tab {\n  /* Add your styles here */\n}\n.active_regularisation-tab-btn,\n.regularization-tab:focus {\n  /* Styles for the active or focused tab buttons */\n  background-color: #7647eb;\n  color: white;\n  \n}\n.active_regularisation-tab-btn,\n.regularization-tab:hover{\n  background-color: #7647eb;\n  \n}\n.regularization-tab:focus {\n  background-color: #7647eb;\n  outline: none;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
