import { Avatar } from "@mui/material";
import React, { useContext } from "react";
import "./Profile.css";
import { useNavigate, Link } from "react-router-dom";
import ProfileFrame from "../../../../assets/img/profile_frame.svg";
import VectorRight from "../../../../assets/img/Vector_right.svg";
import { AppContext } from "../../../../AppContext";
import {getAvatarInitials} from "../../../services/common-service";

function Profile({ selectedTab, homeData, logoutHandler }) {
  const { deviceId } = useContext(AppContext);
  const navigate = useNavigate();
  const data = {
    deviceId: deviceId,
  };

  return (
    <div>
      {selectedTab === "profile" && (
        <div>
          <div className="profil_bg_div">
            <div className="profile_header">
              <Avatar className="ProfileAvtarStyle">
                {getAvatarInitials(homeData?.user?.name) || "U"}
              </Avatar>
              <div className="FooterTextOne">
                {homeData?.user?.name?.split(" ")[0]}
              </div>
              <div className="profileEmpId">{homeData?.user?.empCode}</div>
            </div>
          </div>
          <div
            className="proframe"
            type="button"
            onClick={() => navigate("/profile-detail")}
          >
            <div className="proframe_sub">
              <img src={ProfileFrame} />
              <span style={{ color: "black" }}>Profile Details</span>
            </div>
            <div>
              <Link to="/profile-details">
                <img src={VectorRight} />
              </Link>
            </div>
          </div>
          <div className="btn-div-logout">
            <button onClick={() => logoutHandler(data)} className="LogOutBtn">
              Logout
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default Profile;
