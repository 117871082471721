import React, { useContext, useEffect, useState } from "react";
import "./Home.css";
import { AppContext } from "../../../../AppContext";
import HomeMobile from "./Mobile/Mobile";
import HomeWeb from "./Web/Web";
import { getHomeData } from "./Home-service";

const Home = ({ logoutHandler, homeData, setSelectedTab, selectedTab }) => {
  const { value, isMobView  } = useContext(AppContext);

  return (
    <>
      {isMobView ? (
        <HomeMobile
          homeData={homeData}
          logoutHandler={logoutHandler}
          setSelectedTab={setSelectedTab}
          selectedTab={selectedTab}

        />
      ) : (
        <HomeWeb homeData={homeData} roleName={value?.role?.name} />
      )}
    </>
  );
};

export default Home;
