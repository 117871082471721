import React from "react";
import './ReporteeInfoRow.css';
import UserInfoRow from "../UserInfoRow/UserInfoRow";
import WebAttendanceRowItem from "../../pages/Home/Web/WebAttendanceRowItem/WebAttendanceRowItem";

const ReporteeInfoRow = ({ data }) => {
  return (
    <>
      <div className='row report-card-border' style={{ padding: "0px 17px" }} >
        <UserInfoRow userData={data} />
        <WebAttendanceRowItem attendance={data?.attendances?.[0]} />
      </div>
    </>
  );
}

export default ReporteeInfoRow;
