import React, { useEffect, useState } from "react";
import "./ChangeManagerWeb.css";
import UserDetailCardRow from "../../shared/UserDetailCardRow/UserDetailCardRow";
import {
  convertManagerDataToUserData,
  getAssignedManager,
} from "./ChangeManagerWeb-service";
import ChangeManagerModalWeb from "./ChangeManagerModalWeb/ChangeManagerModalWeb";

const ChangeManagerWeb = () => {
  const [managerList, setManagerList] = useState([]);
  const [modelOpen, setModalOpen] = useState(false);
  const [assignManager, setAssignManager] = useState(false);


  const getData = () => {
    getAssignedManager()
      .then((res) => {
        setManagerList(res?.data?.data?.managers);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  useEffect(() => {
    getData();
  }, []);

  const changeManagerTrigger = (editData) => {
    setAssignManager(editData);
    setModalOpen(true);
  }

  return (
    <>
      <ChangeManagerModalWeb assignManager={assignManager} getData={getData} modelOpen={modelOpen} setModalOpen={setModalOpen} />
      {managerList?.map((managerData) => {
        return (
          <>
            <UserDetailCardRow
              userData={convertManagerDataToUserData(managerData)}
            />
            <div
              className="emp-change-manager"
              onClick={() => changeManagerTrigger(managerData)}
            >
              Do you want to change manager?
            </div>
          </>
        )
      })}
      {(!managerList || managerList.length === 0) && <div className="emp-change-manager ml-1"
          onClick={() => changeManagerTrigger({})}>
        Do you want to assign manager?
      </div>}
    </>
  );
};

export default ChangeManagerWeb;
