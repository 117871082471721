import React, { useContext, useState } from "react";
import "./LoginForm.css";
import { Button, Form } from "react-bootstrap";
import { MuiOtpInput } from "mui-one-time-password-input";
import { AppContext } from "../../../../../AppContext";

const LoginForm = ({
  handleSubmit,
  handleInputChange,
  formData,
  handleChange,
  isOtpValue,
  isVerifiedOtp,
  isBtn,
  star,
  validationErrors,
  isOtp,
  disable,
  setDisable,
  setOtp,
  setVerifiedOtp,
  setBtn,
  changeNumber,
  handleKeyPress,
}) => {
  const { isMobView } = useContext(AppContext);
  const [consent, setConsent] = useState(true);

  const handleConsentChange = () => {
    setConsent(!consent);
  };
  const changeHandler = () => {
    setDisable(false);
    setOtp(false);
    setVerifiedOtp(false);
    setBtn(true);
    handleChange("");
  };
  return (
    <Form onSubmit={(e) => handleSubmit(e)} className="login-form flex-column">
      <div>
        <p className="login-text">
          Log In
          <span className="required">
            <img className="login_star" src={star} alt="logo" />
          </span>
        </p>
        {/* <p className="login-subtext">Get started building HRMS today.</p> */}
      </div>
      <Form.Group controlId="phoneNumber">
        <Form.Label className="input-field-style">
          Mobile Number
          <span className="required login_star">*</span>
        </Form.Label>
        <Form.Control
          type="tel"
          placeholder="Enter Number"
          name="phoneNumber"
          value={formData.phoneNumber}
          onChange={handleInputChange}
          pattern="[0-9]*"
          maxLength={10}
          minLength={10}
          disabled={disable}
          onKeyDown={(e) => handleKeyPress(e)}
          className={validationErrors.phoneNumber ? '' : ''}

        />
        <div className="text-danger">{validationErrors.phoneNumber}</div>
        {changeNumber && (
          <div className="d-flex justify-content-end">
            <span className="change-Number" onClick={changeHandler}>
              Change Number
            </span>
          </div>
        )}
      </Form.Group>

      {isOtp && (
        <div>
          <p className=" enter-otp">Enter OTP</p>
          <MuiOtpInput value={isOtpValue} length={4} onChange={handleChange} />
        </div>
      )}

      {!isVerifiedOtp && (
        <div className="FormConsent">
          <div>
            <input
              type="checkbox"
              id="consent"
              checked={consent}
              onChange={handleConsentChange}
            />
          </div>
          {<label htmlFor="consent" className="FormLoginlabel">
            I agree to receive emails about relevant content. <br/>Please refer
            to our <a target="_blank" href="https://dfccil.com/Home/DynemicPages?MenuId=154#:~:text=DFCCIL%20is%20providing%20these%20links,of%20the%20outside%20Portal%2Fwebsite.">Privacy policy</a>  for more details.
          </label>}
        </div>
      )}

      <Button
        variant="primary"
        disabled={!isBtn || !consent}
        type="submit"
        className="btn-login"
      >
        {!isVerifiedOtp  ? "Send OTP" : "Verify OTP"}
      </Button>
    </Form>
  );
};

export default LoginForm;
