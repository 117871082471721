import React from "react";
import "./BulkAction.css";


const ReporteeRegularizationBulkAction = ({
  checkedItems,
  bulkAction,
  userRegularizationPendingData,
  regularizationActionHandler,
}) => {
  return (
    <>
      <div className="d-flex mt-15">
        {checkedItems.length > 0 && (
          <div
            className="d-flex align-items-center action-btn-wrap">
            <span className="reg-selected pr-2 pl-3">
              {checkedItems.length} Selected
            </span>
            <button
              className=" m-0 reg-approve border-right-reg"
              onClick={() => regularizationActionHandler("Approve")}
            >
              Approve
            </button>
            <button
              className=" m-0 reg-reject"
              onClick={() => regularizationActionHandler("Reject")}
            >
              Reject
            </button>
          </div>
        )}
        {userRegularizationPendingData?.length > 0 && (
        <button onClick={() => bulkAction()}  className="approve-bulk mr-2">
          {checkedItems?.length &&
          checkedItems?.length === userRegularizationPendingData?.length
            ? "Clear All"
            : "Select All"}
        </button>
)}
      </div>
    </>
  );
};

export default ReporteeRegularizationBulkAction;
