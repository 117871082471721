import api from "../../../services/api";

const parseDate = (dateString) => {
    const [day, month, year] = dateString.split('/');
    return new Date(year, month - 1, day); // month is 0-indexed in JavaScript Date
};

//to set utc 000 hrs
const convertToMidnightUTCNextDayStartDate = (date) => {
    date.setUTCHours(0, 0, 0, 0);
    date.setUTCDate(date.getUTCDate() + 1);
    return date.toISOString();
};
const convertToMidnightUTCNextDayEndDate = (date) => {
    date.setUTCHours(23, 59, 59, 999);
    date.setUTCDate(date.getUTCDate() + 1);
    return date.toISOString();
};

export const getAttendanceSectionData = (data, reqSubUrl) => {
    let url = `/attendance/${reqSubUrl}`;
    var reqObj = { ...data };

    if (reqObj?.startdate) {
        const parsedStartDate = parseDate(reqObj["startdate"]);
        reqObj["startdate"] = convertToMidnightUTCNextDayStartDate(parsedStartDate);
    } else {
        delete reqObj["startdate"];
    }

    if (reqObj?.enddate) {
        const parsedEndDate = parseDate(reqObj["enddate"]);
        reqObj["enddate"] = convertToMidnightUTCNextDayEndDate(parsedEndDate);
    } else {
        delete reqObj["enddate"];
    }

    if (!reqObj?.userid) {
        delete reqObj["userid"];
    }

    return api.get(url, { params: reqObj });
};
