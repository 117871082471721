// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bread-crumbs-container {
  padding: 20px;
}

.link-dashboard {
  color: #5c5a5a;
  font-weight: 500;
  font-size: 16px;
  cursor: pointer;
}

.link-dashboardd {
  color: #5c5a5a;
  font-weight: 500;
  font-size: 16px;
}
`, "",{"version":3,"sources":["webpack://./src/v1/components/pages/Dashboard/shared/BreadCrumbs/BreadCrumbs.css"],"names":[],"mappings":"AAAA;EACE,aAAa;AACf;;AAEA;EACE,cAAc;EACd,gBAAgB;EAChB,eAAe;EACf,eAAe;AACjB;;AAEA;EACE,cAAc;EACd,gBAAgB;EAChB,eAAe;AACjB","sourcesContent":[".bread-crumbs-container {\n  padding: 20px;\n}\n\n.link-dashboard {\n  color: #5c5a5a;\n  font-weight: 500;\n  font-size: 16px;\n  cursor: pointer;\n}\n\n.link-dashboardd {\n  color: #5c5a5a;\n  font-weight: 500;\n  font-size: 16px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
