// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Web {
  /* Add your styles here */
}

.p-0-12 {
    padding: 0px 12px 0px 12px;
}

.rg-40 {
    row-gap: 40px;
}

.mb-2r {
    margin-bottom: 2rem;
}
`, "",{"version":3,"sources":["webpack://./src/v1/components/pages/Home/Web/Web.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;AAC3B;;AAEA;IACI,0BAA0B;AAC9B;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,mBAAmB;AACvB","sourcesContent":[".Web {\n  /* Add your styles here */\n}\n\n.p-0-12 {\n    padding: 0px 12px 0px 12px;\n}\n\n.rg-40 {\n    row-gap: 40px;\n}\n\n.mb-2r {\n    margin-bottom: 2rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
