import React, {useEffect, useState} from "react";
import "./Details.css";
import UserDetailCardRow from "../../../shared/UserDetailCardRow/UserDetailCardRow";
import Attendance from "../../Attendance/Attendance";

const ReporteeDetails = () => {
  const [userDetails, setUserDetails] = useState(null)

    const updateUserDetails = (data) => {
        const userData = {
            ...data,
            user: {
                name: data?.user_details?.profile?.name,
                contact: data?.user_details?.contact?.phoneNumber
            },
            contact: {
                reportees: data?.user_details?.contact?.reportees
            },
            managers: [
                {
                    manager: {
                        name: data?.user_details?.contact?.reporting_to
                    }
                }
            ]
        }
        setUserDetails(userData);
    }

  return (
    <>
      <UserDetailCardRow userData={userDetails} />
      <Attendance getUserDetails={(data) => updateUserDetails(data)} />
    </>
  );
};

export default ReporteeDetails;
