import React from "react";
import './HomeMobileRowCard.css';
import {useNavigate} from "react-router-dom";

const HomeMobileRowCard = ({data}) => {
    const { navigateTo, attText, atttextSub, icon, iconPerson } = data;
    const navigate = useNavigate();
  return (
    <>
        <div
            className="att_cont_card"
            type="button"
            onClick={() => navigate(`/${navigateTo}`)}
        >
            <div className="AttCardContainer">
                <div className="AttCardContainerSub">
                    <div className="AttLogText">{attText}</div>
                    <div className="AttLogTextSub">{atttextSub}</div>
                    <button
                        className="AttActionBtn"
                        onClick={() => navigate(`/${navigateTo}`)}
                    >
                        <img src={icon} />
                    </button>
                </div>
                <div className="IconImageContt">
                    <img src={iconPerson} className="AttPerson" />
                </div>
            </div>
        </div>
    </>
  );
}

export default HomeMobileRowCard;
