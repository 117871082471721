import React, { useEffect, useState } from "react";
import "./Shift.css";
import BreadCrumbs from "../shared/BreadCrumbs/BreadCrumbs";
import SharedInput from "../shared/Shared/Shared-input";
import TableComp from "../shared/Table/Table";
import { getShiftDetails } from "./Shift-services";

function Shift() {
  const [shiftData, setShiftData] = useState([]);
  useEffect(() => {
    const data = {
      offset: 0,
      limit: 10,
    };
    getShiftData(data);
  }, []);

  const getShiftData = (data) => {
    getShiftDetails(data)
      .then((res) => {
        console.log("res is:", res?.data);
        setShiftData(res?.data?.data);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  return (
    <div className="office-container">
      <div>
        <BreadCrumbs pathText="shift-count" />
      </div>
      <div>
        <SharedInput callFunction={getShiftData} />
      </div>
      <div>
        <TableComp
          headersData={shiftData?.headers}
          tableData={shiftData?.list}
          callFunction={getShiftData}
          count={shiftData?.total}
        />
      </div>
    </div>
  );
}

export default Shift;
