import React, { useEffect, useState } from "react";
import Avatar from "@mui/material/Avatar";
import "./ProfileDetails.css";
import LeftArrow from "../../../../../assets/img/arrow_left_icon .svg";
import Warn from "../../../../../assets/img/warning_icon.svg";
import ChangeManagerDrawer from "../ChnageManagerDrawer/ChangeManagerDrawer";
import { useNavigate } from "react-router-dom";
import { getAssignedManager } from "./ProfileDetails-services";
import {getAvatarInitials} from "../../../../services/common-service";

function ProfileDetails() {
  const {
    name,
    email,
    contact,
    department,
    designation,
    managers,
    empCode,
    office,
  } = JSON.parse(localStorage.getItem("user_data")) ?? {};

  const [oldManagerId, setOldManagerId] = useState(null);

  const [formState, setFormState] = useState({
    email,
    mobile: contact,
    department: department?.name,
    designation: designation?.name,
    reportingManager: "",
    office: office?.full_address,
  });

  const [drawerOpen, setDrawerOpen] = useState(false);
  const navigate = useNavigate();
  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormState({
      ...formState,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  const handleClear = () => {
    setFormState({
      email: "",
      mobile: "",
      department: "",
      designation: "",
      reportingManager: "",
      office: "",
    });
  };

  const handleChangeManager = () => {
    setDrawerOpen(true);
  };

  useEffect(() => {
    getAssignedManager()
      .then((res) => {
        setFormState({
          reportingManager: res?.data?.data?.managers,
        });
        setOldManagerId(res?.data?.data?.managers?.[0]?.profile?.manager_id);
      })
      .catch((err) => {
        console.log("err", err);
      });
  }, [drawerOpen]);

  const handleCloseDrawer = () => {
    const { email, contact, department, designation, managers } = JSON.parse(
      localStorage.getItem("user_data")
    );
    setFormState({
      email: email,
      mobile: contact,
      department: department?.name,
      designation: designation?.name,
      office: office?.full_address,
    });
    setDrawerOpen(false);
  };

  return (
    <div>
      <div className="ProfileDetailsContt">
        <div type="button" onClick={() => navigate("/profile")}>
          <img src={LeftArrow} />
        </div>
        <span className="PdText">Profile Details</span>
      </div>
      <div className="pro_sub_container">
        <Avatar className="PdAvtar">{getAvatarInitials(name) || "U"}</Avatar>

        <div>
          <span className="PdHeaderName">{name}</span>
          <div className="PdHeaderEmpId">{empCode}</div>
        </div>
      </div>

      <div>
        <form onSubmit={handleSubmit} className="PdForm">
          <div>
            <label className="profile-label">Email</label>
            <input
              type="text"
              name="email"
              value={formState.email}
              onChange={handleChange}
              className="profile-input"
              disabled
            />
          </div>

          <div>
            <label className="profile-label">Mobile no.</label>
            <input
              type="text"
              name="mobile"
              value={formState.mobile}
              onChange={handleChange}
              className="profile-input"
              disabled
            />
          </div>
          <div>
            <label className="profile-label">Department</label>
            <input
              type="text"
              name="department"
              value={formState.department}
              onChange={handleChange}
              className="profile-input"
              disabled
            />
          </div>
          <div>
            <label className="profile-label">Designation</label>
            <input
              type="text"
              name="designation"
              value={formState.designation}
              onChange={handleChange}
              className="profile-input"
              disabled
            />
          </div>
          <div>
            <label className="profile-label">Reporting Manager</label>
            <input
              type="text"
              name="reportingManager"
              value={formState?.reportingManager?.[0]?.profile?.name}
              onChange={handleChange}
              className="profile-input"
              disabled
            />

            <div onClick={handleChangeManager} className="PdSubmitBtn">
              Do you want to change Manager?
            </div>
          </div>
          <div>
            <label className="profile-label">Office</label>
            <input
              type="text"
              name="office"
              value={formState.office}
              onChange={handleChange}
              className="profile-input"
              disabled
            />
          </div>
        </form>
      </div>

      <ChangeManagerDrawer
        handleCloseDrawer={handleCloseDrawer}
        drawerOpen={drawerOpen}
        LeftArrow={LeftArrow}
        Warn={Warn}
        recentManagerId={oldManagerId}
        department={formState?.reportingManager?.[0]?.contact?.departmentId}
        managerId={formState?.reportingManager?.[0]?.profile?.manager_id}
      />
    </div>
  );
}

export default ProfileDetails;
