// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.depart-card {
  width: 350px;
  border-radius: 12px;
  background: #F2EEFD;
  object-fit: fill;
  position: relative;
  cursor: pointer;
}

.it-text {
  color: rgb(32, 31, 31);
  font-size: 25px !important;
  font-weight: 600;
}

.people-count {
  color: rgb(32, 31, 31);
  font-size: 20px;
  font-weight: 500;
}

.card-container-department {
  display: flex;
  margin: 20px;
  flex-wrap: wrap;
  gap: 25px;
}

.position-absolute-department{
  top: 10%;
  position: absolute;
  /* top: -6%; */
  left: 4%;
 }
`, "",{"version":3,"sources":["webpack://./src/v1/components/pages/Dashboard/Department/Department.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,mBAAmB;EACnB,mBAAmB;EACnB,gBAAgB;EAChB,kBAAkB;EAClB,eAAe;AACjB;;AAEA;EACE,sBAAsB;EACtB,0BAA0B;EAC1B,gBAAgB;AAClB;;AAEA;EACE,sBAAsB;EACtB,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,YAAY;EACZ,eAAe;EACf,SAAS;AACX;;AAEA;EACE,QAAQ;EACR,kBAAkB;EAClB,cAAc;EACd,QAAQ;CACT","sourcesContent":[".depart-card {\n  width: 350px;\n  border-radius: 12px;\n  background: #F2EEFD;\n  object-fit: fill;\n  position: relative;\n  cursor: pointer;\n}\n\n.it-text {\n  color: rgb(32, 31, 31);\n  font-size: 25px !important;\n  font-weight: 600;\n}\n\n.people-count {\n  color: rgb(32, 31, 31);\n  font-size: 20px;\n  font-weight: 500;\n}\n\n.card-container-department {\n  display: flex;\n  margin: 20px;\n  flex-wrap: wrap;\n  gap: 25px;\n}\n\n.position-absolute-department{\n  top: 10%;\n  position: absolute;\n  /* top: -6%; */\n  left: 4%;\n }\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
