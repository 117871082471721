// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.comming-soon-container-main {
  background: linear-gradient(-45deg, #fddbee, #fffbfe, #fee6d1);
}
.coming-soon-container {
  height: 93.5vh;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Arial, sans-serif;
}

.comming-soon-container-web {
  /* height: 73.5vh; */
  height: 90vh;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Arial, sans-serif;
}

.coming-soon-content {
  text-align: center;
}

.construction {
  font-size: 1.5rem;
  color: #555;
  margin-bottom: 1rem;
}

.coming-soon-text {
  font-size: 4rem;
  font-weight: bold;
  color: #333;
  margin-bottom: 1rem;
}

.stay-tuned {
  font-size: 1.5rem;
  color: #fff;
  background-color: #5c29d7;
  display: inline-block;
  padding: 0.5rem 1rem;
  border-radius: 25px;
}

@media (max-width: 600px) {
  .coming-soon-text {
    font-size: 2rem;
  }

  .stay-tuned {
    font-size: 1rem;
  }

  .construction {
    font-size: 1rem;
  }
}
`, "",{"version":3,"sources":["webpack://./src/v1/components/pages/ComingSoon/ComingSoon.css"],"names":[],"mappings":"AAAA;EACE,8DAA8D;AAChE;AACA;EACE,cAAc;EACd,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,8BAA8B;AAChC;;AAEA;EACE,oBAAoB;EACpB,YAAY;EACZ,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,8BAA8B;AAChC;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,iBAAiB;EACjB,WAAW;EACX,mBAAmB;AACrB;;AAEA;EACE,eAAe;EACf,iBAAiB;EACjB,WAAW;EACX,mBAAmB;AACrB;;AAEA;EACE,iBAAiB;EACjB,WAAW;EACX,yBAAyB;EACzB,qBAAqB;EACrB,oBAAoB;EACpB,mBAAmB;AACrB;;AAEA;EACE;IACE,eAAe;EACjB;;EAEA;IACE,eAAe;EACjB;;EAEA;IACE,eAAe;EACjB;AACF","sourcesContent":[".comming-soon-container-main {\n  background: linear-gradient(-45deg, #fddbee, #fffbfe, #fee6d1);\n}\n.coming-soon-container {\n  height: 93.5vh;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  font-family: Arial, sans-serif;\n}\n\n.comming-soon-container-web {\n  /* height: 73.5vh; */\n  height: 90vh;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  font-family: Arial, sans-serif;\n}\n\n.coming-soon-content {\n  text-align: center;\n}\n\n.construction {\n  font-size: 1.5rem;\n  color: #555;\n  margin-bottom: 1rem;\n}\n\n.coming-soon-text {\n  font-size: 4rem;\n  font-weight: bold;\n  color: #333;\n  margin-bottom: 1rem;\n}\n\n.stay-tuned {\n  font-size: 1.5rem;\n  color: #fff;\n  background-color: #5c29d7;\n  display: inline-block;\n  padding: 0.5rem 1rem;\n  border-radius: 25px;\n}\n\n@media (max-width: 600px) {\n  .coming-soon-text {\n    font-size: 2rem;\n  }\n\n  .stay-tuned {\n    font-size: 1rem;\n  }\n\n  .construction {\n    font-size: 1rem;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
