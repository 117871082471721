import React from "react";
import './Tab.css';
import {Button} from "react-bootstrap";

const ReporteeRegularizationTab = ({activeTab, setActiveTab}) => {
  return (
    <>
        <div className="d-flex pt-3" style={{ marginLeft: "10px" }}>
            <Button
                className={
                    "regularization-tab" +
                    ((activeTab && activeTab === 'pending') ? " active_regularisation-tab-btn" : "")
                }
                onClick={() => {
                    setActiveTab('pending');
                }}
            >
                Pending
            </Button>
            <Button
                className={
                    "regularization-tab" +
                    ((activeTab && activeTab === 'history') ? " active_regularisation-tab-btn" : "")
                }
                onClick={() => {
                    setActiveTab('history');
                }}
            >
                History
            </Button>
        </div>
    </>
  );
}

export default ReporteeRegularizationTab;
