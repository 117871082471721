import React, { useContext } from "react";
import "./ComingSoon.css";
import CommingSoonMobile from "../../../../assets/img/comming-soon-mob.svg";
import CommingSoonMobileWeb from "../../../../assets/img/comming-soon-web.svg";

import { Link, useLocation, useNavigate } from "react-router-dom";
import ArrowLeft from "../../../../assets/img/si_Arrow_left.svg";
import { AppContext } from "../../../../AppContext";

const ComingSoon = () => {
  const { pathname } = useLocation();
  const { isMobView } = useContext(AppContext);
  const navigate = useNavigate();
  return (
    <div className="comming-soon-container-main">
      {isMobView && (
        <div className="al-logleave">
          <div className="link-wrap" style={{ width: "35%" }}>
            <div onClick={() => navigate("/home")}>
              <img src={ArrowLeft} />
            </div>
            <span className="page-heading">Go Back</span>
          </div>
        </div>
      )}

      <div
        className={
          isMobView ? "coming-soon-container" : "comming-soon-container-web"
        }
      >
        <div className="coming-soon-content">
          <img src={isMobView ? CommingSoonMobile : CommingSoonMobileWeb} />
        </div>
      </div>
    </div>
  );
};

export default React.memo(ComingSoon);
