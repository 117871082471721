import React from "react";
import { DatePicker, Space } from "antd";
import moment from "moment";
import './Attendancerecordcalender.css';
import dayjs from "dayjs";

const Attendancerecordcalender = ({ attendanceDate, setAttendanceDate }) => {
  const { RangePicker } = DatePicker;
  const disabledDate = (current, { from }) => {
    // Disable dates that are after today
    if (current && current > moment().endOf('day')) {
      return true;
    }
    // Disable dates that are more than 31 days from the 'from' date
    if (from && current) {
      return Math.abs(current.diff(from, 'days')) >= 31;
    }
    return false;
  };
  const onDateChange = (dates, dateStrings) => {
    setAttendanceDate(dateStrings);
  };

  return (
  
      <Space direction="vertical">
    <RangePicker


      dropdownClassName="custom-calendar custom-date-picker" 
      disabledDate={disabledDate}
      value={[
        attendanceDate[0] ? dayjs(attendanceDate[0], "DD/MM/YYYY") : null,
        attendanceDate[1] ? dayjs(attendanceDate[1], "DD/MM/YYYY") : null
      ]}
      placeholder={["DD/MM/YY", "DD/MM/YY"]}
      onChange={onDateChange}
      format="DD/MM/YYYY"
      
    />
  </Space>
  );
};

export default React.memo(Attendancerecordcalender);
