import React, { useContext, useEffect } from "react";
import "./DonoughtChart.css";
import { AppContext } from "../../../../../AppContext";
import Chart from "react-apexcharts";

const AttendanceDonoughtChart = ({ attendanceSummary }) => {
  const { isMobView } = useContext(AppContext);

  useEffect(() => {
    window.dispatchEvent(new Event("resize"));
  }, []);

  const options = {
    chart: {
      type: "donut",
      height: "100%",
    },
    plotOptions: {
      pie: {
        donut: {
          size: "85%",
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
    colors: ["#a283f6", "#b0fdc4", "#fdbbbb"],
    labels: ["Unmarked", "Present", "Absent"],
    legend: {
      position: "bottom",
      formatter: function (seriesName, opts) {
        return `${seriesName}: ${opts.w.globals.series[opts.seriesIndex]}`;
      },
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: "100%",
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
  };

  const series = [
    (attendanceSummary?.total_working_days?.value - (attendanceSummary?.day_present?.value + attendanceSummary?.days_absent?.value)),
    attendanceSummary?.day_present?.value,
    attendanceSummary?.days_absent?.value,
  ];
  return (
    <>
      <div
        id="chart"
        className={`main-container ${isMobView ? "h-340" : "h-490"}`}
      >
        <span className="summary-heading">Attendance Summary</span>
        <div className={`legend-wrap ${isMobView ? "top-50" : "top-40"}`}>
          <div>Working Days</div>
          <div
            style={{
              fontSize: "30px",
              fontWeight: "700",
              color: "black",
            }}
          >
            {attendanceSummary?.total_working_days?.value}
          </div>
        </div>
        <Chart
          options={options}
          series={series}
          type="donut"
          height={isMobView ? "100%" : "80%"}
        />
      </div>
    </>
  );
};

export default AttendanceDonoughtChart;
