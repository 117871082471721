// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.InputWithIcon {
  /* Add your styles here */
}
.input-container-icon{
  padding: 4px 10px;
  border-radius: 12px;
  border: 1px solid #E7E7E7  ;
}
.label-style{
  font-family: 'Kumbh Sans';
font-size: 18px;
font-weight: 600;
line-height: 22.32px;
text-align: left;
color: #292929;
}`, "",{"version":3,"sources":["webpack://./src/v1/components/shared/InputWithIcon/InputWithIcon.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;AAC3B;AACA;EACE,iBAAiB;EACjB,mBAAmB;EACnB,2BAA2B;AAC7B;AACA;EACE,yBAAyB;AAC3B,eAAe;AACf,gBAAgB;AAChB,oBAAoB;AACpB,gBAAgB;AAChB,cAAc;AACd","sourcesContent":[".InputWithIcon {\n  /* Add your styles here */\n}\n.input-container-icon{\n  padding: 4px 10px;\n  border-radius: 12px;\n  border: 1px solid #E7E7E7  ;\n}\n.label-style{\n  font-family: 'Kumbh Sans';\nfont-size: 18px;\nfont-weight: 600;\nline-height: 22.32px;\ntext-align: left;\ncolor: #292929;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
