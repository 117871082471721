import React, { useState, useEffect, useContext } from "react";
import "./Shared-input.css";
import SearchIcon from "../../../../../../assets/img/button.svg";
import { useLocation, useParams } from "react-router-dom";
import { AppContext } from "../../../../../../AppContext";
import { getUserDetails } from "../../User/User-services";
import { HiArrowDownTray } from "react-icons/hi2";
import { FaTimes } from "react-icons/fa";
import * as XLSX from "xlsx";

function SharedInput({ callFunction }) {
 const{searchValue, setSearchValue ,debouncedValue, setDebouncedValue} = useContext(AppContext);
 const [userData, setUserData] = useState([]);
 const [designations, setDesignations] = useState([]);
 const [roles, setRoles] = useState([]);
 const [departments, setDepartments] = useState([]);

 const [selectedDesignation, setSelectedDesignation] = useState("");
 const [selectedRole, setSelectedRole] = useState("");
 const [selectedDepartment, setSelectedDepartment] = useState("");

 
 const { pathname } = useLocation();
 const {departmentName,departmentId}=useParams();
 const isUserCount = pathname.split("/")[2] === "user-count";
 const isDepartmentDetails = pathname.includes("department-details");
 const isOfficeCount = pathname.split("/")[2] === "office";
 const isShiftCount = pathname.split("/")[2] === "shift-count"

 const getUserData = (data) => {
   getUserDetails(data)
     .then((res) => {
       setRoles(res?.data?.data?.roles || []);
       setDepartments(res?.data?.data?.departments || []);
       setDesignations(res?.data?.data?.designations || []);
       setUserData(res?.data?.data?.list || []);
     })
     .catch((err) => {
       console.log("err", err);
     });
 };

 useEffect(() => {
   const data = {
     offset: 0,
     limit: 10,
   };
   if (isUserCount) {
     getUserData(data);
   } else {
     getUserData({ departmentId: departmentId });
   }
 }, [isUserCount, departmentId]);

 useEffect(() => {
   handleFilterChange();
 }, [selectedRole, selectedDepartment, selectedDesignation, debouncedValue]);

 const handleFilterChange = () => {
   const data = {
     offset: 0,
     limit: 10,
     roleId: selectedRole || undefined,
     departmentId: selectedDepartment || departmentId,
     designationId: selectedDesignation || undefined,
     name: debouncedValue || undefined,
   };
   callFunction(data);
 };

 const handleSearchChange = (e) => {
  setSearchValue(e.target.value);
  setSelectedRole("");         
  setSelectedDepartment("");    
  setSelectedDesignation("");   
};

useEffect(() => {
  const handler = setTimeout(() => {
    setDebouncedValue(searchValue);  
  }, 300);

  return () => {
    clearTimeout(handler);  
  };
}, [searchValue]);  

useEffect(() => {
  setSearchValue("");          
  setDebouncedValue("");        
  setSelectedRole("");          
  setSelectedDepartment("");    
  setSelectedDesignation("");   
}, [pathname.split("/")[1] === "dashboard"]);

 const handleClearFilter = (filterType) => {
   if (filterType === "role") setSelectedRole("");
   if (filterType === "department") setSelectedDepartment("");
   if (filterType === "designation") setSelectedDesignation("");
 };

 const handleDownload = async () => {
  let data = {
    roleId: selectedRole || undefined,
    designationId: selectedDesignation || undefined,
  };

  let fileName = "data";
  let columnsToHide = [];

  if (pathname.includes(departmentId)) {
    data.departmentId = departmentId;
    fileName = `${departmentName}-department_details`;
    columnsToHide = [10]; 
  } else if (isUserCount) {
    data.departmentId = selectedDepartment || undefined;
    fileName = `user-count`;
    columnsToHide = [9, 10]; 
  } else {
    fileName = `other-page-data`;
  }

  const fetchAllData = async () => {
    let allData = [];
    let offset = 0;
    const limit = 10;

    while (true) {
      const response = await getUserDetails({ ...data, offset, limit });
      const newData = response?.data?.data?.list || [];
      allData = [...allData, ...newData];

      if (newData.length < limit) break;
      offset += limit;
    }

    return allData;
  };

  try {
    const allData = await fetchAllData();
    const worksheet = XLSX.utils.json_to_sheet(allData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

    columnsToHide.forEach((index) => {
      worksheet["!cols"] = worksheet["!cols"] || [];
      worksheet["!cols"][index] = { hidden: true };
    });

    XLSX.writeFile(workbook, `${fileName}.xlsx`);
  } catch (err) {
    console.log("Error while downloading data", err);
  }
};

 return (
  <div id="filter-component">
    <div className="shared-container">
      <div
        className={`search-d-input-field ${
          isOfficeCount || isShiftCount
            ? "office-shift"
            : isUserCount
            ? "default-width"
            : "half-width"
        }`}
        
      >
        <input
          placeholder={`Search by ${
            departmentName ? "user" : pathname.split("/")[2].split("-")[0]
          } name`}
          className="input-search-d"
          onChange={handleSearchChange}
        />
        <img src={SearchIcon} className="search-input-icon" />
      </div>
    </div>
    
    {(!isOfficeCount && !isShiftCount) && (
      <>
        <div className="dropdownFilter">
          <div className="dropdownContainer">
            <select
              className={`dropdownFilter ${selectedRole ? "has-value" : ""}`}
              onChange={(e) => setSelectedRole(e.target.value)}
              value={selectedRole}
            >
              <option value="">Select Role</option>
              {roles.map((role) => (
                <option key={role.id} value={role.id}>
                  {role.name}
                </option>
              ))}
            </select>
            {selectedRole && (
              <FaTimes
                className="clear-icon"
                onClick={() => handleClearFilter("role")}
              />
            )}
          </div>
        </div>
    
        <div className="dropdownFilter">
          <div className="dropdownContainer">
            {isUserCount && !isDepartmentDetails && (
              <>
                <select
                  onChange={(e) => setSelectedDepartment(e.target.value)}
                  value={selectedDepartment}
                  className={`dropdownFilter ${
                    selectedDepartment ? "has-value" : ""
                  }`}
                >
                  <option value="">Select Department</option>
                  {departments.map((department) => (
                    <option key={department.id} value={department.id}>
                      {department.name}
                    </option>
                  ))}
                </select>
                {selectedDepartment && (
                  <FaTimes
                    className="clear-icon"
                    onClick={() => handleClearFilter("department")}
                  />
                )}
              </>
            )}
          </div>
        </div>
    
        <div className="dropdownFilter">
          <div className="dropdownContainer">
            {isUserCount && !isDepartmentDetails && (
              <>
                <select
                  className={`dropdownFilter ${
                    selectedDesignation ? "has-value" : ""
                  }`}
                  onChange={(e) => setSelectedDesignation(e.target.value)}
                  value={selectedDesignation}
                >
                  <option value="">Select Designation</option>
                  {designations.map((designation) => (
                    <option key={designation.id} value={designation.id}>
                      {designation.name}
                    </option>
                  ))}
                </select>
                {selectedDesignation && (
                  <FaTimes
                    className="clear-icon"
                    onClick={() => handleClearFilter("designation")}
                  />
                )}
              </>
            )}
            {!isUserCount && (
              <>
                <select
                  className={`dropdownFilter ${
                    selectedDesignation ? "has-value" : ""
                  }`}
                  onChange={(e) => setSelectedDesignation(e.target.value)}
                  value={selectedDesignation}
                >
                  <option value="">Select Designation</option>
                  {designations.map((designation) => (
                    <option key={designation.id} value={designation.id}>
                      {designation.name}
                    </option>
                  ))}
                </select>
                {selectedDesignation && (
                  <FaTimes
                    className="clear-icon"
                    onClick={() => handleClearFilter("designation")}
                  />
                )}
              </>
            )}
          </div>
        </div>
    
        <div className="tooltip-container">
          <button className="tooltip-button" onClick={handleDownload}>
            <HiArrowDownTray />
          </button>
          <span className="tooltip-text">Download CSV</span>
        </div>
      </>
    )}
  </div>
);

}

export default SharedInput;
