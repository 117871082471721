import React from "react";
import { DatePicker, Space } from "antd";
import moment from "moment";
import dayjs from "dayjs";

const DateRangePicker = ({ selectedDates, setSelectedDates }) => {
  const { RangePicker } = DatePicker;

  const disabledDate = (current) => {
    // Disable dates that are after today
    if (current && current > moment().endOf('day')) {
      return true;
    }
    return false;
  };

  const onDateChange = (dates, dateStrings) => {
    setSelectedDates(dateStrings);
  };

  return (
    <Space direction="vertical" size={12}>
      <RangePicker
        disabledDate={disabledDate}
        onChange={onDateChange}
        value={[
          selectedDates[0] ? dayjs(selectedDates[0], "DD/MM/YYYY") : null,
          selectedDates[1] ? dayjs(selectedDates[1], "DD/MM/YYYY") : null
        ]}
        placeholder={["DD/MM/YY", "DD/MM/YY"]}
        format="DD/MM/YYYY"
      />
    </Space>
  );
};

export default DateRangePicker;
