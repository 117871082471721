import React from "react";
import "./RegularizationHistoryRowItem.css";
import Typography from "@mui/material/Typography";
import { formatDate } from "../../../../../services/common-service";

const RegularizationHistoryRowItem = ({ historyData }) => {
  return (
    <>
      <Typography>
        <div className="history-container">
          <div className="heading-container">
            <div>
              <div className="regularized-by-label">Regularised By</div>
              <div className="manager-text">
                {historyData?.applyTo?.name} ({historyData?.applyTo?.empCode})
              </div>
            </div>
            <div>
              <span
                className={
                  historyData?.status === "Approved"
                    ? "history-status"
                    : "history-statuss"
                }
              >
                {historyData?.status}
              </span>
            </div>
          </div>
          <div className="dividerr"></div>

          <div className="mt-25">
            <div className="applied-date-label">Applied Date(s)</div>
            <div className="start-date-label">
              {formatDate(historyData?.created_at)}
            </div>
          </div>

          <div className="dividerr"></div>
          <div>
            <div className="regularized-on-wrap">
              <div>
                <div className="regularized-on-label">Regularised on</div>
                <div className="history-update-label">
                  {formatDate(historyData?.updated_at)}
                </div>
              </div>
              <div>
                <span className="day-count-label">01 Day</span>
              </div>
            </div>
          </div>
          <div style={{ padding: "10px 0px" }}>
            <p className="m-0 reason-heading">Reason</p>
            <p className="reason-border-style">
              {historyData?.leaveReason?.name}
            </p>
          </div>
          {historyData?.remarks && (
            <div>
              <p className="m-0 reason-heading">Remarks</p>
              <p className="reason-border-style">{historyData?.remarks}</p>
            </div>
          )}

          {historyData?.approve_reason && (
            <div>
              <p className="m-0 reason-heading">Approve Reason</p>
              <p className="reason-border-style">
                {historyData?.approve_reason}
              </p>
            </div>
          )}

          {historyData?.reject_reason && (
            <div>
              <p className="m-0 reason-heading">Reject Reason</p>
              <p className="reason-border-style">
                {historyData?.reject_reason}
              </p>
            </div>
          )}
        </div>
      </Typography>
    </>
  );
};

export default RegularizationHistoryRowItem;
