import React, { useContext, useEffect, useState } from "react";
import './History.css';
import { Table } from "react-bootstrap";
import {ReactComponent as ArrowImg} from "../../../../../../../assets/img/CardImg/Alt Arrow Down.svg"
import { toastError } from "../../../../../shared/ToastMsg/ToastMsgs";
// import PageComponent from "../../../../../../../components/Shared/RightDrawerComponent/RightDrawerComponent";
import { AppContext } from "../../../../../../../AppContext";
import { formatTime } from "../../../../../../services/common-service";
import {getPendingRegularizationFunction} from "./History-service";
import RightDrawerComponent from "../../../../../shared/RightDrawerComponent/RightDrawerComponent";

const ReporteeRegularizationHistory = () => {
    const { isDrawerOpen, setIsDrawerOpen } = useContext(AppContext);
    const [data, setData] = useState([]);
    const [selectedData ,setSelectedData]=useState([])

    useEffect(() => {
        const data = { status: 'History' };
        getPendingRegularizationFunction(data)
            .then((reply) => {
                setData(reply?.data?.data);
            })
            .catch((error) => {
                toastError(error?.response?.data?.message);
            });
    }, []);
    const openDrawerHandler=(row)=>{
        setSelectedData(row)
        setIsDrawerOpen(true)
    }

    return (

        <div className="m-4">
            <RightDrawerComponent selectedData={selectedData}/>
            <div className="table-component-div">
                <Table className="align-items-center border-radius m-0 no-border">
                    <thead className="thead-light border-bottom-row">
                        <tr className="no-border">
                            <th className="border-left no-border">S. no</th>
                            <th className="no-border">Applied by or date</th>
                            <th className="no-border">Shift Time</th>
                            <th className="no-border">Actual & Updated Time</th>
                            <th className="no-border">Reason</th>
                            <th className="no-border">Reject or Accept remarks</th>
                            <th className="no-border">  </th>
                        </tr>
                    </thead>
                    <tbody  className="no-border">
                        {data?.map((row, index) => (
                            
                            <tr key={row?.id} className="border-bottom-row hover-color cursor-pointer " onClick={()=>openDrawerHandler(row)}>
                                <td className="no-border">{index + 1}</td>
                                <td className="no-border row-name">< span className="row-time">{row?.user?.name} </span><br/> < span className="row-time-400">{new Date(row?.created_at).toLocaleDateString("en-US", {
                                        timeZone: "UTC",
                                    })}</span></td>
                                <td className="no-border row-time-400 color-400">
                                <span>
                                        {formatTime(row?.user?.office?.shifts?.[0]?.shift?.start_time)
                                           }
                                    </span>
                                    -
                                    <span>
                                        {formatTime(row?.user?.office?.shifts?.[0]?.shift?.end_time)}
                                    </span>
                                  
                                </td>
                                <td className="no-border">
                                    <span>
                                        {formatTime(  row?.attendance?.check_in_time)}
                                    </span>
                                    -
                                    <span>
                                    {formatTime(row?.attendance?.check_out_time)}
                                    </span><br/>   <span>
                                    {formatTime(row?.start_date)}
                                    </span>
                                    -
                                    <span>
                                    {formatTime(row?.end_date)}
                                    </span>
                                </td>
                                <td className="no-border">{row?.leaveReason?.name}</td>
                                <td className="no-border    ">
                                    <span className={`rejected-apporve border-rejected  ${row.status === "Approved" ? "approved-color" : "rejected-color"}`}>
                                        {row?.status}
                                    </span>
                                    <br/>
                                    <span className="row-name-400">
                                    {row?.approve_reason || row?.reject_reason || "--"} 
                                     </span>
                                    
                                </td>
                                <td>
                                    <ArrowImg  className="cursor-pointer" onClick={()=>openDrawerHandler(row)}/>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </div>
        </div>
    );
};

export default React.memo(ReporteeRegularizationHistory);
