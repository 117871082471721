import React, { useState, useEffect } from "react";
import BreadCrumbs from "../shared/BreadCrumbs/BreadCrumbs";
import SharedInput from "../shared/Shared/Shared-input";
import TableComp from "../shared/Table/Table";
import { getUserDetails } from "./User-services";

function User() {
  const [userData, setUserData] = useState([]);
  useEffect(() => {
    const data = {
      offset: 0,
      limit: 10,
    };
    getUserData(data);
  }, []);

  const getUserData = (data) => {
    getUserDetails(data)
      .then((res) => {
        setUserData(res?.data?.data);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  return (
    <div className="office-container">
      <div>
        <BreadCrumbs pathText="user-count" />
      </div>
      <div>
        <SharedInput callFunction={getUserData} />
      </div>
      <div>
        <TableComp
          headersData={userData?.headers  || []}
          tableData={userData?.list || []}
          callFunction={getUserData}
          count={userData?.total || 0}
        />
      </div>
    </div>
  );
}

export default User;
