import axios from "axios";

const baseUrl = "https://hrms-staging.ccdms.in";
// const baseUrl="http://localhost:4000"

const api = axios.create({
  baseURL: baseUrl,
});

const showLoader = () => {
  document.getElementById("loader").style.display = "block";
  document.getElementById("loader-background").style.display = "block";
};

const hideLoader = () => {
  document.getElementById("loader").style.display = "none";
  document.getElementById("loader-background").style.display = "none";
};

api.interceptors.request.use(
  (config) => {
    const userData = JSON.parse(localStorage.getItem("user_data"));
    if (userData && userData?.activeToken) {
      config.headers["Authorization"] = `${userData?.activeToken}`;
    }
    showLoader();
    return config;
  },
  (error) => {
    hideLoader();
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  (response) => {
    hideLoader();
    return response;
  },
  (error) => {
    hideLoader();
    if (error.response) {
      switch (error.response.status) {
        case 400:
          console.log("Bad Request");
          break;
        case 401:
          console.log("Unauthorized");
          localStorage.clear();
          window.location.href = "/";
          break;
        case 403:
          console.log("Forbidden");
          localStorage.clear();
          window.location.href = "/";
          break;
        case 404:
          console.log("Not Found");
          break;
        case 500:
          console.log("Internal Server Error");
          break;
        default:
          console.log("An error occurred:", error.response.statusText);
      }
    } else if (error.request) {
      console.error("No response received from the server");
    } else {
      console.error("Error", error.message);
    }
    return Promise.reject(error);
  }
);

export default api;
