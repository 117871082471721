import React from "react";
import './Web.css';
import {Container} from "reactstrap";
import UserProfileCard from "../../../shared/UserProfileCard/UserProfileCard";
import HomeWebBigCard from "./HomeWebBigCard/HomeWebBigCard";
import HomeWebSmallCard from "./HomeWebSmallCard/HomeWebSmallCard";
import HomeWebReviewCard from "./HomeWebReviewCard/HomeWebReviewCard";
import HomeWebHolidayCard from "../HomeHolidayCard/HomeHolidayCard";
import {SMALL_CARD_DATA} from "./Web-service";

const HomeWeb = ({homeData, roleName}) => {
  return (
    <>
        <Container fluid className="home-container">
            <div className="d-flex flex-column rg-40">
                <div className="row row-card-gap mb-2r">
                    <div className="col-md-3 card-radius h-100 p-0-12">
                        <UserProfileCard showSignInSignOut={true} userData={homeData} />
                    </div>
                    {roleName && roleName.toLowerCase() === 'manager' && <div className="col-md-6 " style={{padding:"0px 18px"}}>
                        <HomeWebBigCard />
                    </div>}
                <div className="col-md-3 card-radius p-0-12">
                        <HomeWebReviewCard reviewData={homeData?.reviewData} />
                    </div>
                    {SMALL_CARD_DATA.map((item,index) => {
                        if(roleName==="Manager" && index===1){
                            return null
                        }
                        return <div className="col-md-3 card-radius p-0-12">
                    
                            <HomeWebSmallCard item={item} userData={homeData} />
                        </div>
                    })}
                    <div className="col-md-3 p-0-12">
                        <HomeWebHolidayCard holidays={homeData?.holidays} />
                    </div>
                </div>
            </div>
        </Container>
    </>
  );
}

export default HomeWeb;
