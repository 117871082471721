import React from "react";
import ComingSoon from "../ComingSoon/ComingSoon";
import PageHeaderMobile from "../../shared/PageHeaderMobile/PageHeaderMobile";

function Leaves() {
  return (
    <div>
      <ComingSoon />
    </div>
  );
}

export default Leaves;
