import React, { useEffect, useState } from "react";
import "./Regularization.css";
import RegularizationMobile from "./Mobile/Mobile";
import {
  getUserAttendanceRegularize,
  getRegularizationReasons,
} from "./Regularization-service";

const Regularization = () => {
  const [attendanceList, setAttendanceList] = useState([]);
  const [reasons, setReason] = useState([]);

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    getUserAttendanceRegularize()
      .then((res) => {
        setAttendanceList(res.data.data);
      })
      .catch((err) => {
        console.log("error in fetching attendance", err);
      });
    getRegularizationReasons()
      .then((res) => {
        setReason(res.data);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };
  return (
    <>
      <RegularizationMobile
        attendanceList={attendanceList}
        reasons={reasons}
        getData={getData}
      />
    </>
  );
};

export default Regularization;
