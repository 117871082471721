import React, { useState } from "react";
import { Button } from "react-bootstrap";
import "./PageComponentHeader.css";

const PageComponentHeader = React.memo(({ headerTitle,buttonText,toggleDrawer, isOpen, setIsOpen }) => {
  return (
    <div className="d-flex justify-content-between p-3" style={{ flex: "1" }}>
      <h2>{headerTitle}</h2>
      {/* <Button
        variant="outline-primary"
        className="page-component-header-btn"
        onClick={toggleDrawer}
        disabled={isOpen===true}
      >
        {buttonText}
      </Button> */}
    </div>
  );
});

export default PageComponentHeader;
