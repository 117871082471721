import React, { useContext, useEffect, useState } from "react";
import './Departmentdetails.css';
import BreadCrumbs from "../../../shared/BreadCrumbs/BreadCrumbs";
import SharedInput from "../../../shared/Shared/Shared-input";
import TableComp from "../../../shared/Table/Table";
import { getDepartmentWiseData } from "./Departmentdetails-service";
import { useLocation, useParams } from "react-router-dom";
import { assignHead } from "../../Department-services";
import { toastError, toastSuccess } from "../../../../../shared/ToastMsg/ToastMsgs";
import HeadAssignModal from "../../../../../shared/Dashboard/HeadAssignModal";
import { AppContext } from "../../../../../../../AppContext";

const Departmentdetails = () => {
  const { departmentName, departmentId } = useParams()
  const [getDepartmentData, setDepartmentData] = useState([]);
  const [isToogle, setToogle] = useState(false)
  // const {isAssign, setAssign}=useState(false)

  // useEffect(() => {
  //   const data = {
  //     offset: 0,
  //     limit: 10,
  //     departmentId: departmentId
  //   };
  //   departmentWiseData(data);
  // }, [isToogle]);

  const departmentWiseData = (data) => {
    getDepartmentWiseData(data)
      .then((res) => {
        setDepartmentData(res?.data?.data);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const assignHeadHandler = (id) => {
    const data = {
      userId: id,
      departmentId: departmentId
    }
    assignHead(data).then((res) => {
      setToogle(prev => !prev)
      toastSuccess(res?.data?.message)
    }).catch(err => toastError(err?.res?.data?.message))

  }
  return (
    <>
      <div className="office-container">
        {/* <HeadAssignModal isAssign={isAssign} setAssign={setAssign}/> */}
        <div>
        <BreadCrumbs pathText={`Department / ${ departmentName }`} navigation="dashboard/department-count" />
        </div>
        <div>
          <SharedInput callFunction={departmentWiseData} />
        </div>
        <div>
          <TableComp
            headersData={getDepartmentData?.headers}
            tableData={getDepartmentData?.list}
            callFunction={departmentWiseData}
            count={getDepartmentData?.total}
            assignHeadHandler={assignHeadHandler}
          />
        </div>
      </div>    </>
  );
}

export default React.memo(Departmentdetails);
