// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ChangeManagerModalWeb {
  /* Add your styles here */
}

a:hover {
  text-decoration: none !important;
}

.ant-picker-outlined:focus-within {
  box-shadow: none !important
}

.assign-manager-border {
  border: 1px solid #e2e2e2 !important;
}`, "",{"version":3,"sources":["webpack://./src/v1/components/pages/ChangeManagerWeb/ChangeManagerModalWeb/ChangeManagerModalWeb.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;AAC3B;;AAEA;EACE,gCAAgC;AAClC;;AAEA;EACE;AACF;;AAEA;EACE,oCAAoC;AACtC","sourcesContent":[".ChangeManagerModalWeb {\n  /* Add your styles here */\n}\n\na:hover {\n  text-decoration: none !important;\n}\n\n.ant-picker-outlined:focus-within {\n  box-shadow: none !important\n}\n\n.assign-manager-border {\n  border: 1px solid #e2e2e2 !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
