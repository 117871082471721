import React, { useEffect, useState } from "react";
import "./ReporteeRegularization.css";
import { useSearchParams } from "react-router-dom";
import ReporteeRegularizationTab from "./Tab/Tab";
import ReporteeRegularizationBulkAction from "./BulkAction/BulkAction";
import PendingReporteeRegularization from "./Pending/Pending";
import ReporteeRegularizationHistory from "./History/History";
import BulkActionModal from "./BulkActionModal/BulkActionModal";

const ReporteeRegularization = () => {
  const [searchParams] = useSearchParams();
  const [pendingReportees, setPendingReportees] = useState([]);

  const [checkedItems, setCheckedItems] = useState([]);
  const [pendingRegularizations, setPendingRegularizations] = useState([]);
  const [bulkActionModalData, setBulkActionModalData] = useState({ bool: false, type: "" });
  const [activeTab, setActiveTab] = useState(
    searchParams.get("regularizationTab") || "pending"
  );

  const bulkAction = () => {
    if (checkedItems.length === pendingRegularizations.length) {
      setCheckedItems([]);
    } else {
      setCheckedItems(pendingRegularizations.map(item => item.id));
    }
  };

  const regularizationActionHandler = (type) => {
    setBulkActionModalData({ bool: true, type });
  };

  const checkActions = (id) => {
    if (checkedItems.includes(id)) {
      setCheckedItems(checkedItems.filter(item => item !== id));
    } else {
      setCheckedItems([...checkedItems, id]);
    }
  };

  useEffect(() => {
    setCheckedItems([]);
    if (activeTab === "history") {
      setPendingRegularizations([]);
    }
  }, [activeTab]);

  return (
    <>
      <div className="d-flex justify-content-between">
        <BulkActionModal
          isShowBulk={bulkActionModalData}
          setShowBulk={setBulkActionModalData}
          checkedItems={checkedItems}
          getPendingList={() => {}}
          getPendingUserList={() => {}}
          setCheckedItems={setCheckedItems}
        />
        <ReporteeRegularizationTab
          activeTab={activeTab}
          setActiveTab={setActiveTab}
        />
        {activeTab === "pending" && (
          <ReporteeRegularizationBulkAction
            bulkAction={bulkAction}
            checkedItems={checkedItems}
            regularizationActionHandler={regularizationActionHandler}
            userRegularizationPendingData={pendingRegularizations}
          />
        )}
      </div>
      {activeTab === "pending" && (
        <PendingReporteeRegularization
          checkedItems={checkedItems}
          pendingReportees={pendingReportees}
          setPendingReportees={setPendingReportees}
          checkActions={checkActions}
          setPendingRegularizationsParent={setPendingRegularizations}
        />
      )}
      {activeTab === "history" && <ReporteeRegularizationHistory />}
    </>
  );
};

export default ReporteeRegularization;
