import RightArrow from "../../../../../assets/img/CardImg/ArrowRight.svg";
import Hourglass from "../../../../../assets/img/hourglass.svg";
import Buildings from "../../../../../assets/img/buildings.svg";
import City from "../../../../../assets/img/city.svg";
import User from "../../../../../assets/img/user.svg";
import React from "react";

export const ADMIN_CARDS = [
  {
    img: (
      <img
        src={Buildings}
        style={{
          width: "70%",
          height: "70%",
          backgroundColor: "white",
          borderRadius: "8px",
          padding: "8px",
        }}
      />
    ),
    type: "Admin",
    heading: "Office Count",
    desc_key: "officeCount",
    navigate: "/attendance",
    navigateImg: <img src={RightArrow} />,
  },
  {
    img: (
      <img
        src={City}
        style={{
          width: "70%",
          height: "70%",
          backgroundColor: "white",
          borderRadius: "8px",
          padding: "8px",
        }}
      />
    ),
    type: "Admin",
    heading: "Department Count",
    desc_key: "departmentCount",
    navigate: "/attendance",
    navigateImg: <img src={RightArrow} />,
  },
  {
    img: (
      <img
        src={Hourglass}
        style={{
          width: "70%",
          height: "70%",
          backgroundColor: "white",
          borderRadius: "8px",
          padding: "8px",
        }}
      />
    ),
    type: "Admin",
    heading: "Shift Count",
    desc_key: "shiftCount",
    navigate: "/attendance",
    navigateImg: <img src={RightArrow} />,
  },
  {
    img: (
      <img
        src={User}
        style={{
          width: "70%",
          height: "60%",
          backgroundColor: "white",
          borderRadius: "8px",
          padding: "8px",
        }}
      />
    ),
    type: "Admin",
    heading: "User Count",
    desc_key: "userCount",
    navigate: "/attendance",
    navigateImg: <img src={RightArrow} />,
  },
];
