import React from "react";
import './WebAttendanceRowItem.css';
import {formatTime} from "../../../../../services/common-service";
import {checkInStatus, checkOutStatus} from "../../../../../services/util-services";

const WebAttendanceRowItem = ({attendance}) => {
  console.log(attendance,"attemdace")
  return (
    <>
        <div className='d-flex justify-content-center w-50' style={{columnGap:"40px"}}>
            <div className=''>
                <p className='reporte-container-time m-0'>{formatTime(attendance?.check_in_time)}</p>
                <p className='m-0 reporte-container-ontime'>{checkInStatus(attendance)}</p>
            </div>
            <div className=''>
                <p className='text-center m-0 reporte-container-time'>{formatTime(attendance?.check_out_time)}</p>
                <p className='m-0 reporte-container-ontime'>{checkOutStatus(attendance)}</p>
            </div>
        </div>
    </>
  );
}

export default WebAttendanceRowItem;
