import React, { useContext, useState } from "react";
import "./Web.css";
import AttendanceDonoughtChart from "../DonoughtChart/DonoughtChart";
import AttendanceProgressChart from "../ProgressChart/ProgressChart";
import AttendanceListingItemCard from "../ListingItemCard/ListingItemCard";
import AttendanceCalender from "../../../shared/Attendancecalender/Attendancecalender"
import Sortbydropdown from "../../../shared/Sortbydropdown/Sortbydropdown";
import { AppContext } from "../../../../../AppContext";
import Attendancerecordcalender from "./Attendancerecordcalender/Attendancerecordcalender";
import Calendertogglebutton from "../../../shared/Calendertogglebutton/Calendertogglebutton";

const AttendanceWeb = ({ data, attendanceDate, setAttendanceDate }) => {
  const { setCalender } = useContext(AppContext)
  const { attendanceSummary, attendanceList, attendancePerformance, calenderList ,checkedHandler,isChecked, setChecked  } = data;
 

  return (
    <div className="attendance-relative">
      <Sortbydropdown heading={`Record for ${attendanceList?.user?.logs_start_date
        } - ${attendanceList?.user?.logs_end_date}`} />
      <div className="attendance-absolute">
        {<Attendancerecordcalender attendanceDate={attendanceDate} setAttendanceDate={setAttendanceDate} />}
      </div>
      <div className="row summary-row">
        {attendanceSummary &&
          Object.keys(attendanceSummary).map((item, index) => {
            const { label, value, color } = attendanceSummary[item];
            return (
              <div
                style={{ '--custom-color': color }}
                key={index}
                className="bg-white col d-flex flex-column card-radius-attendance custom-background"
              >
                <p className="text-day">{label}</p>
                <p className="text-no m-0">{value}</p>
              </div>
            );
          })}
      </div>

      <div className="row row-margin">
      <div className="col-md-4" style={{ height: "490px" }}>
  <Calendertogglebutton isChecked={isChecked} checkedHandler={checkedHandler} />

  {isChecked ? (
    <div className="col-md-14 height-comp">
      <div className="d-flex flex-column .col-gap" style={{rowGap:"10px"}}>
        {attendanceList?.user?.attendance?.map((attendanceData) => (
          <AttendanceListingItemCard 
            attendanceDate={attendanceDate} 
            attendanceData={attendanceData} 
          />
        ))}
      </div>
    </div>
  ) : (
    <div className="col-md-14 height-comp">
      <AttendanceCalender attendanceData={attendanceList} calenderList={calenderList} />
    </div>
  )}
</div>

        <div className="col-md-4">
          {attendanceSummary && (
            <AttendanceDonoughtChart attendanceSummary={attendanceSummary} />
          )}
        </div>
        <div className="col-md-4">
          {attendancePerformance && (
            <AttendanceProgressChart
              attendancePerformance={attendancePerformance}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default AttendanceWeb;
