// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PendingRegularizationRowItem {
  /* Add your styles here */
}

.container-pending-div {
  margin-top: 10px;
}

.regular-shift-text {
  font-size: 10px;
  color: #7647eb;
  margin-top: 10px;
}
`, "",{"version":3,"sources":["webpack://./src/v1/components/pages/Regularization/Mobile/PendingRegularizationRowItem/PendingRegularizationRowItem.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;AAC3B;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,eAAe;EACf,cAAc;EACd,gBAAgB;AAClB","sourcesContent":[".PendingRegularizationRowItem {\n  /* Add your styles here */\n}\n\n.container-pending-div {\n  margin-top: 10px;\n}\n\n.regular-shift-text {\n  font-size: 10px;\n  color: #7647eb;\n  margin-top: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
