import React, { useContext, useEffect, useState } from "react";
import "./Table.css";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import Chip from "@mui/material/Chip";
import { ReactComponent as HeadImg } from "../../../../../../assets/img/CardImg/Component 144.svg"

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  listItemTextClasses,
} from "@mui/material";
import { useLocation, useParams } from "react-router-dom";
import { formatTime } from "../../../../../services/common-service";
import { AppContext } from "../../../../../../AppContext";

function TableComp({ headersData, tableData, callFunction, count, office, assignHeadHandler, officeHandler,isOfficeData }) {
  const { searchValue, setSearchValue, isDrawerOpen,debouncedValue,
    setIsDrawerOpen, } = useContext(AppContext);
  const { pathname } = useLocation();
  const { departmentName, departmentId } = useParams()
  const [currentPage, setCurrentPage] = useState(1);
  const rowsPerPage = 10;

  useEffect(() => {
    const data = {
      offset: rowsPerPage * (currentPage - 1),
      limit: rowsPerPage,
      departmentId: departmentId,
    };
  
    if (searchValue) {
      data.name = searchValue;
    }
  
    callFunction(data);
  }, [currentPage,isOfficeData,debouncedValue]);


  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  return (
    <div className="table-container">
      <TableContainer component={Paper}>
        <Table>
        <TableHead>
          <TableRow>
            {headersData?.map((header, index) => (
              pathname.split("/")[2] === "user-count" && index === 9 ? null : 
              (
                pathname.split("/")[2] !== "user-count" && index === 6 ? null : 
                (
                  <TableCell key={index}>{header?.value}</TableCell>
                )
              )
            ))}
          </TableRow>
        </TableHead>
          <TableBody>
            {tableData?.map((item, index) => (
              <TableRow  key={item.id}>
              {/* <TableRow  key={item.id}> */}
                {headersData?.map((header, index) => (
                  pathname.split("/")[2] !== "user-count" && index === 6 ? null:
                  <TableCell key={header.key} className="postion-relative">
                    {pathname.split("/")[2] === "shift-count" ? (
                      index === 2 || index === 3 ? (
                        formatTime(item[header.key])
                      ) : index === 6 ? (
                        item[header.key]
                          .split(",")
                          .map((day, idx) => (
                            <Chip
                              key={idx}
                              label={day.trim()}
                              className="table-chip"
                              style={{ margin: 2 }}
                            />
                          ))
                      ) : (
                        item[header.key]
                      )
                    ) : pathname.split("/")[2] === "user-count" ? (
                      index === 7 || index === 8 ? (
                        formatTime(item[header.key])
                      ) : pathname.split("/")[2] === "user-count" && index === 9 ? (
                        null

                      ) : (
                        item[header.key]
                      )
                    ) : index === 9 ? (
                      <Button variant="contained" disabled={item?.department_head} onClick={() => assignHeadHandler(item?.id)} className="assign-btn-dashboard" color="primary">
                        {item?.department_head ? "Assigned" : "Assign"}
                      </Button>
                    ) : pathname.split("/")[2] !== "user-count" && pathname.split("/")[2] !== "office"  && pathname.split("/")[2] !== "shift-count" ? (
                      index === 7 || index === 8 ? (
                        formatTime(item[header.key])
                      ) : (
                        item[header.key]
                      )
                    )
                     : (
                      item[header.key]
                    )  
                    } 
                    {
                      (index === 0 && item?.department_head &&  pathname.split("/")[2] !== "user-count") &&
                        <div className="postion-absolute-table">
                        <HeadImg />
                      </div>
                    }
                    {
                      (index === 7 &&  pathname.split("/")[2] === "office") &&
                       <button className="edit-btn-table" onClick={() => officeHandler(item)}>
                        Edit
                       </button>
                    }

                  </TableCell>

                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <div className="pagenation-container">
        <Stack spacing={5}>
          <Pagination
            count={Math.ceil(count / rowsPerPage)}
            page={currentPage}
            onChange={handlePageChange}
            variant="outlined"
            shape="rounded"
          />
        </Stack>
      </div>
    </div>
  );
}

export default TableComp;
