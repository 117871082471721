import React from "react";
import "./UserDetailCardRow.css";
import UserProfileCard from "../UserProfileCard/UserProfileCard";
import LabelValueItem from "../LabelValueItem/LabelValueItem";
import {useNavigate, useParams} from "react-router-dom";
import {formatTime} from "../../../services/common-service";

const UserDetailCardRow = ({ userData }) => {
  const navigate = useNavigate();
  const params = useParams();
  return (
    <>
      <div className="d-flex card-style-emp">
        <div className="w-25">
        <UserProfileCard userData={userData} />
        </div>
        <div className="bg-white w-75 d-flex flex-column card-radius p-18">
          <div className="d-flex">
            <p className="profile">Profiles</p>
          </div>
          <table className="w-100">
            <tbody>
              <tr>
                <td><LabelValueItem value={userData?.department?.name} label="Department" /></td>
                <td>
                  <p className="emp-subheading">Reportees</p>
                  <span className="emp-reporte-no">
                    {userData?.contact?.reportees?.length || 0}
                  </span>
                </td>
                {params?.id &&  <td><LabelValueItem value={userData?.managers?.[0]?.manager?.name} label="Reporting To" /></td>}
                <td><LabelValueItem value={userData?.user_details?.contact?.email} label="Email" /></td>
              </tr>
              <tr>
                <td><LabelValueItem value={userData?.user_details?.contact?.phoneNumber} label="Mobile Number" /></td>
                <td>
                  <p className="emp-subheading">Shift</p>
                  <div className="actionable-item cg-4">
                    <p className="emp-heading">
                      {userData?.user_details?.contact?.shift?.name}
                    </p>
                    <p className="fs-0-8">({formatTime(userData?.user_details?.contact?.shift?.startTime)} - {formatTime(userData?.user_details?.contact?.shift?.endTime)})</p>
                    {params?.id && <i
                      className="fa-regular fa-pen-to-square"
                      onClick={() =>
                        navigate("/reportees/shift/" + params?.id)
                      }
                    ></i>}{" "}
                  </div>
                </td>
                <td><LabelValueItem value={userData?.user_details?.contact?.office} label="Office" /></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default UserDetailCardRow;
