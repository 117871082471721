import React from "react";
import "./LabelValueItem.css";

const LabelValueItem = ({ label, value }) => {

    const triggerMail = () => {
        if (label === "Email") {
            window.location = "mailto:" + value;
        }
    }

  return (
    <>
      <p className="emp-subheading">{label}</p>
      <span className={"emp-reporte-no " + (label === "Email" ? "clickable" : "")} onClick={() => triggerMail()}>{value}</span>
    </>
  );
};

export default LabelValueItem;
