import axios from "axios";
import { v4 as uuidv4 } from "uuid";

export const convertIntoHoursMinute = (minutes) => {
  if (minutes && minutes.toString().split(" ")[0]) {
    const finalMinutes = parseInt(minutes.toString().split(" ")[0]);
    if (finalMinutes > 60) {
      return (
        parseInt(finalMinutes / 60) +
        " Hrs " +
        parseInt(finalMinutes % 60) +
        " min"
      );
    } else {
      return finalMinutes + " min";
    }
  }
};
export const getUserSubtitle = (userData) => {
  const departmentName = userData?.department?.name || '';
  const designationName = userData?.designation?.name || '';
  const grade = userData?.designation?.grade ? `-${userData.designation.grade}-` : ' ';
  const empCode = userData?.user?.empCode || userData?.empCode || '';
  return (
    <span>
      {departmentName} <b>*</b> {designationName} <b>*</b> {grade} {empCode}
    </span>
  );
};


export const checkInStatus = (attendanceData) => {
  if (!attendanceData || Object.keys(attendanceData).length === 0 || attendanceData.check_in_time===null) {
    return "";
  }
  return !attendanceData?.is_early_check_in && !attendanceData?.is_late_check_in
    ? "On Time"
    : attendanceData?.is_late_check_in
    ? "Late Check In"
    : "Early Check In";
};

export const checkOutStatus = (attendanceData) => {

  if (!attendanceData || Object.keys(attendanceData).length === 0 || attendanceData.check_out_time===null) {
    return "";
  }
  return !attendanceData?.is_early_check_out &&
    !attendanceData?.is_late_check_out
    ? "On Time"
    : attendanceData?.is_late_check_out
    ? "Late Check Out"
    : "Early Check Out";
};

export const getIpAddress = async () => {
  try {
    const response = await axios.get("https://ipapi.co/json/");
    return response.data.ip;
  } catch (error) {
    console.error("Error fetching IP address:", error);
    return null;
  }
};

export const getDeviceId = () => {
  let deviceId = localStorage.getItem("device_id");
  if (!deviceId) {
    deviceId = uuidv4();
    localStorage.setItem("device_id", deviceId);
  }
  return deviceId;
};

export const formatUtcToDate=(utcString) =>{
  // Convert the UTC string to a Date object
  const utcDate = new Date(utcString);

  // Extract the day, month, and year
  const day = String(utcDate.getUTCDate()).padStart(2, '0');
  const month = String(utcDate.getUTCMonth() + 1).padStart(2, '0'); // Months are zero-indexed
  const year = utcDate.getUTCFullYear();

  // Format as dd mm yyyy
  return `${day}/${month}/${year}`;}
