// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.review-Card-container{
    border-bottom: 1px solid rgba(172, 175, 181, 0.1);

}
.review-Card-container-p1{
    font-family: "Kumbh Sans";
    font-size: 20px;
    font-weight: 600;
    line-height: 19.84px;
    text-align: left;
    color: #212121;

}
.review-Card-container-p2{
    font-family: "Kumbh Sans";
    font-size: 14px;
    font-weight: 400;
    line-height: 17.4px;
    text-align: left;
    color: #2f2f2f;

}
.review-Card-container-p3{
    font-family:" Kumbh Sans";
    font-size: 12px;
    font-weight: 500;
    line-height: 14.91px;
    text-align: left;
    color: #7647EB;

}
.review-Card-container-p3:hover{
    text-decoration: underline;
}
.overflow-container{
    overflow: scroll;
    max-height: 272px;
    -ms-overflow-style: none;
    margin-bottom: 4px;
}

.overflow-container::-webkit-scrollbar {
    display: none;
}
`, "",{"version":3,"sources":["webpack://./src/v1/components/pages/Home/Web/ReviewCard/ReviewCard.css"],"names":[],"mappings":"AAAA;IACI,iDAAiD;;AAErD;AACA;IACI,yBAAyB;IACzB,eAAe;IACf,gBAAgB;IAChB,oBAAoB;IACpB,gBAAgB;IAChB,cAAc;;AAElB;AACA;IACI,yBAAyB;IACzB,eAAe;IACf,gBAAgB;IAChB,mBAAmB;IACnB,gBAAgB;IAChB,cAAc;;AAElB;AACA;IACI,yBAAyB;IACzB,eAAe;IACf,gBAAgB;IAChB,oBAAoB;IACpB,gBAAgB;IAChB,cAAc;;AAElB;AACA;IACI,0BAA0B;AAC9B;AACA;IACI,gBAAgB;IAChB,iBAAiB;IACjB,wBAAwB;IACxB,kBAAkB;AACtB;;AAEA;IACI,aAAa;AACjB","sourcesContent":[".review-Card-container{\n    border-bottom: 1px solid rgba(172, 175, 181, 0.1);\n\n}\n.review-Card-container-p1{\n    font-family: \"Kumbh Sans\";\n    font-size: 20px;\n    font-weight: 600;\n    line-height: 19.84px;\n    text-align: left;\n    color: #212121;\n\n}\n.review-Card-container-p2{\n    font-family: \"Kumbh Sans\";\n    font-size: 14px;\n    font-weight: 400;\n    line-height: 17.4px;\n    text-align: left;\n    color: #2f2f2f;\n\n}\n.review-Card-container-p3{\n    font-family:\" Kumbh Sans\";\n    font-size: 12px;\n    font-weight: 500;\n    line-height: 14.91px;\n    text-align: left;\n    color: #7647EB;\n\n}\n.review-Card-container-p3:hover{\n    text-decoration: underline;\n}\n.overflow-container{\n    overflow: scroll;\n    max-height: 272px;\n    -ms-overflow-style: none;\n    margin-bottom: 4px;\n}\n\n.overflow-container::-webkit-scrollbar {\n    display: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
