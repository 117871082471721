import React from "react";
import "./PageHeaderMobile.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import ArrowLeft from "../../../../assets/img/si_Arrow_left.svg";

const PageHeaderMobile = ({ title }) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const backClick = () => {
    if (pathname.split("/")[1] === "attendance") {
      navigate("/home");
      return;
    }
    if (pathname.split("/")[1] === "regularization") {
      navigate("/attendance");
      return;
    }

    if (pathname.split("/")[1] === "leave") {
      navigate("/home");
      return;
    }
  };

  return (
    <>
      <div
        className={
          pathname.split("/")[1] === "leave" ? "al-logleave" : "al-log"
        }
      >
        <div className="link-wrap">
          <div onClick={(e) => backClick()}>
            <img src={ArrowLeft} />
          </div>

          <span className="page-heading">{title}</span>
        </div>
      </div>
    </>
  );
};

export default PageHeaderMobile;
