import React from "react";
import "./UserProfileCard.css";
import SigninSignoutInfoWeb from "../SigninSignoutInfoWeb/SigninSignoutInfoWeb";
import Avatar from "@mui/material/Avatar";
import {getAvatarInitials} from "../../../services/common-service";
import {getUserSubtitle} from "../../../services/util-services";

const UserProfileCard = ({showSignInSignOut = false, userData}) => {
  return (
    <>
      <div
        className="bg-white card-radius h-100 padding-card-home" >
        <Avatar className="avtar-img">
          {getAvatarInitials(userData?.user?.name) || 'U'}
        </Avatar>
        <div className="d-flex flex-column justify-content-center">
          <p className="admin-text">{userData?.user?.name}</p>
          <p className=" m-0 admin-sub-text">{getUserSubtitle(userData)}</p>
          <p className="admin-sub-text"> Contact - {userData?.user?.contact}</p>
        </div>
        {showSignInSignOut && <SigninSignoutInfoWeb attendance={userData?.attendance} />}
      </div>
    </>
  );
};

export default UserProfileCard;
