import React from "react";
import { Navigate } from "react-router-dom";
import ROUTES_DATA from "./RoutesData.js";

export default function PrivateRoute({ children, redirectPath, name}) {
    const user = JSON.parse((localStorage.getItem("user_data") || "{}") === 'undefined' ? '{}' : (localStorage.getItem("user_data") || "{}"));
    const isAllowed = ROUTES_DATA.find(item => item?.name === name)?.allowedRoles?.includes(user?.role?.name);

    if (name==='Login'&& user.activeToken){
        return <Navigate to={"/home"} replace />;
    }
    if (!isAllowed &&  user.activeToken) {
        return <Navigate to={redirectPath} replace />;
    }

    return children;
}