// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.emp-subheading {
    font-family: "Kumbh Sans";
    font-size: 12px;
    font-weight: 400;
    line-height: 14.91px;
    text-align: left;
    color: #2f2f2f;
    margin: 0;
    margin-top: 10px;
    margin-bottom: 5px;
}

.emp-reporte-no {
    font-family: "Kumbh Sans";
    font-size: 16px;
    font-weight: 500;
    line-height: 19.84px;
    text-align: left;
    color: black;
}
`, "",{"version":3,"sources":["webpack://./src/v1/components/shared/LabelValueItem/LabelValueItem.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,eAAe;IACf,gBAAgB;IAChB,oBAAoB;IACpB,gBAAgB;IAChB,cAAc;IACd,SAAS;IACT,gBAAgB;IAChB,kBAAkB;AACtB;;AAEA;IACI,yBAAyB;IACzB,eAAe;IACf,gBAAgB;IAChB,oBAAoB;IACpB,gBAAgB;IAChB,YAAY;AAChB","sourcesContent":[".emp-subheading {\n    font-family: \"Kumbh Sans\";\n    font-size: 12px;\n    font-weight: 400;\n    line-height: 14.91px;\n    text-align: left;\n    color: #2f2f2f;\n    margin: 0;\n    margin-top: 10px;\n    margin-bottom: 5px;\n}\n\n.emp-reporte-no {\n    font-family: \"Kumbh Sans\";\n    font-size: 16px;\n    font-weight: 500;\n    line-height: 19.84px;\n    text-align: left;\n    color: black;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
