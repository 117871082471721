import React, { useContext } from "react";
import "./ProgressChart.css";
import { AppContext } from "../../../../../AppContext";
import ApexCharts from "react-apexcharts";

const AttendanceProgressChart = ({attendancePerformance}) => {
  const { isMobView } = useContext(AppContext);
  const options_1 = {
    series: [
      attendancePerformance?.regular_hours,
      attendancePerformance?.overtime_hours,
    ],
    chart: {
      height: 390,
      type: "radialBar",
    },
    plotOptions: {
      radialBar: {
        offsetY: 0,
        startAngle: 0,
        endAngle: 270,
        hollow: {
          margin: 5,
          size: "58%",
          background: "transparent",
          image: undefined,
        },
        dataLabels: {
          name: {
            show: false,
          },
          value: {
            show: false,
          },
        },
        barLabels: {
          enabled: true,
          useSeriesColors: false,
          margin: 8,
          fontSize: "12px",
          formatter: function (seriesName, opts) {
            return seriesName + ":  " + opts.w.globals.series[opts.seriesIndex];
          },
        },
      },
    },
    colors: ["#7647EB", "#38DD2A"],
    labels: ["Regular Hrs", "Overtime Hrs"],
    responsive: [
      {
        breakpoint: 480,
        options_1: {
          legend: {
            show: true,
          },
        },
      },
    ],
  };
  return (
    <div
      id="chart"
      style={{
        backgroundColor: "white",
        paddingTop: "10px",
        position: "relative",
        borderRadius: "12px",
        width: "100%",
        maxWidth: "600px",
        margin: "0 auto",
        marginTop: isMobView ? "20px" : 0,
        height: isMobView ? "388px" : "100%",
      }}
    >
      <span
        style={{
          fontWeight: "600",
          fontSize: "16px",
          color: "black",
          padding: "15px",
          paddingTop: "25px",
        }}
      >
        Performance Summary
      </span>
      <h1 className="cs-label">Coming Soon..!</h1>
    </div>
  );
};

export default AttendanceProgressChart;
