import React, { useContext, useEffect } from "react";
import Avatar from "@mui/material/Avatar";
import { Link, useNavigate } from "react-router-dom";
import ProfileFrame from "../../../../../../assets/img/profile_frame.svg";
import VectorRight from "../../../../../../assets/img/Vector_right.svg";
import { AppContext } from "../../../../../../AppContext";
import HomeBlack from "../../../../../../assets/img/home_black.svg";
import HomeWhite from "../../../../../../assets/img/home_white.svg";
import ProfileBlack from "../../../../../../assets/img/profile_black.svg";
import ProfileWhite from "../../../../../../assets/img/profile_white.svg";

function HomeFooter({
  selectedTab,
  setSelectedTab,
  homeData,
  logoutHandler,
  location,
}) {
  const navigate = useNavigate();
  const { deviceId, deviceIp } = useContext(AppContext);

  const data = {
    lat: location?.lat,
    long: location?.lng,
    deviceId: deviceId,
    ipAddress: deviceIp,
  };

  const handleHomeTab = () => {
    setSelectedTab("home");
    navigate("/home");
  };

  const handleProfileTab = () => {
    setSelectedTab("profile");
    navigate("/profile");
  };

  return (
    <div>
      <div className="mob-footer">
        <div className="tabs">
          <div className="MobTabsContt">
            <div
              className={`tab ${selectedTab === "home" ? "selected" : ""}`}
              onClick={handleHomeTab}
            >
              <img src={selectedTab === "home" ? HomeWhite : HomeBlack} />
            </div>
            <div>Home</div>
          </div>
          <div className="TabWrapper">
            <div
              className={`tab ${selectedTab === "profile" ? "selected" : ""}`}
              onClick={handleProfileTab}
            >
              <img
                src={selectedTab === "profile" ? ProfileWhite : ProfileBlack}
              />
            </div>
            <div>Profile</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HomeFooter;
