import React from "react";
import './UserInfoRow.css';
import Avatar from "@mui/material/Avatar";
import {getAvatarInitials} from "../../../services/common-service";
import {getUserSubtitle} from "../../../services/util-services";

const UserInfoRow = ({userData}) => {
    
  return (
    <>
        <div className='d-flex reporte-container-info2 cursor-pointer'>
            <Avatar>
                {getAvatarInitials(userData?.name) || 'U'}
            </Avatar>
            <div>
                <p className='m-0 reporte-container-heading'>{userData?.name || 'User'}</p>
                <p className=' reporte-container-subheading'>{getUserSubtitle(userData)}</p>
            </div>
        </div>
    </>
  );
}

export default UserInfoRow;
