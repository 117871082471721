import React from "react";
import './InputWithIcon.css';

const InputWithIcon = ({label, value,name ,className, type, icon, placeholder, onChange, validations = null}) => {
  return (
    <>
      <div className="InputWithIcon">
      <label className="location-form-label label-style">{label}</label>
     <div className="d-flex bg-white input-container-icon">
     <img src={icon} />
        <input className={className} value={value} name={name} type={type} placeholder={placeholder} onChange={(e) => onChange(e)} />
     </div>
      </div>
    </>
  );  
}

export default React.memo(InputWithIcon);
