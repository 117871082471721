import React, { useContext } from "react";
import "./NavHeader.css";
import NavLogo from "../../../../assets/img/Logo/Dedicated_Freight_Corridor_Corporation_of_India_Logo.svg 1.svg";
import Navbar from "react-bootstrap/Navbar";
import { AppContext } from "../../../../AppContext";
import { Dropdown, Space } from "antd";
import { getAvatarInitials } from "../../../services/common-service";
import { Avatar } from "@mui/material";

const NavHeader = ({logoutHandler,userData}) => {
    
  const items = [
    {
      label: <a className="logout-text">Settings</a>,
      key: "0",

    },
    {
      label: <a className="logout-text" onClick={()=>logoutHandler()} >LogOut</a>,
      key: "1",
    },
  ];
  
  const { value, isMobView } = useContext(AppContext);
  const UserAuth = value?.activeToken;
  return (
    <>
      <Navbar expand="lg bg-white" className="login-nav">
        <div className="d-flex logo-text">
          <img src={NavLogo} width={100} alt="logo" />
          <p className="attendEase">उपस्थिति</p>
        </div>
        {UserAuth && (
          <Dropdown
            menu={{
              items,
            }}
            trigger={["click"]}
          >
            <a onClick={(e) => e.preventDefault()}>
              <Space className="cursor-pointer">
                {/* <img
                  src={logo}
                  className="cursor-pointer"
                  style={{ height: "52px" }}
                /> */}
                <Avatar  sx={{width:50, height:50}}>
          {getAvatarInitials(userData?.name ||  'U')}
        </Avatar>
              </Space>
            </a>
          </Dropdown>
        )}
      </Navbar>
    </>
  );
};

export default NavHeader;
