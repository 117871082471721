// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal-text-mob {
  font-family: "Quicksand";
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-align: center;
  color: #000000;
}

.blinking-icon {
  animation: blink-animation 1.2s infinite;
}

@keyframes blink-animation {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.successScreen-container {
  display: flex;
  flex-direction: column;
  row-gap: 24px;
}

.successScreen-btn {
  font-family: "Roboto";
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.10000000149011612px;
  text-align: center;
  background-color: #7647eb;
  color: white;
  padding: 7px 15px;
  cursor: pointer;
  border-radius: 42px;
}
`, "",{"version":3,"sources":["webpack://./src/v1/components/shared/MobSuccessScreen/MobSuccessScreen.css"],"names":[],"mappings":"AAAA;EACE,wBAAwB;EACxB,eAAe;EACf,gBAAgB;EAChB,iBAAiB;EACjB,kBAAkB;EAClB,cAAc;AAChB;;AAEA;EACE,wCAAwC;AAC1C;;AAEA;EACE;IACE,UAAU;EACZ;EACA;IACE,UAAU;EACZ;EACA;IACE,UAAU;EACZ;AACF;AACA;EACE,aAAa;EACb,sBAAsB;EACtB,aAAa;AACf;;AAEA;EACE,qBAAqB;EACrB,eAAe;EACf,gBAAgB;EAChB,iBAAiB;EACjB,qCAAqC;EACrC,kBAAkB;EAClB,yBAAyB;EACzB,YAAY;EACZ,iBAAiB;EACjB,eAAe;EACf,mBAAmB;AACrB","sourcesContent":[".modal-text-mob {\n  font-family: \"Quicksand\";\n  font-size: 16px;\n  font-weight: 600;\n  line-height: 24px;\n  text-align: center;\n  color: #000000;\n}\n\n.blinking-icon {\n  animation: blink-animation 1.2s infinite;\n}\n\n@keyframes blink-animation {\n  0% {\n    opacity: 1;\n  }\n  50% {\n    opacity: 0;\n  }\n  100% {\n    opacity: 1;\n  }\n}\n.successScreen-container {\n  display: flex;\n  flex-direction: column;\n  row-gap: 24px;\n}\n\n.successScreen-btn {\n  font-family: \"Roboto\";\n  font-size: 14px;\n  font-weight: 500;\n  line-height: 20px;\n  letter-spacing: 0.10000000149011612px;\n  text-align: center;\n  background-color: #7647eb;\n  color: white;\n  padding: 7px 15px;\n  cursor: pointer;\n  border-radius: 42px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
