import React from "react";
import { TimePicker } from 'antd';
import dayjs from 'dayjs';
import './Minimumdropdown.css';



const Minimumdropdown = ({minimunHours ,onMinimumHoursChange}) => {
  const format = 'HH:mm';

  return (
  <div className="d-flex justify-content-around width-full">
 <TimePicker 
     onChange={(e)=>onMinimumHoursChange(e)}
 dropdownClassName="time-picker-dropdown "
 format={format} />
  </div>
  );
}

export default React.memo(Minimumdropdown);
