// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.table-container {
  margin: 20px;
  border-radius: 12px !important;
}

.pagenation-container {
  height: 100px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.table-chip {
  color: #61be7a !important;
  background-color: #d4f7dd !important;
  border: 0.5px solid #61be7a !important;
}

.assign-btn-dashboard{
  background-color: #EEE8FF !important;
  font-family: 'Plus Jakarta Sans'!important;
font-size: 14px !important;
font-weight: 600 !important;
line-height: 20px !important;
text-align: left !important;
color: #7647eb !important;

}
.postion-absolute-table{
  position: absolute;
  top: 2px;
  left: 0px;
}
.postion-relative{
position: relative;
}

.edit-btn-table{
  border: none !important;
  color: #7647eb;
  font-family: 'Kumbh Sans';
font-size: 14px;
font-weight: 600;
line-height: 20px;
text-align: left;
background-color: white;
}`, "",{"version":3,"sources":["webpack://./src/v1/components/pages/Dashboard/shared/Table/Table.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,8BAA8B;AAChC;;AAEA;EACE,aAAa;EACb,aAAa;EACb,yBAAyB;EACzB,mBAAmB;AACrB;;AAEA;EACE,yBAAyB;EACzB,oCAAoC;EACpC,sCAAsC;AACxC;;AAEA;EACE,oCAAoC;EACpC,0CAA0C;AAC5C,0BAA0B;AAC1B,2BAA2B;AAC3B,4BAA4B;AAC5B,2BAA2B;AAC3B,yBAAyB;;AAEzB;AACA;EACE,kBAAkB;EAClB,QAAQ;EACR,SAAS;AACX;AACA;AACA,kBAAkB;AAClB;;AAEA;EACE,uBAAuB;EACvB,cAAc;EACd,yBAAyB;AAC3B,eAAe;AACf,gBAAgB;AAChB,iBAAiB;AACjB,gBAAgB;AAChB,uBAAuB;AACvB","sourcesContent":[".table-container {\n  margin: 20px;\n  border-radius: 12px !important;\n}\n\n.pagenation-container {\n  height: 100px;\n  display: flex;\n  justify-content: flex-end;\n  align-items: center;\n}\n\n.table-chip {\n  color: #61be7a !important;\n  background-color: #d4f7dd !important;\n  border: 0.5px solid #61be7a !important;\n}\n\n.assign-btn-dashboard{\n  background-color: #EEE8FF !important;\n  font-family: 'Plus Jakarta Sans'!important;\nfont-size: 14px !important;\nfont-weight: 600 !important;\nline-height: 20px !important;\ntext-align: left !important;\ncolor: #7647eb !important;\n\n}\n.postion-absolute-table{\n  position: absolute;\n  top: 2px;\n  left: 0px;\n}\n.postion-relative{\nposition: relative;\n}\n\n.edit-btn-table{\n  border: none !important;\n  color: #7647eb;\n  font-family: 'Kumbh Sans';\nfont-size: 14px;\nfont-weight: 600;\nline-height: 20px;\ntext-align: left;\nbackground-color: white;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
