import React, { useContext, useState } from 'react';
import './Sortbydropdown.css';
import {ReactComponent as CalenderImg} from "../../../../assets/img/CardImg/icon.svg"
import { AppContext } from '../../../../AppContext';
import Attendancerecordcalender from '../../pages/Attendance/Web/Attendancerecordcalender/Attendancerecordcalender';

const Sortbydropdown = ({ heading }) => {
  const { isCalender, setCalender } = useContext(AppContext)
  return (
    <>
      <div  className='d-flex justify-content-between align-items-center' style={{ padding: "20px 15px" }}>
        <p className='m-0 log-text'>{heading}</p>
        <div className='d-flex align-items-center'>

        </div>
      </div> 
      
       </>
  );
}

export default React.memo(Sortbydropdown);
