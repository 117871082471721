import React, { useContext, useEffect, useState } from "react";
import './Attendance.css';
import { useParams } from "react-router-dom";
import { getAttendanceSectionData } from "./Attendance-service";
import { AppContext } from "../../../../AppContext";
import AttendanceMobile from "./Mobile/Mobile";
import AttendanceWeb from "./Web/Web";

const Attendance = ({ getUserDetails }) => {
    const [attendanceList, setAttendanceList] = useState([]);
    const [attendanceSummary, setAttendanceSummary] = useState(null);
    const [attendancePerformance, setAttendancePerformance] = useState(null);
    const [calenderList, setCalenderList] = useState(null);
    const [attendanceDate, setAttendanceDate] = useState([])
    const [isChecked, setChecked] = useState(false)



    const checkedHandler = (e) => {
      setChecked((prev)=>!prev)
    }
  
  
    const params = useParams();
    const { isMobView } = useContext(AppContext);
    useEffect(() => {
        if (params?.id) {
            // clearData();
            getData(params.id);
        } else {
            getData();
        }
    }, [params, attendanceDate]);

    useEffect(() => {
        if (attendanceList?.user && getUserDetails) {
            getUserDetails(attendanceList?.user);
        }
    }, [attendanceList?.user])


    const clearData = () => {
        setAttendanceList([]);
        setAttendanceSummary(null);
        setAttendancePerformance(null);
    }

    const getData = (userId = null) => {
        const data = {
            userid: userId,
            startdate: attendanceDate?.[0],
            enddate: attendanceDate?.[1]
        }

        getAttendanceSectionData(data, "calender_data").then(res => {
            setCalenderList(res.data);
        }).catch(err => {
            console.log("error in fetching attendance", err);
        });

        getAttendanceSectionData(data, "get_user_attendance").then(res => {
            setAttendanceList(res.data.data);
        }).catch(err => {
            console.log("error in fetching attendance", err);
        });

        getAttendanceSectionData(data, "get_attendance_summary").then(res => {
            setAttendanceSummary(res?.data?.data);
        }).catch(err => {
            console.log("error in fetching attendance summary", err);
        });

        getAttendanceSectionData(data, "get_performance_summary").then(res => {
            setAttendancePerformance(res.data.data);
        }).catch(err => {
            console.log("error in fetching attendance summary", err);
        });
    }
    return (
        <>
            {isMobView ? (
                <AttendanceMobile  attendanceDate={attendanceDate} setAttendanceDate={setAttendanceDate}
                    data={{ attendanceList, attendanceSummary, attendancePerformance ,isChecked , checkedHandler,calenderList }}
                />
            ) : (
                <>
                    <AttendanceWeb
                        attendanceDate={attendanceDate} setAttendanceDate={setAttendanceDate}
                        data={{ attendanceList, attendanceSummary, attendancePerformance, calenderList ,isChecked , checkedHandler }}
                    />
                    <br />
                </>
            )}
        </>
    );
}

export default Attendance;
