// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.WebDashboardCard {
  /* Add your styles here */
}
.admin-count {
  font-family: "Kumbh Sans";
  font-weight: 600;
  text-align: left;
  color: #000000;
  font-size: 8rem;
  line-height: 0.8;
  font-size: 55px;
}

.admin-dash-card-padding {
  padding: 6px 17px;
  cursor: pointer;
  background-image: linear-gradient(#aae5fd66, #d5cefc66);
}

.navigate-btn-dashboard {
  background-color: white;
  width: 50px;
  height: 50px;
  padding: 12px;
  border-radius: 100%;
}

.cursor-pointer {
  margin-bottom: 6px;
}

.dashboard-icon-container {
  height: 100px;
  width: 100px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
`, "",{"version":3,"sources":["webpack://./src/v1/components/pages/Dashboard/WebDashboardCard/WebDashboardCard.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;AAC3B;AACA;EACE,yBAAyB;EACzB,gBAAgB;EAChB,gBAAgB;EAChB,cAAc;EACd,eAAe;EACf,gBAAgB;EAChB,eAAe;AACjB;;AAEA;EACE,iBAAiB;EACjB,eAAe;EACf,uDAAuD;AACzD;;AAEA;EACE,uBAAuB;EACvB,WAAW;EACX,YAAY;EACZ,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,YAAY;EACZ,aAAa;EACb,2BAA2B;EAC3B,mBAAmB;AACrB","sourcesContent":[".WebDashboardCard {\n  /* Add your styles here */\n}\n.admin-count {\n  font-family: \"Kumbh Sans\";\n  font-weight: 600;\n  text-align: left;\n  color: #000000;\n  font-size: 8rem;\n  line-height: 0.8;\n  font-size: 55px;\n}\n\n.admin-dash-card-padding {\n  padding: 6px 17px;\n  cursor: pointer;\n  background-image: linear-gradient(#aae5fd66, #d5cefc66);\n}\n\n.navigate-btn-dashboard {\n  background-color: white;\n  width: 50px;\n  height: 50px;\n  padding: 12px;\n  border-radius: 100%;\n}\n\n.cursor-pointer {\n  margin-bottom: 6px;\n}\n\n.dashboard-icon-container {\n  height: 100px;\n  width: 100px;\n  display: flex;\n  justify-content: flex-start;\n  align-items: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
