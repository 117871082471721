import React, { useEffect, useState } from "react";
import "./ChangeManagerModalWeb.css";
import {
  Alert,
  Box,
  FormControl,
  MenuItem,
  Modal,
  Select,
  TextField,
} from "@mui/material";
import {
  getDepartments,
  getManagers,
  postManagers,
} from "./ChangeManagerModalWeb-service";
import { toastError } from "../../../shared/ToastMsg/ToastMsgs";
import { toastSuccess } from "../../../shared/ToastMsg/ToastMsgs";

const ChangeManagerModalWeb = ({
  modelOpen,
  setModalOpen,
  getData,
  assignManager,
}) => {
  const [departments, setDepartments] = useState([]);
  const [departmentManagers, setDepartmentManagers] = useState([]);
  const [departmentId, setDepartmentId] = useState(null);
  const [managerId, setManagerId] = useState(null);
  const [reason, setReason] = useState(null);

  useEffect(() => {
    getDepartments()
      .then((response) => {
        setDepartments(response?.data?.data);
      })
      .catch((err) => console.log(err.message, "err"));
  }, []);

  useEffect(() => {
    const params = { departmentId };

    if (params) {
      getManagers(params).then((response) => {
        setDepartmentManagers(response?.data?.data);
        if (assignManager?.profile?.manager_id) {
          setManagerId(assignManager?.profile?.manager_id);
        }
      });
    }
  }, [departmentId]);

  useEffect(() => {
    if (assignManager) {
      if (assignManager?.contact?.departmentName) {
        setDepartmentId(
          departments?.find(
            (item) => item.name == assignManager?.contact?.departmentName,
          )?.id,
        );
      }
    }
  }, [assignManager, departments]);

  const changeDepartment = (event) => {
    setDepartmentId(event.target.value);
  };

  const changeManager = (event) => {
    setManagerId(event.target.value);
  };

  const submitHandler = (id, reasons) => {
    const data = {
      managerId: id,
      oldManagerId: assignManager?.profile?.manager_id ?? "",
      reason: reasons,
    };
    postManagers(data)
      .then((response) => {
        if (response) {
          updateManagerLocally(response?.data?.data);
          toastSuccess(response?.data?.message)
          setModalOpen(false);
          getData();
        }
      })
      .catch((err) => toastError(err?.message));
  };

  function updateManagerLocally(managerData) {
    const localUserData = JSON.parse(localStorage.getItem("user_data"));
    localUserData.managers = [managerData];
    localStorage.setItem("user_data", JSON.stringify(localUserData));
  }

  return (
    <>
      <Modal open={modelOpen} onClose={() => setModalOpen(false)}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 449,
            bgcolor: "background.paper",
            border: "none",
            boxShadow: 24,
            borderRadius: "12px",
            padding: "11px 32px",
          }}
        >
          <p className="assign-manager-text"> Assign New Manager </p>
          <div
            className="d-flex flex-column modal-container"
            style={{ rowGap: "27px" }}
          >
            <div>
              <p className="m-0 label-modal-text">
                Department
              </p>
              <Box className="modal-dropdown" sx={{ minWidth: 120 }}>
                <FormControl fullWidth>
                  <Select
                    className="modal-dropdown"
                    id="demo-simple-select"
                    value={departmentId}
                    onChange={changeDepartment}
                  >
                    <MenuItem disabled={true} id="demo-simple-select" value="">
                      <em className="disable-text">Select</em>
                    </MenuItem>
                    {departments?.map((item) => {
                      return <MenuItem value={item.id}>{item?.name}</MenuItem>;
                    })}
                  </Select>
                </FormControl>
              </Box>
            </div>

            <div>
              <p className="m-0 label-modal-text">
                Manager Name <span>*</span>
              </p>
              <Box sx={{ minWidth: 120 }}>
                <FormControl fullWidth>
                  <Select
                    className="modal-dropdown"
                    id="demo-simple-select-manager"
                    value={managerId}
                    onChange={changeManager}

                  >
                    <MenuItem disabled={true} id="demo-simple-select" value="">
                      <em className="disable-text">Select</em>
                    </MenuItem>
                    {departmentManagers?.map((item) => {
                      return <MenuItem value={item?.id}>{item?.name}  ({item.empId})</MenuItem>;
                    })}
                  </Select>
                </FormControl>
              </Box>
            </div>
            <div>
            <p className="m-0 label-modal-text">
              Reason </p>
            <TextField
              className="assign-manager-border"
              multiline
              rows={4}
              variant="outlined"
              fullWidth
              // value={}
              onChange={(e) => setReason(e.target.value)}
              sx={{ mb: 2, borderRadius: "11px" }}
            />
            </div>
          </div>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              columnGap: "18px",
            }}
          >
            <button
              className="btn-padding done-bg text-white "
              onClick={() => submitHandler(managerId, reason)}
            >
              Done
            </button>
            <button
              className="btn-padding cancel-bg"
              onClick={() => setModalOpen(false)}
            >
              Cancel
            </button>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default ChangeManagerModalWeb;
