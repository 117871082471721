// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Sortbydropdown {
  /* Add your styles here */
}
.log-text{
  font-family: "Kumbh Sans";
font-size: 20px;
font-weight: 600;
line-height: 24.8px;
text-align: left;
color: #202020;

}`, "",{"version":3,"sources":["webpack://./src/v1/components/shared/Sortbydropdown/Sortbydropdown.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;AAC3B;AACA;EACE,yBAAyB;AAC3B,eAAe;AACf,gBAAgB;AAChB,mBAAmB;AACnB,gBAAgB;AAChB,cAAc;;AAEd","sourcesContent":[".Sortbydropdown {\n  /* Add your styles here */\n}\n.log-text{\n  font-family: \"Kumbh Sans\";\nfont-size: 20px;\nfont-weight: 600;\nline-height: 24.8px;\ntext-align: left;\ncolor: #202020;\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
