import api from "../../../../../services/api";

export const getWeekDays = () => {
    return [
        'Monday',
        'Tuesday',
        'Wednesday',
        'Thursday',
        'Friday',
        'Saturday',
        'Sunday'
    ]
};

export const getShiftList = (userId) => {
    return api.get(`/shift/list?userId=${userId}`);
};

export const createUpdateUserShift = (shiftData) => {
    return api.post(`/shift/create_or_update`, shiftData);
};
