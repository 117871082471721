// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.main-container {
  background-color: white;
  padding-top: 10px;
  position: relative;
  margin-top: 20px;
  border-radius: 12px;
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
}

.h-340 {
  height: 340px;
}

.h-490 {
  height: 100%;
}

.summary-heading {
  font-weight: 600;
  font-size: 16px;
  color: black;
  padding: 15px;
  padding-top: 25px;
}

.legend-wrap {
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.top-50 {
  top: 50%;
}

.top-40 {
  top: 50%;
}


@media(min-width:780px){
  .apexcharts-canvas{
    position: relative;
    -webkit-user-select: none;
            user-select: none;
    margin-top: 57px;
    
  }
}
`, "",{"version":3,"sources":["webpack://./src/v1/components/pages/Attendance/DonoughtChart/DonoughtChart.css"],"names":[],"mappings":"AAAA;EACE,uBAAuB;EACvB,iBAAiB;EACjB,kBAAkB;EAClB,gBAAgB;EAChB,mBAAmB;EACnB,WAAW;EACX,gBAAgB;EAChB,cAAc;AAChB;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,gBAAgB;EAChB,eAAe;EACf,YAAY;EACZ,aAAa;EACb,iBAAiB;AACnB;;AAEA;EACE,kBAAkB;EAClB,SAAS;EACT,gCAAgC;EAChC,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;EACE,QAAQ;AACV;;AAEA;EACE,QAAQ;AACV;;;AAGA;EACE;IACE,kBAAkB;IAClB,yBAAiB;YAAjB,iBAAiB;IACjB,gBAAgB;;EAElB;AACF","sourcesContent":[".main-container {\n  background-color: white;\n  padding-top: 10px;\n  position: relative;\n  margin-top: 20px;\n  border-radius: 12px;\n  width: 100%;\n  max-width: 600px;\n  margin: 0 auto;\n}\n\n.h-340 {\n  height: 340px;\n}\n\n.h-490 {\n  height: 100%;\n}\n\n.summary-heading {\n  font-weight: 600;\n  font-size: 16px;\n  color: black;\n  padding: 15px;\n  padding-top: 25px;\n}\n\n.legend-wrap {\n  position: absolute;\n  left: 50%;\n  transform: translate(-50%, -50%);\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n}\n\n.top-50 {\n  top: 50%;\n}\n\n.top-40 {\n  top: 50%;\n}\n\n\n@media(min-width:780px){\n  .apexcharts-canvas{\n    position: relative;\n    user-select: none;\n    margin-top: 57px;\n    \n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
