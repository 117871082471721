export const ROUTES_DATA = [
  {
    name: "Login",
    allowedRoles: ["employee", "Manager", "manager","admin", "super admin"],
  },
  {
    name: "Home",
    allowedRoles: ["employee", "Manager","manager", "admin", "super admin"],
  },
  {
    path: "/attendance",
    name: "Attendance",
    allowedRoles: ["employee", "Manager","manager", "admin"],
  },
  {
    path: "/leave",
    name: "Leave",
    allowedRoles: ["employee", "Manager","manager", "admin", "super admin"],
  },
  {
    path: "/regularization",
    name: "Regularization",
    allowedRoles: ["employee", "admin", "Manager","manager"],
  },
  {
    path: "/manger",
    name: "ChangeManagerWeb",
    allowedRoles: ["employee", "Manager","manager", "admin"],
  },
  {
    path: "/reportees",
    name: "Reportee",
    allowedRoles: ["Manager","manager",],
  },
  {
    path: "/reportee/:id",
    name: "ReporteeDetails",
    allowedRoles: ["Manager","manager",],
  },
  {
    path: "/reportee/shift/:id",
    name: "ShiftChange",
    allowedRoles: ["Manager","manager",],
  },
  {
    path: "/report",
    name: "Report",
    allowedRoles: ["admin"],
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    allowedRoles: ["admin"],
  },
  {
    path: "/profile-detail",
    name: "ProfileDetails",
    allowedRoles: ["employee", "Manager","manager", "admin"],
  },
  {
    path: "/attendance-action",
    name: "AttendanceAction",
    allowedRoles: ["employee", "Manager","manager", "admin"],
  },
  {
    path: "/profile",
    name: "Profile",
    allowedRoles: ["employee", "Manager","manager", "admin"],
  },
];



export default ROUTES_DATA;