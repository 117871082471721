import React, { useEffect, useState } from "react";
import "./HomeWebBigCard.css";
import { ReactComponent as RightArrow } from "../../../../../../assets/img/CardImg/ArrowRight.svg";
import { useNavigate } from "react-router-dom";
import ReporteeInfoRow from "../../../../shared/ReporteeInfoRow/ReporteeInfoRow";
import {getReportees} from "../../../Reportee/Web/Web-service";

const HomeWebBigCard = ({isAdmin = false}) => {
  const [reportees, setReportees] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    if (!isAdmin) {
      getReportees()
        .then((res) => {
          setReportees(res?.data?.data);
        })
        .catch((err) => {
          console.log("error while getting reportees");
        });
    }
  }, [isAdmin]);

  const handleNavigate = () => {
    if (!isAdmin) {
      navigate("/reportees?tab=current");
    }
  };

  return (
    <>
      <div className="h-100 bg-white card-radius ">
        <div
          className="d-flex justify-content-between align-items-center "
          style={{ paddingRight: "26px" }}
        >
          <p className="review card-padding">
            {isAdmin ? "Things To Do" : "Reportees"}
          </p>
          <RightArrow
            onClick={() => handleNavigate()}
            style={{ cursor: "pointer" }}
          />
        </div>
        {(!reportees || reportees.length <= 0) && <h2 className="ml-4">Sadly! Nobody is under your guidance as of now.</h2>}
        <div className="card-padding overflow-container">
          {reportees?.map((item) => {
            return <ReporteeInfoRow data={item} />;
          })}
        </div>
      </div>
    </>
  );
};

export default HomeWebBigCard;
