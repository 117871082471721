import React from "react";
import "./ListingItemCard.css";
import LoginIcon from "../../../../../assets/img/login_in.svg";
import LogoutIcon from "../../../../../assets/img/login_out.svg";
import Warning from "../../../../../assets/img/warning.svg";
import { formatDate, formatTime } from "../../../../services/common-service";
import {formatUtcToDate} from "../../../../services/util-services";

const AttendanceListingItemCard = ({ attendanceDate, attendanceData }) => {

  return (
    <>
      <div className="page-container ">
        <div className="top-header">
          <div className="created-at">
            {formatUtcToDate(attendanceData?.created_at)}
          </div>
          <div className={`total-time ${attendanceData?.status === "not-marked"
  ? "absent-color"
  : attendanceData?.status === "Off Day"
  ? "offday-color"
  : attendanceData?.status === "Holiday"
  ? "holiday-color"
  : "present-color"}`}>

 {attendanceData?.status === "not-marked"
  ? "Absent"
  : attendanceData?.status === "Off Day"
  ? "Off Day"
  : attendanceData?.status === "Holiday"
  ? "Holiday"
  : `Total Time ${attendanceData?.totalTime}`}

          </div>
        </div>
        <div className="dividerr"></div>
        <div className="div-wrap mb-20">
          <div className="div-wrapp">
            <div>
              <img src={LoginIcon} />
            </div>
            <div>
              <div className="check-in-time">
                {formatTime(attendanceData?.check_in_time)}
              </div>
              {attendanceData?.check_in_time &&
                !attendanceData?.is_regularized &&
                attendanceData?.is_late_check_in && (
                  <div className="checkin-checkout-label error-img">
                    {attendanceData.late_check_in_time} late
                  </div>
                )}

              {attendanceData?.check_in_time &&
                !attendanceData?.is_regularized &&
                attendanceData?.is_early_check_in && (
                  <div className="checkin-checkout-label success-img">
                    {attendanceData.early_check_in_time} extra
                  </div>
                )}

              {attendanceData?.check_in_time &&
                !attendanceData?.is_early_check_in &&
                !attendanceData?.is_late_check_in &&
                attendanceData?.status !== "Not marked" && (
                  <div className="fs-12">On Time</div>
                )}
            </div>
          </div>
          <div className="logout-wrap">
            <div>
              <img src={LogoutIcon} />
            </div>
            <div>
              <div className="check-out">
                {!attendanceData?.check_out_time
                  ? "--:--"
                  : formatTime(attendanceData?.check_out_time)}
              </div>{" "}
              {attendanceData?.check_out_time &&
                !attendanceData?.is_regularized &&
                attendanceData?.is_late_check_out && (
                  <div className="checkin-checkout-label success-img">
                    {attendanceData.late_check_out_time} extra
                  </div>
                )}
              {attendanceData?.check_out_time &&
                !attendanceData?.is_regularized &&
                attendanceData?.is_early_check_out && (
                  <div className="checkin-checkout-label error-img">
                    {attendanceData.early_check_out_time} early
                  </div>
                )}
              {attendanceData?.check_out_time &&
                !attendanceData?.is_regularized &&
                !attendanceData?.is_early_check_out &&
                !attendanceData?.is_late_check_out &&
                attendanceData?.status !== "Not marked" && (
                  <div className="fs-12">On Time</div>
                )}
            </div>
          </div>
        </div>
        {(attendanceData?.is_oor_check_in ||
          attendanceData?.is_oor_check_out) &&
        !attendanceData?.is_regularized ? (
          <div className="LabelOor">
            <img src={Warning} />
            Attendance marked out of office radius.
          </div>
        ) : attendanceData?.is_regularized ? (
          <div className="LabelOorr">Regularized</div>
        ) : null}
      </div>
    </>
  );
};

export default AttendanceListingItemCard;
