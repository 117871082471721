// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.UserProfileCard {
  /* Add your styles here */
}
.avtar-img {
    height: 100px !important;
    width: 100px !important;
    margin: auto;
    margin-bottom: 1rem;
    font-size: 3rem !important;
}

.padding-card-home{padding:  44px 35px ;

}
@media (max-width: 1550px) {
  .padding-card-home{padding:  44px 19px }
}`, "",{"version":3,"sources":["webpack://./src/v1/components/shared/UserProfileCard/UserProfileCard.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;AAC3B;AACA;IACI,wBAAwB;IACxB,uBAAuB;IACvB,YAAY;IACZ,mBAAmB;IACnB,0BAA0B;AAC9B;;AAEA,mBAAmB,oBAAoB;;AAEvC;AACA;EACE,mBAAmB,oBAAoB;AACzC","sourcesContent":[".UserProfileCard {\n  /* Add your styles here */\n}\n.avtar-img {\n    height: 100px !important;\n    width: 100px !important;\n    margin: auto;\n    margin-bottom: 1rem;\n    font-size: 3rem !important;\n}\n\n.padding-card-home{padding:  44px 35px ;\n\n}\n@media (max-width: 1550px) {\n  .padding-card-home{padding:  44px 19px }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
