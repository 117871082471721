import React, { lazy, useContext, useEffect, useState } from "react";
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import "./App.css";
import ToastMsgs, {
  toastSuccess,
  toastError,
} from "./v1/components/shared/ToastMsg/ToastMsgs";
import Login from "./v1/components/pages/Login/Login";
import Sidebar from "./v1/components/shared/Sidebar/Sidebar";
import NavHeader from "./v1/components/shared/NavHeader/NavHeader";
import { AppContext } from "./AppContext";
import Home from "./v1/components/pages/Home/Home";
import PageInfoHeader from "./v1/components/shared/PageInfoHeader/PageInfoHeader";
import Attendance from "./v1/components/pages/Attendance/Attendance";
import Regularization from "./v1/components/pages/Regularization/Regularization";
import ChangeManagerWeb from "./v1/components/pages/ChangeManagerWeb/ChangeManagerWeb";
import Reportee from "./v1/components/pages/Reportee/Reportee";
import ReporteeDetails from "./v1/components/pages/Reportee/Details/Details";
import Profile from "./v1/components/pages/profile/Profile";
import HomeFooter from "./v1/components/pages/Home/Mobile/HomeFooter/HomeFooter";
import { getHomeData, logOut } from "./v1/components/pages/Home/Home-service";
import ProfileDetails from "./v1/components/pages/profile/ProfileDetails/ProfileDetails";
import MobSuccessScreen from "./v1/components/shared/MobSuccessScreen/MobSuccessScreen";
import AttendanceAction from "./v1/components/pages/AttendanceAction/AttendanceAction";
import Dashboard from "./v1/components/pages/Dashboard/Dashboard";
import { getDeviceId } from "./v1/services/common-service";

import ShiftChange from "./v1/components/pages/Reportee/Web/ShiftChange/ShiftChange";
import Report from "./v1/components/pages/Report/Report";
import ComingSoon from "./v1/components/pages/ComingSoon/ComingSoon";
import PrivateRoute from "./PrivateRoutes";
import Leaves from "./v1/components/pages/Leaves/Leaves";
import Office from "./v1/components/pages/Dashboard/Office/Office";
import Department from "./v1/components/pages/Dashboard/Department/Department";
import Shift from "./v1/components/pages/Dashboard/Shift/Shift";
import User from "./v1/components/pages/Dashboard/User/User";
import DepartmentDetails from "./v1/components/pages/Dashboard/Department/DepartmentDetails/Departmentdetails/Departmentdetails";
export default function App() {
  const { isMobView, setDeviceId, setValue, successText, openModal, value } =
    useContext(AppContext);
  const userAuth = value?.activeToken;
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const userData = JSON.parse(localStorage.getItem("user_data") || "{}");
  const [selectedTab, setSelectedTab] = useState("home");
  const [homeData, setHomeData] = useState([]);
  const footerTabArr = ["/home", "/profile"];
  const isHeaderShow = !(
    // pathname.split("/")[2] === "office" ||
    // pathname.split("/")[2] === "department-count" ||
    // pathname.split("/")[2] === "shift-count" ||
    // pathname.split("/")[2] === "user-count" ||
    pathname.split("/")[1] === "dashboard"
  );

  useEffect(() => {
    setDeviceId(getDeviceId());
  }, [pathname]);

  useEffect(() => {
    if (pathname.split("/")[1] === "home") {
      getHomeData()
        .then((response) => {
          setHomeData(response?.data);
        })
        .catch((err) => console.log(err, "err"));
    }
  }, [pathname]);

  useEffect(() => {
    if (pathname === "/home") {
      setSelectedTab("home");
    }
    if (pathname === "/profile") {
      setSelectedTab("profile");
    }
  }, [pathname]);

  const logoutHandler = () => {
    logOut()
      .then((response) => {
        toastSuccess("Logout successful");
        localStorage.clear("user_data");
        setValue("");
        navigate("/");
      })
      .catch((error) => toastError(error.message));
  };

  return (
    <>
      {!isMobView && (
        <NavHeader logoutHandler={() => logoutHandler()} userData={userData} />
      )}
      <MobSuccessScreen openModal={openModal} successText={successText} />

      {Object.keys(userData).length > 0 && !isMobView && <Sidebar />}
      <div className={`main-content h-100 ${!isMobView ? "pt-80" : "mb-40"}`}>
        {!isMobView && userAuth && isHeaderShow && <PageInfoHeader />}
        <Routes>
          <Route
            path="/"
            element={
              <PrivateRoute name="Login">
                <Login />
              </PrivateRoute>
            }
          />
          <Route
            path="/home"
            element={
              <PrivateRoute redirectPath="/" name="Home">
                <Home
                  logoutHandler={logoutHandler}
                  homeData={homeData}
                  selectedTab={selectedTab}
                  setSelectedTab={setSelectedTab}
                />
              </PrivateRoute>
            }
          />
          <Route
            path="/attendance"
            element={
              <PrivateRoute redirectPath="/home" name="Attendance">
                <Attendance />
              </PrivateRoute>
            }
          />
          <Route
            path="/leave"
            element={
              <PrivateRoute redirectPath="/" name="Leave">
                <Leaves />
              </PrivateRoute>
            }
          />
          <Route
            path="/regularization"
            element={
              <PrivateRoute redirectPath="/home" name="Regularization">
                <Regularization />
              </PrivateRoute>
            }
          />
          <Route
            path="/manager"
            element={
              <PrivateRoute redirectPath="/home" name="ChangeManagerWeb">
                <ChangeManagerWeb />
              </PrivateRoute>
            }
          />
          <Route
            path="/reportees"
            element={
              <PrivateRoute redirectPath="/home" name="Reportee">
                <Reportee />
              </PrivateRoute>
            }
          />
          <Route
            path="/reportees/:id"
            element={
              <PrivateRoute redirectPath="/home" name="ReporteeDetails">
                <ReporteeDetails />
              </PrivateRoute>
            }
          />
          <Route
            path="/reportees/shift/:id"
            element={
              <PrivateRoute redirectPath="/home" name="ShiftChange">
                <ShiftChange />
              </PrivateRoute>
            }
          />
          <Route
            path="/report"
            element={
              <PrivateRoute redirectPath="/home" name="Report">
                <Report />
              </PrivateRoute>
            }
          />
          <Route
            path="/dashboard"
            element={
              <PrivateRoute redirectPath="/home" name="Dashboard">
                <Dashboard homeData={homeData} />
              </PrivateRoute>
            }
          />

          <Route
            path="/dashboard/office"
            element={
              <PrivateRoute redirectPath="/home" name="Dashboard">
                <Office />
              </PrivateRoute>
            }
          />

          <Route
            path="/dashboard/department-count"
            element={
              <PrivateRoute redirectPath="/home" name="Dashboard">
                <Department />
              </PrivateRoute>
            }
          />

          <Route
            path="/dashboard/shift-count"
            element={
              <PrivateRoute redirectPath="/home" name="Dashboard">
                <Shift />
              </PrivateRoute>
            }
          />

          <Route
            path="/dashboard/user-count"
            element={
              <PrivateRoute redirectPath="/home" name="Dashboard">
                <User />
              </PrivateRoute>
            }
          />


          <Route
            path="/dashboard/:departmentName/:departmentId"
            element={
              <PrivateRoute redirectPath="/home" name="Dashboard">
                <DepartmentDetails />
              </PrivateRoute>
            }
          />

          <Route
            path="/profile-detail"
            element={
              <PrivateRoute redirectPath="/" name="ProfileDetails">
                <ProfileDetails />
              </PrivateRoute>
            }
          />
          <Route
            path="/attendance-action"
            element={
              <PrivateRoute redirectPath="/" name="AttendanceAction">
                <AttendanceAction />
              </PrivateRoute>
            }
          />

          <Route
            path="/profile"
            element={
              <PrivateRoute redirectPath="/" name="Profile">
                <Profile
                  selectedTab={selectedTab}
                  setSelectedTab={setSelectedTab}
                  homeData={homeData}
                  logoutHandler={() => logoutHandler()}
                />
              </PrivateRoute>
            }
          />
        </Routes>
        {footerTabArr?.includes(pathname) && isMobView && (
          <HomeFooter
            selectedTab={selectedTab}
            setSelectedTab={setSelectedTab}
          />
        )}
      </div>
      <ToastMsgs />
    </>
  );
}
