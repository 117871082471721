import React, { useEffect, useState } from "react";
import "./Mobile.css";
import PageHeaderMobile from "../../../shared/PageHeaderMobile/PageHeaderMobile";
import { Box } from "@mui/material";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { a11yProps, CustomTabPanel } from "./Mobile-service";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import AttendanceListingItemCard from "../../Attendance/ListingItemCard/ListingItemCard";
import ApplyRegularizationDrawer from "./ApplyRegularizationDrawer/ApplyRegularizationDrawer";
import PendingRegularizationRowItem from "./PendingRegularizationRowItem/PendingRegularizationRowItem";
import RegularizationHistoryRowItem from "./RegularizationHistoryRowItem/RegularizationHistoryRowItem";
import { getPendingRegularizationFunction } from "../../Reportee/Web/ReporteeRegularization/History/History-service";

const RegularizationMobile = ({ attendanceList, reasons, getData }) => {
  const [tabValue, setTabValue] = useState(0);
  const [currentAttendance, setCurrentAttendance] = useState(null);
  const [pendingHistoryData, setPendingHistoryData] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    if (tabValue) {
      const queryObj = { type: "own" };
      if (tabValue > 1) {
        queryObj["status"] = "History";
      }
      getPendingRegularizationFunction(queryObj)
        .then((res) => {
          setPendingHistoryData(res?.data?.data || []);
        })
        .catch((err) => {
          console.log("error while getting record of pending history", err);
        });
    }
  }, [tabValue]);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleDraw = (data) => {
    if (data.manager && data.manager?.id) {
      setCurrentAttendance(data);
    } else {
      toast.error("You need to select your manager first.");
      navigate("/profile-details");
    }
  };
  return (
    <>
      <div className="rm-container">
        <PageHeaderMobile title="Regularization" />
        <Box
          sx={{
            width: "100%",
            padding: "10px",
            height: "100vh",
            backgroundColor: "#F6F8FA",
          }}
        >
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={tabValue}
              onChange={handleTabChange}
              aria-label="basic tabs example"
            >
              <Tab label="Required" {...a11yProps(0)} />
              <Tab label="Pending" {...a11yProps(1)} />
              <Tab label="History" {...a11yProps(2)} />
            </Tabs>
          </Box>
          <CustomTabPanel value={tabValue} index={0}>
            {(!attendanceList || attendanceList.length === 0) && (
              <h4>Hurray! No Pending Regularization to Apply.</h4>
            )}
            {attendanceList?.map((attendanceData, index) => (
              <div key={index} onClick={() => handleDraw(attendanceData)}>
                <AttendanceListingItemCard attendanceData={attendanceData} />
              </div>
            ))}
          </CustomTabPanel>
          <CustomTabPanel value={tabValue} index={1}>
            {(!pendingHistoryData || pendingHistoryData.length === 0) && (
              <h4>Hurray! No Pending Regularization.</h4>
            )}
            {pendingHistoryData.map((regularData) => {
              return <PendingRegularizationRowItem regularData={regularData} />;
            })}
          </CustomTabPanel>
          <CustomTabPanel value={tabValue} index={2}>
            {(!pendingHistoryData || pendingHistoryData.length === 0) && (
              <h4>No Regularization History.</h4>
            )}
            {pendingHistoryData.map((regularData) => {
              return <RegularizationHistoryRowItem historyData={regularData} />;
            })}
          </CustomTabPanel>
        </Box>
        <ApplyRegularizationDrawer
          reasons={reasons}
          currentAttendance={currentAttendance}
          setCurrentAttendance={setCurrentAttendance}
          getData={getData}
          attendanceList={attendanceList}
        />
      </div>
    </>
  );
};

export default RegularizationMobile;
