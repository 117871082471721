import React, { useEffect, useState } from "react";
import "./Web.css";
import { useSearchParams } from "react-router-dom";
import { getReportees } from "./Web-service";
import UserAttendanceListRow from "./UserAttendanceListRow/UserAttendanceListRow";
import ReporteeRegularization from "./ReporteeRegularization/ReporteeRegularization";

const ReporteeWeb = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [activeTab, setActiveTab] = useState(
      searchParams.get("tab") || "current",
  );
  const [attendees, setAttendees] = useState([]);

  useEffect(() => {
    if (activeTab && activeTab === 'current') {
      getReportees(activeTab)
        .then((res) => {
          setAttendees(res.data.data);
        })
        .catch((err) => {
          console.log("error while getting attendance list", err);
        });
    } else {
      setAttendees([]);
    }
  }, [activeTab]);

  const changeTab = (e, tab) => {
    e.preventDefault();
    setActiveTab(tab);
    setSearchParams({ ["tab"]: tab, ["regularizationTab"]: "pending" });
  };
  return (
    <>
      <ul className="nav nav-tabs reportees-tab">
        <li className="nav-item">
          <a
            className={`nav-link ${activeTab === "current" ? "active" : ""}`}
            aria-current="page"
            onClick={(e) => changeTab(e, "current")}
          >
            Current Reportees
          </a>
        </li>
        <li className="nav-item">
          <a
            className={`nav-link ${activeTab === "regularization" ? "active" : ""}`}
            onClick={(e) => changeTab(e, "regularization")}
          >
            Regularisation
          </a>
        </li>
      </ul>
      {(activeTab && activeTab === 'current') && <div>
        {(!attendees || attendees.length == 0) && <h3 style={{textAlign: "center"}}>No reportees found</h3>}
        {attendees.map((attendeeData) => (
          <UserAttendanceListRow attendeeData={attendeeData} />
        ))}
      </div>}
      {(activeTab && activeTab === 'regularization') && <ReporteeRegularization />}
    </>
  );
};

export default ReporteeWeb;
