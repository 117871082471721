// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.NavHeader {
  /* Add your styles here */
}
.logout-text{
  font-family: "Inter";
  font-size: 14px !important;
  font-weight: 500 !important;
  line-height: 16.94px !important;
  text-align: left !important;
}

.clickable {
  cursor: pointer;
}
`, "",{"version":3,"sources":["webpack://./src/v1/components/shared/NavHeader/NavHeader.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;AAC3B;AACA;EACE,oBAAoB;EACpB,0BAA0B;EAC1B,2BAA2B;EAC3B,+BAA+B;EAC/B,2BAA2B;AAC7B;;AAEA;EACE,eAAe;AACjB","sourcesContent":[".NavHeader {\n  /* Add your styles here */\n}\n.logout-text{\n  font-family: \"Inter\";\n  font-size: 14px !important;\n  font-weight: 500 !important;\n  line-height: 16.94px !important;\n  text-align: left !important;\n}\n\n.clickable {\n  cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
