import React from "react";
import "./HomeWebReviewCard.css";
import Review from "../../../../../../assets/img/CardImg/Review.svg";
import ReviewCard from "../ReviewCard/ReviewCard";

const HomeWebReviewCard = ({reviewData}) => {
  return (
    <>
      <div
        className=" bg-white  h-100 card-radius d-flex flex-column"
        style={{ padding: "16px 17px 8px 17px", rowGap: "13px" }}
      >
        <p className="review">Review</p>
        {
          <div className="overflow-container">
            {reviewData?.length > 0 ? (
              reviewData?.map((dataItem) => (
                <ReviewCard key={dataItem.id} data={dataItem} />
              ))
            ) : (
              <div
                className="d-flex flex-column text-center"
                style={{ rowGap: "29px" }}
              >
                <div className="d-flex justify-content-center">
                    <img src={Review} style={{width: "45%"}} />
                </div>
                <p className="empty-message-text">Hurrah ! You’ve nothing to review.</p>
              </div>
            )}
          </div>
        }
      </div>
    </>
  );
};

export default HomeWebReviewCard;
