import React, { useState, useEffect } from "react";
import "./Department.css";
import BreadCrumbs from "../shared/BreadCrumbs/BreadCrumbs";
import { getDepartmentDetails } from "./Department-services";
import {ReactComponent as DepartmentBG} from "../../../../../../src/assets/img/department-bg.svg"
import {  useNavigate } from "react-router-dom";

function Department() {
  const [departmentData, setDepartmentData] = useState([]);
  const navigate = useNavigate()

  useEffect(() => {
    getDepartmentDetails()
      .then((res) => {
        setDepartmentData(res?.data?.data);
      })
      .catch((err) => {
        console.log("err", err);
      });
  }, []);
  const naviationHandler=(item)=>{
    navigate(`/dashboard/${item?.name}/${item?.id}`)
  }

  return (
    <div className="office-container">
      <div>
        <BreadCrumbs pathText="department-count" />
        <div>
          <div className="card-container-department">
            {departmentData?.map((item,index) => (
                <div className="depart-card " onClick={()=>naviationHandler(item)}>
                  <DepartmentBG/>
                  <div className="position-absolute-department">
                    <div className="it-text ">{item?.name}</div>
                    <div className="people-count">{item?.userCount} People</div>
                  </div>
                </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Department;
